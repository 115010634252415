<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-credit-card-check-outline
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{
              $t("activerecord.models.payment.with_attribute.other", null, {
                attribute: $t("activerecord.models.order.one"),
              })
            }}
            {{ order.identifier }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-expansion-panels>
                <payment-panel
                  v-for="(payment, idx) in order_attrs.payments_attributes"
                  v-show="!payment._destroy"
                  v-bind:key="payment.uuid"
                  v-model="order_attrs.payments_attributes[idx]"
                  :payment-methods="paymentMethods"
                  :document-kinds="documentKinds"
                  :payment-kinds="paymentKinds"
                  :items="items"
                  :expenses="expenses"
                  :payment-statuses="paymentStatuses"
                  @credit-note="addCreditNote"
                ></payment-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="4">
              <add-payment
                ref="AddPayment"
                :items="items"
                :to-pay="toPay"
                :payment-methods="paymentMethods"
                :document-kinds="documentKindsForSelect"
                :expenses="expenses"
                :expenses-to-pay="expensesToPay"
                @paymentAdded="addNewPayment"
              ></add-payment>
            </v-col>
          </v-row>
        </v-card-text>
        <v-snackbar v-model="snackbar.opened" :color="snackbar.color">
          {{ snackbar.text }}

          <template v-slot:action="{ attrs }">
            <div v-show="snackbar.color == 'error' && snackbar.show_actions">
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="clearOrderPaymentDialog()"
              >
                {{ $t("common.adfirmative") }}
              </v-btn>
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="snackbar.opened = false"
              >
                {{ $t("common.negative") }}
              </v-btn>
            </div>
            <div v-show="snackbar.color == 'success'">
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="snackbar.opened = false"
              >
                {{ $t("common.close") }}
              </v-btn>
            </div>
          </template>
        </v-snackbar>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="success" text @click="update()">
            {{ $t("helpers.submit.update", null, { model: "" }) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close()">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn color="primary" text @click="save()">
            {{ $t("helpers.submit.save", null, { model: "" }) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import RequestService from "@/services/request.service.js";
import PaymentPanel from "./PaymentPanel.vue";
import AddPayment from "./AddPayment.vue";

export default {
  components: { PaymentPanel, AddPayment },
  name: "order-payments",
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      order_attrs: {
        id: this.order.id,
        payments_attributes: [],
      },
      items: [],
      expenses: [],
      dialog: this.dialogOpener,
      paymentMethods: [],
      documentKinds: [],
      paymentStatuses: [],
      paymentKinds: [
        {
          text: this.$t("enum.payment.payment_kind.deposit"),
          value: "deposit",
        },
        { text: this.$t("enum.payment.payment_kind.deposit"), value: 0 },
        {
          text: this.$t("enum.payment.payment_kind.balance"),
          value: "balance",
        },
        { text: this.$t("enum.payment.payment_kind.balance"), value: 1 },
      ],
      snackbar: {
        opened: false,
        text: "",
        color: "",
        close: null,
        show_actions: false,
      },
    };
  },
  computed: {
    toPay() {
      var toPay = {};

      this.items.forEach((i) => {
        toPay[i.id] =
          (parseFloat(i.unitary_price) || 0) *
          (parseFloat(i.expected_stock) || 0);
      });

      this.order_attrs.payments_attributes
        .filter((p) => p._destroy != true)
        .flatMap((p) => p.item_payments_attributes)
        .filter((ip) => ip._destroy != true && ip.item_id)
        .forEach((ip) => {
          toPay[ip.item_id] -= parseFloat(ip.price);
        });

      // round every value to 2 decimal places in toPay
      Object.keys(toPay).forEach((key) => {
        toPay[key] = Math.round(toPay[key] * 100) / 100;
      });

      return toPay;
    },
    expensesToPay() {
      var toPay = {};

      this.expenses.forEach((i) => {
        toPay[i.id] = parseFloat(i.unitary_price) || 0;
      });

      this.order_attrs.payments_attributes
        .filter((p) => p._destroy != true)
        .flatMap((p) => p.item_payments_attributes)
        .filter((ip) => ip._destroy != true && ip.expense_id)
        .forEach((ip) => {
          toPay[ip.expense_id] -= parseFloat(ip.price);
        });
      return toPay;
    },
    documentKindsForSelect() {
      return this.documentKinds.filter((dk) => dk.value != "credit_note");
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getPayment();
      }
    },
  },
  methods: {
    addCreditNote(payment) {
      this.order_attrs.payments_attributes.push({
        reverse_payment_id: payment.id,
        price: -payment.price,
        payment_kind: payment.payment_kind,
        payment_method_id: payment.payment_method,
        document_kind: "credit_note",
        company_id: this.$store.state.auth.session.company.id,
        created_at: "",
        uuid: this.generateUUID(),
        _destroy: false,
        id: null,
        item_payments_attributes: payment.item_payments_attributes.map(
          (ip) => ({
            item_id: ip.item_id,
            expense_id: ip.expense_id,
            price: -ip.price,
            quantity: ip.quantity,
            company_id: this.$store.state.auth.session.company.id,
          })
        ),
      });
    },
    addNewPayment(addedPayment) {
      this.order_attrs.payments_attributes.push(
        Object.assign(addedPayment, {
          created_at: "",
          uuid: this.generateUUID(),
          _destroy: false,
          id: null,
        })
      );
    },
    getPaymentsAttributes(payment) {
      return {
        id: payment.id,
        price: payment.price,
        from_sync: payment.from_sync,
        created_at:
          payment.created_at.substring(0, 10) +
          " " +
          payment.created_at.substring(11, 16),
        item_payments_attributes: payment.item_payments.map((ip) =>
          this.getItemPaymentsAttributes(ip)
        ),
        payment_kind: payment.payment_kind,
        payment_method_id: payment.payment_method_id,
        document_kind: payment.document_kind,
        status: payment.status,
        invoice: payment.invoice,
        has_credit_note: payment["has_credit_note?"],
        description: payment.description,
        reverse_payment_id: payment.reverse_payment_id,
        _destroy: false,
        uuid: this.generateUUID(),
      };
    },
    getItemPaymentsAttributes(item_payment) {
      return {
        id: item_payment.id,
        item_id: item_payment.item_id,
        expense_id: item_payment.expense_id,
        price: item_payment.price,
        quantity: item_payment.quantity,
        _destroy: false,
      };
    },
    update() {
      var that = this;
      var url = "orders/" + this.order.id + "/update_payments";
      RequestService.request_post({}, url).then(
        (response) => {
          console.log(response);
          that.getPayment();
          that.snackbar = {
            opened: true,
            text: this.$t("helpers.effects.updated.m.one", null, {
              model: this.$t("activerecord.models.order.one"),
            }),
            color: "success",
            show_actions: false,
          };
        },
        (error) => {
          console.log(error);
          that.snackbar = {
            opened: true,
            text: error.response.data.error,
            color: "error",
            show_actions: false,
          };
        }
      );
    },
    close() {
      if (
        this.order_attrs.payments_attributes.filter((p) => p._destroy).length
      ) {
        this.snackbar = {
          opened: true,
          text: this.$t("alert.close_without_saving"),
          color: "error",
          show_actions: true,
        };
      } else {
        this.clearOrderPaymentDialog();
      }
    },
    clearOrderPaymentDialog() {
      this.order_attrs = {
        id: this.order.id,
        payments_attributes: [],
      };
      this.items = [];
      this.expenses = [];
      this.getPayment();
      this.$refs.AddPayment.clearAddPayment();
      this.snackbar.opened = false;
      this.dialog = false;
    },

    save() {
      var that = this;
      var params = { order: this.order_attrs };
      RequestService.request_post(params, "orders/update_from_open").then(
        (response) => {
          that.order_attrs.id = response.data.id;
          that.order_attrs.payments_attributes = response.data.payments.map(
            (p) => that.getPaymentsAttributes(p)
          );
          that.items = response.data.items;
          that.expenses = response.data.expenses || [];
          that.snackbar = {
            opened: true,
            text: this.$t("helpers.effects.updated.m.one", null, {
              model: this.$t("activerecord.models.order.one"),
            }),
            color: "success",
            show_actions: true,
          };
        },
        (error) => {
          this.snackbar = {
            opened: true,
            text: error.response.data.payments.join("-"),
            color: "error",
            show_actions: true,
          };
          error.response.data;
        }
      );
    },
    generateUUID() {
      return Math.floor(Math.random() * 1000000);
    },
    getPayment() {
      var params = { order_id: this.order.id };
      var that = this;
      this.order_attrs.payments_attributes = [];
      RequestService.request_get(params, "orders/get_payment").then(
        (response) => {
          that.order_attrs.id = response.data.order.id;
          response.data.order.payments.forEach((p) => {
            that.order_attrs.payments_attributes.push(
              that.getPaymentsAttributes(p)
            );
          });
          that.items = response.data.order.items;
          that.expenses = response.data.order.expenses || [];
          that.paymentMethods = response.data.payment_methods;
          that.documentKinds = response.data.document_kinds;
          that.paymentStatuses = response.data.payment_statuses;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
