<template>
  <v-dialog v-model="dialog" max-width="80vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">
        {{ isNew ? "Nuovo Pagamento" : "Modifica" }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary">
        <h2>
          <font color="#FFFFFF">{{
            isNew ? "Nuovo Pagamento" : `Modifica Pagamento ${payment.id}`
          }}</font>
        </h2>
        <v-spacer />
        <v-btn
          @click="dialog = false"
          color="white"
          outlined
          dense
          hide-details
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-form ref="form">
          <br />
          <v-select
            v-model="payment.document_kind"
            label="Documento"
            outlined
            dense
            :rules="[rules.present]"
            :items="documentEnum"
            :item-text="'text'"
            :item-value="'value'"
          />
          <br />
          <v-select
            v-model="payment.payment_kind"
            label="Pagamanto"
            outlined
            dense
            :rules="[rules.present]"
            :items="paymentEnum"
            :item-text="'text'"
            :item-value="'value'"
          />
          <br />
          <v-text-field
            v-model="payment.price"
            label="Importo"
            type="number"
            outlined
            dense
            :rules="[rules.present]"
          />
          <br />
          <v-text-field
            v-model="payment.discount"
            label="Sconto"
            type="number"
            outlined
            dense
            :rules="[rules.present]"
          />
          <br />
          <v-select
            v-model="payment.status"
            label="Stato"
            outlined
            dense
            :rules="[rules.present]"
            :items="statusEnum"
            :item-text="'text'"
            :item-value="'value'"
            :disabled="isNew"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="primary">
        <v-spacer />
        <v-btn color="white" outlined dense hide-details @click="confirm()">
          CONFERMA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable prettier/prettier */

export default {
  name: "PaymentModal",
  props: {
    value: {
      type: Object,
    },
    documentEnum: { type: Array },
    paymentEnum: { type: Array },
    statusEnum: { type: Array },
  },
  data() {
    return {
      payment: JSON.parse(JSON.stringify(this.value)),
      rules: {
        present: (value) => (!!value || value == 0) || "Campo obbligatorio",
      },
      dialog: false,
    };
  },
  methods: {
    confirm() {
      if (!this.$refs.form.validate()) return;

      this.$emit("save", this.payment);
      this.dialog = false;
    },
  },
  computed: {
    isNew() {
      return !this.payment.id;
    },
  },
};
</script>