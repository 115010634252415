<style scoped></style>
<template>
  <v-row justify="start" style="margin-left: 0px" class="new-service">
    <v-row id="shipment-card">
      <v-overlay
        :value="loadingResults || saveAlert || errorAlerts || itemCard"
        opacity="0.3"
      >
        <v-progress-circular
          v-if="loadingResults"
          indeterminate
          size="100"
          width="5"
        ></v-progress-circular>
        <v-alert :value="saveAlert" type="success" elevation="8"
          >Servizio salvato correttamente
          <v-icon
            right
            @click="
              saveAlert = false;
              createNewPopup = true;
              clearNewServiceDialog();
            "
            >mdi-close</v-icon
          >
        </v-alert>
        <template v-for="(error, idx) in errorsArray">
          <v-alert type="error" elevation="8" v-bind:key="idx">
            {{ getErrorMessage(error) }}
            <v-icon right @click="errorsArray.splice(idx, 1)">mdi-close</v-icon>
          </v-alert>
        </template>
        <v-card
          v-model="insertNewItem"
          style="min-width: 30vw"
          light
          flex
          v-if="itemCard"
        >
          <div>
            <v-row
              ><v-card-title style="padding-left: 2vw"
                >Nuova Voce di Fatturazione</v-card-title
              ></v-row
            >
            <v-row style="padding-left: 2vw; padding-right: 2vw">
              <v-text-field
                outlined
                v-model="newItemName"
                label="Name"
                required
              ></v-text-field>
            </v-row>
            <v-row
              style="padding-left: 2vw; padding-right: 2vw; padding-bottom: 2vh"
            >
              <v-btn color="success" class="mr-4" @click="itemCreation()">
                Crea
              </v-btn>
              <v-btn color="error" class="mr-4" @click="itemCreationVoid()">
                Annulla
              </v-btn>
            </v-row>
          </div>
        </v-card>
      </v-overlay>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <h4>Step 1</h4>
              <h2>Impostazioni</h2>
              <br />
              <v-row class="mb-0">
                <v-col>
                  <v-btn dark @click="createNewShipment"
                    >Conferma
                  </v-btn
                ></v-col>
                <v-col v-if="departments.length > 0">
                  <v-select
                      dense
                      hide-details="auto"
                      v-model="selectedDepartment"
                      :items="departments"
                      item-text="text"
                      item-value="value"
                      label="Dipartimento"
                      outlined
                    ></v-select>
                </v-col>
                <v-col> Committente: {{ sender.to_s }} </v-col>
                <v-col v-if="company == 'Fenix'">
                  Tagliandi: {{ sender.vouchers }}
                </v-col>
              </v-row>
              <hr />
              <v-row class="my-0">
                <v-col>
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="urgency"
                    :items="urgencyArray"
                    item-text="text"
                    item-value="value"
                    @change="updateTimeWindows"
                    label="Tempistiche *"
                    outlined
                  ></v-select>
                  <br />
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="vehicleType"
                    :items="skills"
                    item-text="name"
                    item-value="id"
                    @change="updateTimeWindows"
                    label="Veicolo richiesto *"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <hr />
              <v-row class="my-0">
                <v-col>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="serviceDate"
                        label="Data servizio"
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="validateIsoDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="serviceDate"
                      locale="it"
                      scrollable
                      @change="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mb-0">
                <v-col>
                  <v-menu
                    dense
                    ref="pemmenu"
                    v-model="PEMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="timeWindows.pickup.earliest"
                        label="Ritiri da..."
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        @change="updateTimeWindows"
                        v-on="on"
                        :rules="validateIsoTime"
                        @click="
                          clearIfNotIso(
                            timeWindows.pickup.earliest,
                            'pickup',
                            'earliest'
                          )
                        "
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      @click:minute="
                        $refs.pemmenu.save(timeWindows.pickup.earliest)
                      "
                      v-if="PEMenu"
                      v-model="timeWindows.pickup.earliest"
                      @change="updateTimeWindows"
                      locale="it"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="PLMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="timeWindows.pickup.latest"
                        label="Ritiri entro..."
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="validateIsoTime"
                        @click="
                          clearIfNotIso(
                            timeWindows.pickup.latest,
                            'pickup',
                            'latest'
                          )
                        "
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeWindows.pickup.latest"
                      locale="it"
                      @change="PLMenu = false"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mb-0">
                <v-col>
                  <v-menu
                    v-model="DEMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="timeWindows.delivery.earliest"
                        :label="`Consegne da...`"
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="validateIsoTime"
                        @click="
                          clearIfNotIso(
                            timeWindows.pickup.latest,
                            'delivery',
                            'earliest'
                          )
                        "
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeWindows.delivery.earliest"
                      locale="it"
                      @change="DEMenu = false"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="DLMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="timeWindows.delivery.latest"
                        label="Consegne entro..."
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="validateIsoTime"
                        @click="
                          clearIfNotIso(
                            timeWindows.pickup.latest,
                            'delivery',
                            'latest'
                          )
                        "
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeWindows.delivery.latest"
                      locale="it"
                      @change="DLMenu = false"
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <hr />
              <v-row class="my-0">
                <v-col>
                  <h4 style="text-align: left" class="mb-3">Step 4</h4>
                  <v-select
                    dense
                    class="mb-3"
                    hide-details="auto"
                    v-model="paymentType"
                    :items="paymentArray"
                    item-text="text"
                    item-value="value"
                    label="Metodo di pagamento"
                    outlined
                  ></v-select>
                  <v-checkbox
                    dense
                    class="mb-3"
                    hide-details="auto"
                    v-model="isVirtualVoucher"
                    label="Tagliandi Virtuali"
                    v-if="paymentType == 'voucher' && company.name == 'Fenix'"
                    :disabled="hasDefaultVirtualVoucher"
                  ></v-checkbox>
                  <v-text-field
                    dense
                    class="mb-3"
                    outlined
                    hide-details="auto"
                    type="number"
                    v-model="price"
                    label="Tariffa"
                    readonly
                    v-bind="company.name == 'Fenix' ? attrs : null"
                    v-on="company.name == 'Fenix' ? on : null"
                  ></v-text-field>
                  <v-btn @click="calculatePriceList()"> Calcola Prezzo </v-btn>
                </v-col>
              </v-row>
              <hr />
              <v-checkbox
                dense
                class="mb-3"
                hide-details="auto"
                label="Fatturare a fine mese"
                v-model="displaybill"
                v-if="sender.open_has_billing_flags == true"
              >
              </v-checkbox>
              <v-row v-show="displaybill" class="mb-0">
                <v-autocomplete
                  dense
                  v-model="selecteditems"
                  :loading="loadingItems"
                  :items="items"
                  :search-input.sync="searchItems"
                  item-text="name"
                  item-value="id"
                  outlined
                  hide-no-data
                  hide-details="auto"
                  label="Voci di Fatturazione"
                  multiple
                  chips
                  small-chips
                  cache-items
                >
                </v-autocomplete>
                <v-btn color="primary" @click="insertNewItem = true">
                  Crea
                </v-btn>
              </v-row>
            </v-col>
            <v-col style="text-align: center">
              <h4 style="text-align: left">Step 2</h4>
              <h2 style="text-align: left">Ritiri</h2>
              <div
                v-for="pickup in pickups"
                style="margin-bottom: 2vh"
                v-bind:key="pickup.id"
              >
                <v-row>
                  <v-col>
                    <v-row class="ma-0">
                      <v-checkbox
                        dense
                        class="mb-3"
                        :disabled="sender == null"
                        hide-details="auto"
                        v-model="pickup.hasSenderAddress"
                        @change="
                          updateServiceAddress(pickup.idx),
                            (pickup['saveInRubric'] = false)
                        "
                        label="Stesso indirizzo del committente"
                      >
                      </v-checkbox>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="ma-0">
                      <v-checkbox
                        dense
                        class="mb-3"
                        :disabled="pickup.hasSenderAddress"
                        hide-details="auto"
                        v-model="pickup.saveInRubric"
                        label="Salva in Rubrica"
                      >
                      </v-checkbox>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            text
                            plain
                          >
                            <v-icon>mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("common.save_in_rubric_info.first") }} <br />
                          {{ $t("common.save_in_rubric_info.second") }}
                        </span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
                <div style="flex-grow: 1">
                  <!--<gmap-autocomplete
                    style="flex-grow: 1;"
                    :id="'pickup-address-' + pickup.idx"
                    class="mb-3"
                    ref="autocomplete"
                    placeholder="Indirizzo"
                    v-if="!pickup.hasSenderAddress"
                    :fields="['address_components']"
                    @place_changed="onPlaceChanged($event, pickup.idx)"
                    @clear="clearAddress(pickup)"
                  />-->
                </div>
                
                <v-row>
                  <v-col class="col-no-padding">
                    <v-combobox
                      dense
                      class="mb-3"
                      outlined
                      hide-details="auto"
                      v-model="pickup.denomination"
                      label="Destinatario"
                      :readonly="pickup.hasSenderAddress"
                      :items="pickup.senderRubric"
                      :search-input.sync="pickup.searchReceiver"
                      @change="completeDataReceiver(pickup)"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="col-no-padding">
                    <v-text-field
                      class="mb-3"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="pickup.address.street"
                      label="Strada"
                      :readonly="pickup.hasSenderAddress"
                      @change="clearLocation(delivery, 'street')"
                    ></v-text-field>
                    <v-combobox
                      v-model="pickup.address.town"
                      :items="pickup.towns"
                      :search-input.sync="pickup.searchTown"
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      dense
                      label="Comune"
                      :clearable="!pickup.hasSenderAddress"
                      @change="
                        updatedPrice = false;
                        clearLocation(pickup, 'town');
                      "
                      :readonly="pickup.hasSenderAddress"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-no-padding">
                    <v-combobox
                      v-model="pickup.address.zipcode"
                      :items="pickup.zipcodes"
                      :search-input.sync="pickup.searchZipcode"
                      outlined
                      :clearable="!pickup.hasSenderAddress"
                      class="mb-3"
                      hide-details="auto"
                      dense
                      label="CAP"
                      @change="
                        updatedPrice = false;
                        clearLocation(pickup, 'zipcode');
                      "
                      :readonly="pickup.hasSenderAddress"
                      v-if="company.name != 'DeliverMI'"
                    ></v-combobox>
                    <v-text-field
                      v-if="company.name == 'DeliverMI'"
                      v-model="pickup.address.zipcode"
                      :clearable="!pickup.hasSenderAddress"
                      :readonly="pickup.hasSenderAddress"
                      class="mb-3"
                      hide-details="auto"
                      outlined
                      dense
                      label="CAP"
                      @change="
                        updatedPrice = false;
                        clearLocation(pickup, 'zipcode');
                      "
                    />
                  </v-col>
                  <v-col class="col-no-padding">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="pickup.address.province"
                      label="Provincia"
                      :readonly="pickup.hasSenderAddress"
                      @change="clearLocation(pickup)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                
                <v-row
                  v-if="company.name == 'DeliverMI' || company.name == 'Fenix'"
                  style="padding-bottom: 10px"
                >
                  <v-col class="col-no-padding">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="pickup.phone_number"
                      :rules="validatePhoneNumber"
                      label="Numero di telefono"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div v-if="pickupsPlural">
                  <div v-if="company.name == 'Fenix'">
                    <v-row>
                      <v-col> <v-icon x-large>mdi-email-outline</v-icon> </v-col
                      ><v-col>
                        <v-icon x-large>mdi-purse-outline</v-icon> </v-col
                      ><v-col>
                        <v-icon x-large>mdi-package-variant-closed</v-icon>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span><b>Busta</b></span> </v-col
                      ><v-col>
                        <span><b>Borsa</b></span> </v-col
                      ><v-col>
                        <span><b>Pacco</b></span>
                      </v-col>
                    </v-row>
                    <v-radio-group
                      style="
                        margin-top: 0 !important;
                        padding-top: 0 !important;
                      "
                      v-model="pickup.packaging"
                    >
                      <v-row
                        style="
                          margin-top: 0 !important;
                          padding-top: 0 !important;
                        "
                      >
                        <v-col style="display: flex; align-items: center">
                          <v-radio
                            style="margin-right: auto; margin-left: auto"
                            color="orange"
                            value="letter"
                          ></v-radio>
                        </v-col>
                        <v-col style="display: flex; align-items: center">
                          <v-radio
                            style="margin-right: auto; margin-left: auto"
                            color="orange"
                            value="bag"
                          ></v-radio>
                        </v-col>
                        <v-col style="display: flex; align-items: center">
                          <v-radio
                            style="margin-right: auto; margin-left: auto"
                            color="orange"
                            value="box"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>

                    <v-row class="my-0">
                      <v-col class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.length"
                          label="Lunghezza"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.width"
                          label="Larghezza"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.volume_data"
                          label="Num Colli"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="needsHeight(pickup)" class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-0"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.height"
                          label="Altezza"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col v-if="needsWeight(pickup)" class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-0"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.weight"
                          label="Peso"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="company.name == 'Antylia Logistics'">
                    <v-row>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.volume"
                          label="Volume"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.weight"
                          label="Peso"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="pickup.packaging_data.volume_data"
                          label="Num Colli"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="company.name == 'DeliverMI'">
                    <v-text-field
                      dense
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      type="number"
                      v-model="pickup.packaging_data.weight"
                      label="Peso"
                      :rules="validatePositive"
                    >
                    </v-text-field>
                    <v-text-field
                      dense
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      type="number"
                      v-model="pickup.packaging_data.volume"
                      label="Numero colli"
                      :rules="validatePositive"
                    >
                    </v-text-field>
                  </div>
                </div>
                <v-row>
                  <v-col>
                    <v-textarea
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="pickup.notes"
                      label="Note"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!--v-row v-if="company.name == 'DeliverMI'">
                  <v-col class="col-no-padding">
                    <v-textarea
                      class="mb-3"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="pickup.orderNotes"
                      label="Prodotti"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row-->
                <v-btn
                  class="white--text ma-2"
                  color="error"
                  depressed
                  small
                  @click="removeService(pickup.idx)"
                  >Elimina</v-btn
                >
              </div>
              <v-btn
                class="ma-2"
                color="success"
                white--text
                depressed
                v-if="canAddPickup"
                @click="addService('pickup')"
                >Aggiungi ritiro</v-btn
              >
            </v-col>
            <v-col style="text-align: center">
              <h4 style="text-align: left">Step 3</h4>
              <h2 style="text-align: left">
                {{ $t("activerecord.models.delivery.other") }}
              </h2>
              <div
                v-for="delivery in deliveries"
                style="margin-bottom: 2vh"
                v-bind:key="delivery.id"
              >
                <v-row>
                  <v-col>
                    <v-row class="ma-0">
                      <v-checkbox
                        dense
                        class="mb-3"
                        :disabled="sender == null"
                        hide-details="auto"
                        v-model="delivery.hasSenderAddress"
                        @change="
                          updateServiceAddress(delivery.idx),
                            (delivery['saveInRubric'] = false)
                        "
                        label="Stesso indirizzo del committente"
                      >
                      </v-checkbox>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="ma-0">
                      <v-checkbox
                        dense
                        class="mb-3"
                        :disabled="delivery.hasSenderAddress"
                        hide-details="auto"
                        v-model="delivery.saveInRubric"
                        label="Salva in Rubrica"
                      >
                      </v-checkbox>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            text
                            plain
                          >
                            <v-icon>mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("common.save_in_rubric_info.first") }} <br />
                          {{ $t("common.save_in_rubric_info.second") }}
                        </span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
                <div style="flex-grow: 1">
                  <!--<gmap-autocomplete
                  style="flex-grow: 1;"
                  :id="'delivery-address-' + delivery.idx"
                  class="mb-3"
                  ref="autocomplete"
                  placeholder="Indirizzo"
                  v-if="!delivery.hasSenderAddress"
                  :fields="['address_components']"
                  @place_changed="onPlaceChanged($event, delivery.idx)"
                  @clear="clearAddress(delivery)"
                />-->
                </div>
                <v-row>
                  <v-col class="col-no-padding">
                    <v-combobox
                      dense
                      class="mb-3"
                      outlined
                      hint
                      v-model="delivery.denomination"
                      label="Destinatario"
                      :readonly="delivery.hasSenderAddress"
                      :items="delivery.senderRubric"
                      :search-input.sync="delivery.searchReceiver"
                      @change="completeDataReceiver(delivery)"
                      hide-details="auto"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-no-padding">
                    <v-text-field
                      class="mb-3"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="delivery.address.street"
                      label="Strada"
                      :readonly="delivery.hasSenderAddress"
                      @change="clearLocation(delivery, 'street')"
                    ></v-text-field>
                    <v-combobox
                      v-model="delivery.address.town"
                      :items="delivery.towns"
                      :search-input.sync="delivery.searchTown"
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      dense
                      label="Comune"
                      :clearable="!delivery.hasSenderAddress"
                      @change="
                        updatedPrice = false;
                        clearLocation(delivery, 'town');
                      "
                      :readonly="delivery.hasSenderAddress"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="col-no-padding">
                    <v-combobox
                      v-model="delivery.address.zipcode"
                      :items="delivery.zipcodes"
                      :search-input.sync="delivery.searchZipcode"
                      outlined
                      :clearable="!delivery.hasSenderAddress"
                      class="mb-3"
                      hide-details="auto"
                      dense
                      label="CAP"
                      @change="
                        updatedPrice = false;
                        clearLocation(delivery, 'zipcode');
                      "
                      :readonly="delivery.hasSenderAddress"
                      v-if="company.name != 'DeliverMI'"
                    ></v-combobox>
                    <v-text-field
                      v-if="company.name == 'DeliverMI'"
                      v-model="delivery.address.zipcode"
                      :clearable="!delivery.hasSenderAddress"
                      :readonly="delivery.hasSenderAddress"
                      class="mb-3"
                      hide-details="auto"
                      dense
                      outlined
                      label="CAP"
                      @change="
                        updatedPrice = false;
                        clearLocation(delivery, 'zipcode');
                      "
                    />
                  </v-col>
                  <v-col class="col-no-padding">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="delivery.address.province"
                      label="Provincia"
                      :readonly="delivery.hasSenderAddress"
                      @change="clearLocation(delivery, 'province')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                
                
                <v-row
                  v-if="company.name == 'DeliverMI' || company.name == 'Fenix'"
                  style="padding-bottom: 10px"
                >
                  <v-col class="col-no-padding">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      v-model="delivery.phone_number"
                      type="string"
                      :rules="validatePhoneNumber"
                      label="Numero di telefono"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-no-padding">
                    <v-text-field
                      readonly
                      v-model="delivery.price"
                      outlined
                      dense
                      class="mb-3"
                      hide-details="auto"
                      label="Tariffa"
                      v-if="company.name == 'Fenix'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-no-padding">
                    <v-text-field
                      dense
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      type="string"
                      v-model="delivery.intercom"
                      label="Citofono"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div v-if="deliveriesPlural || oneOnOneService">
                  <div v-if="company.name == 'Fenix'">
                    <v-row>
                      <v-col> <v-icon x-large>mdi-email-outline</v-icon> </v-col
                      ><v-col>
                        <v-icon x-large>mdi-purse-outline</v-icon> </v-col
                      ><v-col>
                        <v-icon x-large>mdi-package-variant-closed</v-icon>
                      </v-col>
                    </v-row>
                    <v-row style="padding-bottom: 0%">
                      <v-col>
                        <span><b>Busta</b></span> </v-col
                      ><v-col>
                        <span><b>Borsa</b></span> </v-col
                      ><v-col>
                        <span><b>Pacco</b></span>
                      </v-col>
                    </v-row>
                    <v-radio-group
                      style="
                        margin-top: 0 !important;
                        padding-top: 0 !important;
                      "
                      v-model="delivery.packaging"
                    >
                      <v-row
                        style="
                          margin-top: 0 !important;
                          padding-top: 0 !important;
                        "
                      >
                        <v-col style="display: flex; align-items: center">
                          <v-radio
                            style="
                              margin-right: auto;
                              margin-left: auto;
                              padding-top: 0vh;
                            "
                            color="orange"
                            value="letter"
                          ></v-radio>
                        </v-col>
                        <v-col style="display: flex; align-items: center">
                          <v-radio
                            style="
                              margin-right: auto;
                              margin-left: auto;
                              padding-top: 0vh;
                            "
                            color="orange"
                            value="bag"
                          ></v-radio>
                        </v-col>
                        <v-col style="display: flex; align-items: center">
                          <v-radio
                            style="
                              margin-right: auto;
                              margin-left: auto;
                              padding-top: 0vh;
                            "
                            color="orange"
                            value="box"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                    <v-row class="my-0" >
                      <v-col class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.length"
                          label="Lunghezza"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.width"
                          label="Larghezza"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="col-no-padding">
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.volume_data"
                          label="Num Colli"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-if="needsHeight(delivery)"
                        class="col-no-padding"
                      >
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.height"
                          label="Altezza"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        v-if="needsWeight(delivery)"
                        class="col-no-padding"
                      >
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.weight"
                          label="Peso"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="company.name == 'Antylia Logistics'">
                    <v-row>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.volume"
                          label="Volume"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.weight"
                          label="Peso"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          outlined
                          class="mb-3"
                          hide-details="auto"
                          type="number"
                          v-model="delivery.packaging_data.volume_data"
                          label="Num Colli"
                          :rules="validatePositive"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="company.name == 'DeliverMI'">
                    <v-text-field
                      dense
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      type="number"
                      v-model="delivery.packaging_data.weight"
                      label="Peso"
                      @change="updatedPrice = false"
                      :rules="validatePositive"
                    >
                    </v-text-field>
                    <v-text-field
                      dense
                      outlined
                      class="mb-3"
                      hide-details="auto"
                      type="number"
                      v-model="delivery.packaging_data.volume"
                      label="Numero colli"
                      @change="updatedPrice = false"
                      :rules="validatePositive"
                    >
                    </v-text-field>
                  </div>
                </div>
                <v-row>
                  <v-col>
                    <v-textarea
                      class="mb-3"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="delivery.notes"
                      label="Note"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!--v-row v-if="company.name == 'DeliverMI'">
                  <v-col class="col-no-padding">
                    <v-textarea
                      class="mb-3"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="delivery.orderNotes"
                      label="Prodotti"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row-->
                <v-btn
                  class="white--text ma-2"
                  color="error"
                  depressed
                  small
                  @click="removeService(delivery.idx)"
                  >Elimina</v-btn
                >
              </div>
              <v-btn
                class="ma-2"
                color="success"
                white--text
                depressed
                v-if="canAddDelivery"
                @click="addService('delivery')"
                >Aggiungi consegna</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-row>
  </v-row>
</template>
<script>
import RequestService from "@/services/request.service.js";
import ControlService from "@/services/new-service-control.service.js";
import moment from "moment";

//import GmapAutocomplete from "@/views/utilities/gmap_autocomplete.vue";
/* eslint-disable prettier/prettier */
export default {
  name: "new-service",

  props: {},

  data: function () {
    var d = new Date();

    return {
      paymentArray: [
        {
          text: "Tagliandi",
          value: "voucher",
        },
        {
          text: "Euro",
          value: "euro",
        },
      ],
      urgencyArray:
        this.$store.state.auth.session.company.additional_data_schema.schema[
          "urgency"
        ].options.map((o) => {
          return { value: o[1], text: o[0] };
        }),
      
      selectedDepartment: '', 
      departments: [],

      skills: this.$store.state.auth.session.skills.filter((s) => s.internal),

      loadingResults: false,
      saveAlert: false,
      createNewPopup: false,

      dateMenu: false,
      PEMenu: false,
      PLMenu: false,
      DEMenu: false,
      DLMenu: false,

      senders: [],
      searchSenders: null,
      loadingSenders: false,

      items: [],
      selecteditems: [],
      searchItems: null,
      loadingItems: false,
      displaybill: false,

      vehicle_price: null,
      service_prices: [],
      shipment_prices: [],

      updatedPrice: false,
      identifier: null,
      urgency: "",
      vehicleType: "",
      vehicleTypeName: "",
      sender: this.$store.state.auth.session.user,
      item: null,
      services: [],
      timeWindows: {
        pickup: {
          earliest: null,
          latest: null,
        },
        delivery: {
          earliest: null,
          latest: null,
        },
      },
      newItemName: "",
      price: null,
      paymentType: "",
      insertNewItem: false,
      isVirtualVoucher: false,
      hasDefaultVirtualVoucher: false,
      finishToLoad: false,
      defaultVehicle: "",

      company: this.$store.state.auth.session.company,
      vehicleTimes: {
        Fenix: {
          normal: {
            Moto: 120,
            Auto: 180,
            Fiorino: 180,
          },
          urgent: {
            Moto: 60,
            Auto: 90,
            Fiorino: 90,
          },
          very_urgent: {
            Moto: 30,
            Auto: 60,
            Fiorino: 60,
          },
          direct: {
            Moto: 30,
            Auto: 30,
            Fiorino: 30,
          },
        },
        AntyliaLogistics: {
          normal: {
            default: null,
          },
          "10 hours": {
            default: 600,
          },
          "12 hours": {
            default: 720,
          },
        },
      },
      serviceDate: d.toISOString().substr(0, 10),

      validateIsoDate: [
        (value) =>
          /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/.test(
            value
          ) ||
          value === "" ||
          !value ||
          "Data non valida, utilizzare formato yyyy-MM-dd",
      ],
      validateIsoTime: [
        (value) =>
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) ||
          value === "" ||
          !value ||
          "Utilizzare formato hh:mm",
      ],
      validatePositive: [(value) => value > 0 || "Non può essere negativo"],

      validatePhoneNumber: [
        (value) =>
          /^\d+$/.test(value) ||
          value === "" ||
          "Numero di telefono: deve essere composto da soli numeri",
      ],

      errorsArray: [],
    };
  },

  computed: {
    pickups() {
      return this.services.filter((s) => s.type === "pickup");
    },
    deliveries() {
      return this.services.filter((s) => s.type === "delivery");
    },
    canAddPickup() {
      return this.deliveries.length < 2 || !this.pickups.length;
    },
    canAddDelivery() {
      return this.pickups.length < 2 || !this.deliveries.length;
    },
    deliveriesPlural() {
      return this.deliveries.length > 1;
    },
    pickupsPlural() {
      return this.pickups.length > 1;
    },
    oneOnOneService() {
      return this.pickups.length == 1 && this.deliveries.length == 1;
    },
    pickupsAreMany() {
      return this.pickups.length > 1 && this.updatedPrice;
    },
    errorAlerts() {
      return this.errorsArray.length > 0;
    },
    itemCard() {
      return this.insertNewItem;
    },
    hasNotShipmentFields() {
      var can = true;
      if (this.deliveriesPlural || this.oneOnOneService) {
        this.services.forEach((s) => {
          if (s.type == "delivery") {
            can = can && ControlService.packagingControl(s);
          } else {
            s.packaging_data.lenght = null;
            s.packaging_data.width = null;
            s.packaging_data.height = null;
            s.packaging_data.weight = null;
            s.packaging_data.volume = null;
          }
        });
        return !can;
      } else if (this.pickupsPlural) {
        this.services.forEach((s) => {
          if (s.type == "pickup") {
            can = can && ControlService.packagingControl(s);
          } else {
            s.packaging_data.lenght = null;
            s.packaging_data.width = null;
            s.packaging_data.height = null;
            s.packaging_data.weight = null;
            s.packaging_data.volume = null;
          }
        });
        return !can;
      } else {
        return false;
      }
    },
  },

  watch: {
    searchSenders: function (val) {
      if (!val || val.length < 3) {
        this.senders = [];
        return;
      }
      this.fetchSenders(val);
    },
    searchItems: function (val) {
      if (!val || val.length < 3) {
        this.items = [];
        return;
      }
      this.fetchItems(val);
    },
    services: {
      deep: true,
      handler: function (newval) {
        newval.forEach((s) => {
          if (
            s.searchZipcode != s.oldsearchZipcode &&
            s.searchZipcode != null &&
            s.searchZipcode != ""
          ) {
            this.fetchZipcode(s);
            s.oldsearchZipcode = s.searchZipcode;
          }

          if (
            s.searchTown != s.oldsearchTown &&
            s.searchTown != null &&
            s.searchTown != ""
          ) {
            this.fetchTown(s);
            s.oldsearchTown = s.searchTown;
          }

          if (
            s.searchReceiver != s.oldsearchReceiver &&
            s.searchReceiver != null
          ) {
            if (s.handlerTimer != null) clearTimeout(s.handlerTimer);

            var that = this;
            s.handlerTimer = setTimeout(function () {
              s.searchReceiver != ""
                ? that.getRubric(s)
                : (s.senderRubric = []);
              s.oldsearchReceiver = s.searchReceiver;
            }, 500);
          }
        });
      },
    },
  },

  methods: {
    removeItem(item) {
      const index = this.selecteditems.indexOf(item);
      if (index >= 0) this.item.splice(index, 1);
    },
    fetchItems: function (val) {
      this.loadingItems = true;
      var that = this;
      RequestService.request_get(
        {
          query: val,
          limit: 5,
        },
        "items/search"
      )
        .then((response) => {
          that.items = [];
          that.loadingItems = false;
          that.items = response.data.items.filter(
            (i) =>
              (i.name || "").toLowerCase().indexOf((val || "").toLowerCase()) >
              -1
          );
        })
        .catch((error) => {
          this.$emit("errorServer", error);
          console.log(error);
          that.loadingItems = false;
        });
    },
    getRubric: function (service) {
      RequestService.request_get(
        { query: service.searchReceiver },
        "senders/" + this.$store.state.auth.session.user.id + "/rubric"
      )
        .then((response) => {
          response = response.data;
          service.senderDataRubric = response || [];
          service.senderRubric = [];
          service.senderDataRubric.forEach((r) => {
            service.senderRubric.push(r.identifier);
          });
        })
        .catch((error) => {
          this.$emit("errorServer", error);
          console.log(error);
        });
    },
    itemCreationVoid: function () {
      this.insertNewItem = false;
      this.newItemName = "";
    },

    itemCreation: function () {
      if (this.newItemName.length > 0) {
        this.insertNewItem = false;
        var that = this;
        RequestService.request_post(
          {
            item: {
              name: that.newItemName,
            },
          },
          "items/create_from_open"
        )
          .then((response) => {
            that.items = [];
            that.newItemName = "";
            that.items.push(response.data.createdItem);
            that.selecteditems.push(response.data.createdItem.id);
          })
          .catch((error) => {
            this.$emit("errorServer", error);
            console.log(error);
          });
      }
    },

    needsHeight: function (service) {
      return service.packaging == "box" || service.packaging == "bag";
    },

    needsWeight: function (service) {
      return service.packaging == "box" || service.packaging == "bag";
    },

    findOnIdx: function (service, idx) {
      return service.idx == idx;
    },

    fetchTown: function (service) {
      if (service.searchZipcode != null && service.searchZipcode != "") {
        if (service.searchZipcode.length == 5) {
          if (service.searchTown.length > 1) {
            RequestService.request_get(
              {
                price_list_name: "default",
                townfield: service.searchTown,
                zipcodefield: service.address.zipcode,
              },
              "price_lists/search_town"
            ).then(
              (response) => {
                service.towns = [];
                service.towns = response.data.towns;
              },
              (error) => {
                this.$emit("errorServer", error);
                console.log(error);
              }
            );
          } else {
            service.towns = [];
          }
        } else {
          service.towns = [];
        }
      } else {
        service.towns = [];
      }
    },

    fetchZipcode: function (service) {
      service.zipcodes = [];

      if (service.searchZipcode == null || service.searchZipcode == "") {
        service.zipcodes = [];
      } else {
        if (service.searchZipcode.length < 2) {
          return [];
        } else {
          RequestService.request_get(
            {
              price_list_name: "default",
              zipcodefield: service.searchZipcode,
            },
            "price_lists/search_zipcode"
          ).then(
            (response) => {
              service.zipcodes = response.data.zipcodes;
            },
            (error) => {
              this.$emit("errorServer", error);
              console.log(error);
            }
          );
        }
      }
    },

    selectItem: function (val) {
      if (!this.selecteditems.includes(val)) {
        this.selecteditems.push(val);
      }
    },

    calculatePriceList: function () {
      if (this.pickups.length < 1 || this.deliveries.length < 1)
        this.errorsArray.push("missingPaymentServices");
      this.errorsArray.push(...ControlService.getSettingsErrors(this));

      if (this.errorAlerts) return;
      this.updatedPrice = true;
      var that = this;
      var skill = that.skills.filter((s) => s.id == that.vehicleType)[0].name;
      RequestService.request_post(
        {
          price_list: {
            price_list_name: "default",
            shipment_data: {
              shipment: that.services,
              urgency: that.urgency,
              vehicle_type: skill,
              sender_id: this.sender.id,
            },
          },
          authenticity_token: window._token,
        },
        "price_lists/calculate"
      ).then(
        (response) => {
          that.updatePrice(response.data);
        },
        (error) => {
          this.$emit("errorServer", error);
          console.log(error);
        }
      );
    },
    updatePrice(data) {
      this.price = data.price;
      this.service_prices = data.service_prices || [];
      this.shipment_prices = data.shipment_prices || [];
      this.vehicle_price = data.vehicle_price || 0;
      var unitary_veicle_price =
        this.vehicle_price / (this.services.length - 1);

      if (this.deliveriesPlural) {
        data.service_prices.forEach((sp) => {
          var idxService = this.services.findIndex((s) => s.idx == sp.ind);
          if (idxService != -1)
            this.services[idxService].price = sp.price + unitary_veicle_price;
        });

        data.shipment_prices.forEach((sp) => {
          var idxService = this.services.findIndex(
            (s) => s.idx == sp["delivery_id"]
          );
          if (idxService != -1)
            this.services[idxService].price = sp.price + unitary_veicle_price;
        });
      } else {
        this.deliveries[0].price = this.price;
      }

      if (data.price_for_service) {
        this.setPriceOnServices(data.price_for_service);
      }
    },
    setPriceOnServices(listOfPrice) {
      this.services.map(
        (s) =>
          (s["price_calculated"] = listOfPrice.find(
            (p) => p.idx == s.idx
          ).price)
      );
    },

    createNewShipment: function () {
      var that = this;

      this.errorsArray.push(
        ...ControlService.getServicesErrors(
          this.services,
          this.hasNotShipmentFields
        )
      );
      this.errorsArray.push(...ControlService.getDataErrors(this));
      if (this.missingRequiredFields()) this.errorsArray.push("missingFields");
      if (this.phoneNumberOnlyNumber())
        this.errorsArray.push("phoneNumberNotOnlyNumber");

      if (this.errorAlerts) return;

      //inserito paymentType per non cambiare il valore della select (e degli altri service!)
      if (this.paymentType != "voucher" && this.isVirtualVoucher == true) {
        this.isVirtualVoucher = false;
      }

      this.loadingResults = true;
      this.services.map((s) => (s.paymentType = this.paymentType));
      var s;
      var sm;
      var serviceSingle;
      var servicesMultiple = [];

      if (this.pickups.length === 1) {
        s = this.pickups[0];
        sm = this.deliveries;
      } else {
        s = this.deliveries[0];
        sm = this.pickups;
      }

      if (this.company.name == "Fenix") {
        serviceSingle = this.reformatServiceFenix(s, 0);
        var tot = 0;
        sm.forEach((s) => tot +=parseInt(s.packaging_data.volume_data) )
        serviceSingle.additional_data.volume_data = tot;
        sm.forEach((s, idx) => {
          servicesMultiple.push(this.reformatServiceFenix(s, idx));
        });
      }

      if (this.company.name == "Antylia Logistics") {
        serviceSingle = this.reformatServiceAntylia(s, 0);
        sm.forEach((s, idx) => {
          servicesMultiple.push(this.reformatServiceAntylia(s, idx));
        });
      }

      if (this.company.name == "DeliverMI") {
        serviceSingle = this.reformatServiceDeliverMI(s, 0);
        sm.forEach((s, idx) => {
          servicesMultiple.push(this.reformatServiceDeliverMI(s, idx));
        });
      }

      var sender_denomination = that.sender.to_s;

      RequestService.request_post(
        {
          create_shipment_attributes: {
            shipment_identifier: that.identifier,
            sender_denomination: sender_denomination,
            service_single: serviceSingle,
            services_multiple: servicesMultiple,
            end_of_month_billing: that.displaybill,
            service_date: that.serviceDate,
          },
        },
        "shipments/create_new_shipment"
      ).then(
        () => {
          that.loadingResults = false;
          that.saveAlert = true;
        },
        (error) => {
          this.$emit("errorServer", error);
          that.loadingResults = false;
          var message = error.response.data.error;
          console.log(error);
          if (message && message != undefined) {
            if (message.includes("Tagliandi non sufficienti."))
              that.errorsArray.push("insufficientVouchers");
            else if (message.includes("Identificatore servizio esistente."))
              that.errorsArray.push("existingIdentifier");
            else if (message.includes("Receiver in altra rubrica."))
              that.errorsArray.push("existingReceiver");
          } else that.errorsArray.push("general");
        }
      );
    },
    reformatServiceFenix: function (service, index) {
      if (service.address.zipcode.length < 5 ){
        var zipcode = service.address.zipcode
        var n = 6 - zipcode.length
        service.address.zipcode = new Array(n).join('0').slice((n || 2) * -1) + zipcode;
      }
      var hash = {
        identifier: service.type + "-" + (index + 1),
        service_type: service.type,
        sender_id: this.sender.id,
        skill_ids: [this.vehicleType],
        first_date: this.newDateTime(
          this.serviceDate,
          this.timeWindows[service.type].earliest
        ),
        last_date: this.newDateTime(
          this.serviceDate,
          this.timeWindows[service.type].latest
        ),
        street: service.address.street,
        town: service.address.town,
        province: service.address.province,
        zipcode: service.address.zipcode,
        price: service.price,
        created_in_open: true,
        save_in_rubric: service.saveInRubric,
        receiver_id: service.receiver_id || null,
        latitude: service.address.latitude || null,
        longitude: service.address.longitude || null,
        additional_data: {
          phone_number: service.phone_number,
          urgency: this.urgency,
          payment_type: service.paymentType,
          notes: service.notes,
          monthly_bill: this.displaybill,
          denomination: !service.denomination
            ? ""
            : service.denomination.text || service.denomination,
          packaging: service.packaging,
          width: service.packaging_data.width,
          length: service.packaging_data.length,
          height: service.packaging_data.height,
          weight: service.packaging_data.weight,
          volume: service.packaging_data.volume,
          volume_data: service.packaging_data.volume_data,
          is_virtual_voucher: this.isVirtualVoucher,
          sender_name: this.sender.to_s,
          department: this.selectedDepartment
        },
        delivery_items_attributes: (this.selecteditems || []).map((si) => ({
          item_id: si,
        })),
      };

      return hash;
    },
    reformatServiceAntylia: function (service, index) {
      var hash = {
        identifier: service.type + "-" + (index + 1),
        service_type: service.type,
        sender_id: this.sender.id,
        skill_ids: [this.vehicleType],
        first_date: this.newDateTime(
          this.serviceDate,
          this.timeWindows[service.type].earliest
        ),
        last_date: this.newDateTime(
          this.serviceDate,
          this.timeWindows[service.type].latest
        ),
        street: service.address.street,
        town: service.address.town,
        province: service.address.province,
        zipcode: service.address.zipcode,
        price: service.price_calculated,
        created_in_open: true,
        save_in_rubric: service.saveInRubric,
        receiver_id: service.receiver_id || null,
        latitude: service.address.latitude || null,
        longitude: service.address.longitude || null,
        volume: service.packaging_data.volume,
        weight: service.packaging_data.weight,
        additional_data: {
          volume_data: service.packaging_data.volume_data,
          urgency: this.urgency,
          payment_type: this.paymentType,
          notes: service.notes,
          ship_price: service.price_calculated,
          recipient_person: !service.denomination
            ? ""
            : service.denomination.text || service.denomination,
          sender_company: this.sender.username,
        },
        delivery_items_attributes: (this.selecteditems || []).map((si) => ({
          item_id: si,
        })),
      };

      return hash;
    },

    reformatServiceDeliverMI: function (service, index) {
      var timings = [];
      var earliest = this.timeWindows[service.type].earliest;
      var latest = this.timeWindows[service.type].latest;
      if (earliest != null && earliest != "") timings.push("DALLE " + earliest);
      if (latest != null && latest != "") timings.push("ALLE " + latest);
      var hash = {
        identifier: service.type + "-" + (index + 1),
        service_type: service.type,
        sender_id: this.sender.id,
        skill_ids: [this.vehicleType],
        first_date: this.newDateTime(this.serviceDate, earliest),
        last_date: this.newDateTime(this.serviceDate, latest),
        street: service.address.street,
        town: service.address.town,
        province: service.address.province,
        zipcode: service.address.zipcode,
        price: service.price_calculated,
        created_in_open: true,
        save_in_rubric: service.saveInRubric,
        receiver_id: service.receiver_id || null,
        latitude: service.address.latitude || null,
        longitude: service.address.longitude || null,
        volume: service.packaging_data.volume,
        weight: service.packaging_data.weight,
        additional_data: {
          phone_number: service.phone_number,
          timings: timings.join(" - "),
          quantity: service.packaging_data.volume,
          intercom: service.intercom,
          urgency: this.urgency,
          payment_type: this.paymentType,
          notes: service.notes,
          recipient_person: !service.denomination
            ? ""
            : service.denomination.text || service.denomination,
          sender_company: this.sender.username,
          /*
          order_notes: service.orderNotes,
          phone_number: service.phoneNumber
          */
        },
        delivery_items_attributes: (this.selecteditems || []).map((si) => ({
          item_id: si,
        })),
      };

      return hash;
    },

    addService: function (serviceType) {
      this.updatedPrice = false;
      var s = {
        address: {
          street: "",
          town: "",
          zipcode: "",
          province: "",
          latitude: null,
          longitude: null,
        },
        place: null,
        price: null,
        notes: "",
        idx: this.getTimestamp(),
        paymentType: this.paymentType,
        type: serviceType,
        denomination: null,
        phone_number: "",
        towns: [],
        zipcodes: [],
        searchTown: null,
        searchZipcode: null,
        oldsearchTown: null,
        oldsearchZipcode: null,
        saveInRubric: false,
        receiver_id: null,
        packaging: "letter",
        senderRubric: [],
        senderRubricData: [],
        hasRubricAddress: false,
        hasSenderAddress: false,
        /*
        phoneNumber: "",
        orderNotes: "",
        */
        packaging_data: {
          width: null,
          length: null,
          height: null,
          weight: null,
          volume: null,
          volume_data: null,
        },
      };
      this.services.push(s);
    },
    removeService: function (idx) {
      this.updatedPrice = false;
      var index = this.services.findIndex((s) => s.idx == idx);
      this.services.splice(index, 1);
    },
    updateServiceAddress: function (idx) {
      this.updatedPrice = false;
      var s = this.services.find((s) => s.idx == idx);
      if (this.sender && s.hasSenderAddress) {
        s.address = {
          street: this.sender.full_address.street || "",
          town: this.sender.full_address.town || "",
          province: this.sender.full_address.province || "",
          zipcode: this.sender.full_address.zipcode || "",
        };
        s.denomination = this.sender.to_s;
      } else {
        s.towns = [];
        s.zipcodes = [];
        this.clearAddress(s);
        s.denomination = "";
      }
    },
    updateTimeWindows: function () {
      this.PEMenu = false;
      this.updatedPrice = false;

      var company = String(this.company.name);
      if (company == "Antylia Logistics") company = "AntyliaLogistics";
      if (company == "AntyliaLogistics" && this.urgency == "normal") return;

      if (company == "DeliverMI") {
        this.setTimeDeliverMI();
        return;
      }

      var mins = 120;
      var vehicle;
      if (this.vehicleType && this.urgency) {
        vehicle = this.skills.filter((t) => t.id == this.vehicleType)[0].name;
      }
      if (this.vehicleTimes[company] && this.urgency && vehicle)
        mins =
          this.vehicleTimes[company][this.urgency][vehicle] ||
          this.vehicleTimes[company][this.urgency]["default"] ||
          120;
      else if (this.urgency) mins = this.getMins(this.urgency);
      else mins = 120;

      if (this.timeWindows.pickup.earliest) {
        var earliest = new Date();
        earliest.setHours(this.timeWindows.pickup.earliest.substr(0, 2));
        earliest.setMinutes(
          mins + parseInt(this.timeWindows.pickup.earliest.substr(3, 2))
        );
        this.$set(
          this.timeWindows.delivery,
          "latest",
          earliest.toLocaleString("it", {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
        this.$set(
          this.timeWindows.delivery,
          "earliest",
          this.timeWindows.pickup.earliest
        );
      }
    },
    setTimeDeliverMI() {
      var earliest = new Date();
      var today = new Date();
      if (this.urgency == "urgent") {
        var mins = 90;
        earliest.setHours(today.getHours());
        earliest.setMinutes(mins + parseInt(today.getMinutes()));
      }
      if (this.urgency == "normal") {
        earliest = this.demiverMiNormal();
      }
      this.serviceDate = moment(earliest).format("YYYY-MM-DD");
      this.$set(
        this.timeWindows.delivery,
        "latest",
        earliest.toLocaleString("it", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      this.$set(
        this.timeWindows.pickup,
        "latest",
        earliest.toLocaleString("it", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      this.$set(
        this.timeWindows.delivery,
        "earliest",
        this.timeWindows.pickup.earliest
      );
    },
    demiverMiNormal() {
      var today = new Date();
      var hour = today.getHours();
      var minutes = today.getMinutes();
      if (hour < 14 || (hour == 14 && minutes == 0)) {
        today.setHours("20");
        today.setMinutes("00");
      } else if (hour < 22 || (hour == 22 && minutes == 0)) {
        today.setDate(today.getDate() + 1);
        today.setHours("13");
        today.setMinutes("00");
      } else {
        today.setDate(today.getDate() + 1);
        today.setHours("20");
        today.setMinutes("00");
      }
      return today;
    },
    getMins(urgency) {
      if (urgency == "normal") return 120;
      if (urgency == "urgent") return 60;
      if (urgency == "very_urgent") return 30;
      if (urgency == "direct") return 30;
      if (urgency == "10_hours") return 600;
      if (urgency == "12_hours") return 720;
      return 120;
    },
    newDateTime: function (date, time) {
      if (!date || !time) {
        return null;
      } else {
        var newDate = new Date(date);
        newDate.setHours(time.substr(0, 2));
        newDate.setMinutes(time.substr(3, 2));
        newDate.setSeconds(0);
        return newDate;
      }
    },
    blankOrNull: function (x) {
      if (!x || x == "") return true;
      return false;
    },
    missingRequiredFields: function () {
      return (
        this.blankOrNull(this.sender) ||
        this.blankOrNull(this.urgency) ||
        this.blankOrNull(this.vehicleType)
      );
    },
    phoneNumberOnlyNumber() {
      var phoneNumberWithLetterPresent = false;
      this.deliveries.forEach((d) => {
        if (/[a-zA-Z]/g.test(d.phone_number)) {
          phoneNumberWithLetterPresent = true;
        }
      });
      this.pickups.forEach((p) => {
        if (/[a-zA-Z]/g.test(p.phone_number)) {
          phoneNumberWithLetterPresent = true;
        }
      });
      return phoneNumberWithLetterPresent;
    },
    checkSaveInRubric() {
      var that = this;
      return (
        this.services.filter(
          (s) => s.saveInRubric && that.getServiceDenomintation(s).length < 3
        ).length > 0
      );
    },
    getServiceDenomintation(s) {
      return !s.denomination ? "" : s.denomination.text || s.denomination;
    },
    onPlaceChanged: function (event, currentService) {
      var s = this.services.find((s) => s.idx == currentService);
      s.place = event.address_components;
      this.parseAddress(s);
    },
    parseAddress: function (service) {
      var p = service.place;
      var address = p.reduce(function (map, obj) {
        map[obj.types[0]] = obj.short_name;
        return map;
      }, {});

      service.address.street = address["route"]
        ? address["route"] +
          (address["street_number"] ? ", " + address["street_number"] : "")
        : "";
      service.address.town = address["locality"];
      service.address.province = address["administrative_area_level_2"];
      service.address.zipcode = address["postal_code"];
    },
    clearAddress: function (service) {
      this.updatedPrice = false;
      service.address = {
        street: "",
        town: "",
        province: "",
        zipcode: "",
      };
      service.place = null;
    },
    clearNewServiceDialog: function () {
      this.updatedPrice = false;
      this.services = [];
      this.urgency = "";
      this.vehicleType = "";
      this.selecteditems = [];
      this.items = [];
      this.timeWindows.pickup.earliest = null;
      this.timeWindows.pickup.latest = null;
      this.timeWindows.delivery.earliest = null;
      this.timeWindows.delivery.latest = null;
      this.serviceDate = new Date().toISOString().substr(0, 10);
      this.isVirtualVoucher = false;
      this.price = null;
      this.selectedDepartment = ''
      if (this.company.name == "DeliverMI") {
        var bikeArr = this.skills.filter((s) => s.name == "Bici");
        this.vehicleType = bikeArr[0].id;
      }
    },
    getTimestamp: function () {
      var date = new Date();
      return date.getTime();
    },
    clearIfNotIso: function (val, type, time) {
      if (!/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(val))
        this.timeWindows[type][time] = "";
    },
    completeDataReceiver(service) {
      if (service == null || service.senderRubric == null) {
        service.hasRubricAddress = false;
        service.receiver_id = null;
        return;
      }
      if (service.senderRubric.some((s) => s == service.denomination)) {
        var receiver = service.senderDataRubric.find(
          (r) => r.identifier == service.denomination
        );
        service.rubricAddress = {
          street: receiver.street,
          town: receiver.town,
          province: receiver.province,
          zipcode: receiver.zipcode != null ? receiver.zipcode.trim() : null,
        };
        service.address = {
          street: receiver.street,
          town: receiver.town,
          province: receiver.province,
          zipcode: receiver.zipcode != null ? receiver.zipcode.trim() : null,
          latitude: receiver.latitude || null,
          longitude: receiver.longitude || null,
        };
        service.receiver_id = receiver.id || null;
        service.hasRubricAddress = true;
      } else {
        service.hasRubricAddress = false;
        service.receiver_id = null;
      }
      if (service.denomination == "") this.clearAddress(service);
    },
    clearLocation(service, attribute) {
      if (!service.rubricAddress) return;

      if (service.address[attribute] != service.rubricAddress[attribute]) {
        service.address.latitude = null;
        service.address.longitude = null;
      }
    },
    getErrorMessage(error) {
      return ControlService.getErrorMessage(error);
    },
  },
  mounted() {
    if (this.$store.state.auth.session.user.departments.length){
      this.departments = JSON.parse(this.$store.state.auth.session.user.departments);
      this.departments = [''].concat(this.departments)
    }
    var defaultPaymentType =
      this.$store.state.auth.session.user.open_default_payment_type;
    this.hasDefaultVirtualVoucher =
      this.$store.state.auth.session.user.default_virtual_voucher;
    this.isVirtualVoucher =
      this.$store.state.auth.session.user.default_virtual_voucher;
    if (defaultPaymentType && defaultPaymentType != "void") {
      this.paymentArray = this.paymentArray.filter(
        (p) => p.value == defaultPaymentType
      );
      this.paymentType = this.paymentArray[0].value;
    }
    if (this.$store.state.auth.session.company.name == "DeliverMI") {
      this.paymentArray = this.paymentArray.filter((p) => p.value == "voucher");
      this.paymentType = this.paymentArray[0].value;
      var bikeArr = this.skills.filter((s) => s.name == "Bici");
      this.vehicleType = bikeArr[0].id;
    }

    ControlService.init(this.$store.state.auth.session.company.name);

    this.errorsArray.push(...ControlService.getErrorsOnMounted(this.sender));
  },
};
</script>
