<style>
.v-date-time-widget-container {
  background: white;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 12px;
}

.v-card {
  box-shadow: none;
}

.v-picker__title {
  height: 100px;
}
</style>

<template>
  <v-form ref="datetimeform">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
    >
      <template v-slot:activator="{}">
        <v-text-field
          v-model="datetime"
          :label="label"
          :hint="format"
          :dense="dense"
          :disabled="disabled"
          :outlined="outlined"
          :hide-details="hideDetails"
          :rules="validationRules"
          :error-messages="errorMessages"
          :append-icon="disabled ? null : 'mdi-calendar-clock-outline'"
          @click:append="menu = true"
          @input="emitDate"
          @change="completeDateTime"
        ></v-text-field>
      </template>
      <div class="v-date-time-widget-container">
        <v-layout row>
          <v-col :cols="onlyDate ? 12 : 6">
            <v-date-picker
              v-model="dateModel"
              color="primary"
              :locale="locale"
            ></v-date-picker>
          </v-col>
          <v-col :cols="6" v-if="!onlyDate">
            <v-time-picker
              v-model="timeModel"
              color="primary"
              format="24hr"
            ></v-time-picker>
          </v-col>
        </v-layout>
      </div>
    </v-menu>
  </v-form>
</template>

<script>
import moment from "moment";

export default {
  name: "x-date-time-picker",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    dateFormat: {
      type: String,
      default: "DD/MM/YYYY",
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      default: false,
    },
    //use BCP 47 standard
    locale: {
      type: String,
      default: "it-IT",
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    onlyDate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      // using this format directly instead of toISOString because the latter
      // automatically converts the time in UTC
      ISO_DATE_TIME_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSZ",
      ISO_DATE_FORMAT: "YYYY-MM-DD",
      ISO_TIME_FORMAT: "HH:mm",
      datetime: "",
      errors: [],
      validationRules: [
        (v) =>
          moment(v.trim(), this.format, true).isValid() ||
          v.trim() == "" ||
          `Inserire data in formato ${this.format}`,
      ],
    };
  },

  computed: {
    datetimeMoment() {
      return moment(this.datetime, this.format);
    },
    format() {
      return [this.dateFormat, this.onlyDate ? "" : this.timeFormat]
        .filter((e) => e)
        .join(" ");
    },
    dateModel: {
      get() {
        return this.datetimeMoment.isValid()
          ? this.datetimeMoment.format(this.ISO_DATE_FORMAT)
          : "";
      },
      set(val) {
        const timeStr = this.datetimeMoment.isValid()
          ? this.datetimeMoment.format(this.ISO_TIME_FORMAT)
          : "00:00";
        this.emitFromModelChange(val, timeStr);
      },
    },
    timeModel: {
      get() {
        return this.datetimeMoment.isValid()
          ? this.datetimeMoment.format(this.ISO_TIME_FORMAT)
          : "";
      },
      set(val) {
        const dateStr = this.datetimeMoment.isValid()
          ? this.datetimeMoment.format(this.ISO_DATE_FORMAT)
          : moment().format(this.ISO_DATE_FORMAT);
        this.emitFromModelChange(dateStr, val);
      },
    },
    outputFormat() {
      var format = this.ISO_DATE_TIME_FORMAT;
      if (this.onlyDate) format = this.ISO_DATE_FORMAT;
      return format;
    },
  },

  mounted() {
    this.setDatetime(this.value);
  },

  watch: {
    value(val) {
      this.setDatetime(val);
    },
  },

  methods: {
    setDatetime(val) {
      const valMoment = moment(val, this.outputFormat);
      if (val == null || !valMoment.isValid()) {
        // resetting the picker
        this.datetime = "";
      } else {
        const formattedVal = valMoment.format(this.format);
        if (formattedVal != this.datetime) {
          this.datetime = formattedVal;
        }
      }
    },
    emitDate(val) {
      if (this.$refs.datetimeform.validate()) {
        const isoDate = moment(val.trim(), this.format, true).format(
          this.outputFormat
        );
        this.$emit("input", isoDate);
      }
    },
    emitFromModelChange(dateStr, timeStr) {
      const datetimeStr = `${dateStr} ${timeStr}`;
      const newDatetime = moment(
        datetimeStr,
        `${this.ISO_DATE_FORMAT} ${this.ISO_TIME_FORMAT}`,
        true
      );
      if (newDatetime.isValid()) {
        this.$emit("input", newDatetime.format(this.outputFormat));
      }
    },
    completeDateTime(val) {
      if (!this.$refs.datetimeform.validate()) {
        val = val.trim();
        const isOnlyTime = moment(val, this.timeFormat, true).isValid();
        const isOnlyDate = moment(val, this.dateFormat, true).isValid();
        if (isOnlyDate)
          this.emitFromModelChange(
            moment(val, this.dateFormat).format(this.ISO_DATE_FORMAT),
            "00:00"
          );
        if (isOnlyTime)
          this.emitFromModelChange(
            moment().format(this.ISO_DATE_FORMAT),
            moment(val, this.timeFormat).format(this.ISO_TIME_FORMAT)
          );
      }
    },
  },
};
</script>
