<template>
  <v-dialog v-model="openDialogNewMovementProp" max-width="80%">
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <h2>
            {{ value.identifier }}
          </h2>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-text-field
              v-if="value.receiver_data.name"
              outlined
              readonly
              :label="$t('activerecord.models.receiver.one')"
              v-model="order_attributes.receiver_data.name"
              :hide-details="true"
            ></v-text-field>
            <v-text-field
              v-else
              outlined
              readonly
              :label="$t('activerecord.models.receiver.one')"
              v-model="order_attributes.receiver_data.denomination"
              :hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              readonly
              :label="$t('activerecord.attributes.order.formatted_address')"
              v-model="order_attributes.address_data.address"
              :hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              readonly
              :label="$t('activerecord.attributes.order.denomination')"
              v-model="order_attributes.receiver_data.denomination"
              :hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              readonly
              :label="$t('activerecord.models.contact.other')"
              v-model="contactText"
              :hide-details="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="movements" :sort-by.sync="sortBy" class="elevation-1">
          <template v-slot:[`item.quantity`]="{ item }">
            <v-btn
              @click="item.quantity -= 1"
              x-small
              class="mr-2"
              :disabled="item.quantity <= 0"
              color="error"
            >
              -
            </v-btn>
            {{ item.quantity }}
            <v-btn
              @click="item.quantity += 1"
              x-small
              class="ml-2"
              color="success"
            >
              +
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="$emit('close')">
          {{ $t("common.revert") }}
        </v-btn>
        <v-btn text color="light-blue" @click="createMovements()">
          {{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RequestService from "@/services/request.service.js";
export default {
  name: "new-movement-from-order",
  props: {
    inventory: {
      type: Object,
    },
    openDialogNewMovement: {
      type: Boolean,
    },
    value: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      openDialogNewMovementProp: JSON.parse(JSON.stringify(this.openDialogNewMovement)),
      order_attributes: JSON.parse(JSON.stringify(this.value)),
      movements: [],
      sortBy: 'order_line',
      headers: [
        {
          text: this.$t('activerecord.attributes.item.identifier'),
          align: "center",
          sortable: false,
          value: "identifier",
        },
        {
          text: this.$t('activerecord.attributes.item.name'),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t('activerecord.attributes.item.serial_number'),
          align: "center",
          sortable: false,
          value: "serial_number",
        },
        {
          text: this.$t('activerecord.attributes.item.order_line'),
          align: "center",
          sortable: false,
          value: "order_line",
        },
        {
          text: this.$t('activerecord.attributes.item.expected_stock'),
          align: "center",
          sortable: false,
          value: "expected_stock",
        },
        {
          text: this.$t('activerecord.attributes.item.effective_quantity'),
          align: "center",
          sortable: false,
          value: "quantity",
        },
      ],
    };
  },

  mounted() {
    this.initializeMovements(this.value.items);
  },

  watch: {
    value: {
      handler(val) {
        this.initializeMovements(val.items);
      },
      deep: true,
    },
  },
  computed: {
    contactText() {
      if (!this.value.receiver_data) return "";
      if (!this.value.receiver_data.contacts) return "";
      return this.value.receiver_data.contacts.length == 1
        ? this.value.receiver_data.contacts[0] + ""
        : this.value.receiver_data.contacts.slice(0, 2).join(" - ");
    },
  },
  methods: {
    createMovements() {
      var that = this;
      var kind = "in";
      const params = {
        order_id: this.value.id,
        kind: kind,
        inventory_id: this.inventory.id,
        from_open: true,
        movements: this.movements
          .map((mov) => ({
            quantity: mov.quantity,
            order_id: this.value.id,
            item_id: mov.item_id,
            kind: kind,
            warehouse_area_id: mov.warehouse_area_id,
            place_id: null,
            suggested_place_id: null,
            damaged: false,
            inventory_id: mov.inventory_id,
          }))
          .filter((mov) => mov.quantity > 0),
      };
      RequestService.request_post(params, "items/sync_inventory")
        .then((res) => {
          that.$emit("refresh-orders", that.value.identifier);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      this.$emit("close");
    },
    initializeMovements(items) {
      this.movements = items.map((i) => ({
        item_id: i.id,
        quantity: 0,
        kind: 0,
        identifier: i.identifier,
        order_line: i.order_line,
        inventory_id: this.inventory.id,
        warehouse_area_id:
          this.$store.state.auth.session.user.warehouse_area_id,
        name: i.name,
        expected_stock: i.expected_stock,
      }));
    },
  },
};
</script>