<template>
  <v-container fluid>
    <v-row style="text-align: center">
      <h2>Pagamenti: {{totalPayment}}</h2>
      <v-spacer></v-spacer>
      <payment-modal
        :value="buildVoidPayment()"
        :document-enum="documentEnum"
        :payment-enum="paymentEnum"
        :status-enum="statusEnum"
        @save="$emit('new', $event)"
      />
    </v-row>
    <v-row
      v-for="(groupsOfPayment, groupIdx) in spittedPayments"
      v-bind:key="groupIdx"
    >
      <v-col
        :cols="12 / PAYMENTS_IN_ROW"
        v-for="(payment, paymentIdx) in groupsOfPayment"
        v-bind:key="paymentIdx"
      >
        <payment-card
          v-model="payments[groupIdx * PAYMENTS_IN_ROW + paymentIdx]"
          @update="$emit('update', $event)"
          :document-enum="documentEnum"
          :payment-enum="paymentEnum"
          :status-enum="statusEnum"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable prettier/prettier */

import PaymentCard from "./PaymentCard.vue";
import PaymentModal from "./PaymentModal.vue";

export default {
  name: "DeliveryPayments",
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    documentEnum: { type: Array },
    paymentEnum: { type: Array },
    statusEnum: { type: Array },
  },
  components: {
    PaymentCard,
    PaymentModal,
  },
  data() {
    return {
      payments: JSON.parse(JSON.stringify(this.value)),
      PAYMENTS_IN_ROW: 6,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.payments = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
  },
  computed: {
    spittedPayments() {
      return this.payments.reduce(
        (acc, cur) => {
          if (acc[acc.length - 1].length < 6) acc[acc.length - 1].push(cur);
          else acc.push([cur]);
          return acc;
        },
        [[]]
      );
    },
    totalPayment() {
      return this.payments.map((p) => p.price).reduce((a, c) => a + c, 0);
    },
  },
  methods: {
    buildVoidPayment() {
      return {
        id: null,
        price: 0.0,
        discount: 0.0,
        document_kind: null,
        payment_kind: null,
        status: "to_pay",
      };
    },
  },
};
</script>
