<template>
  <div>
    <v-expansion-panel class="mb-2">
      <v-expansion-panel-header>
        <v-row align="center" class="ma-0 pa-0">
          <v-row v-if="!invoiceDownloaded">
            <v-col cols="4">
              <v-text-field
                :label="$t('activerecord.attributes.payment.price')"
                type="string"
                v-model="payment_attributes.price"
                required
                outlined
                dense
                hide-details
                :readonly="hasItemPayments"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <x-date-time-picker
                :value="payment_attributes.created_at || now()"
                @input="payment_attributes.created_at = $event"
                :label="$t('activerecord.attributes.payment.created_at')"
                required
                outlined
                dense
                readonly
                :disabled="payment_attributes.document_kind != 'receipt'"
                hide-details
              />
            </v-col>
            <v-col
              cols="2"
              v-if="
                payment_attributes.id != null &&
                payment_attributes.document_kind != 'receipt'
              "
              align="center"
            >
              <v-btn
                color="info"
                align-center
                small
                @click.native.stop="generateInvoice()"
                :disabled="clickedGenerate"
              >
                <v-icon color="white"> mdi-receipt </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="red"
                align-center
                small
                @click.native.stop="payment_attributes._destroy = true"
                align="center"
              >
                <v-icon color="white"> mdi-delete </v-icon>
              </v-btn>
            </v-col>

            <v-btn v-show="false" ref="openpanel">
              <!-- SHIT USED FOR OPENING PANEL -->
            </v-btn>
          </v-row>
          <v-row v-else>
            <v-col cols="4">
              <v-text-field
                :label="$t('activerecord.attributes.payment.price')"
                type="string"
                v-model="payment_attributes.price"
                required
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                :label="$t('activerecord.attributes.payment.created_at')"
                type="string"
                v-model="payment_attributes.created_at"
                required
                outlined
                dense
                readonly
                hide-details
              ></v-text-field>
            </v-col>

            <v-col v-if="isMaster" cols="1" align="center">
              <v-btn
                color="grey darken-2"
                align-center
                small
                @click.native.stop="downloadXmlInvoice"
                v-if="invoiceConfirmed"
              >
                <v-icon color="white"> mdi-xml </v-icon>
              </v-btn>
              <v-btn
                color="green darken-2"
                align-center
                small
                @click.native.stop="confirmInvoice()"
                v-if="!invoiceConfirmed"
              >
                <v-icon color="white"> mdi-check-bold </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1" align="center">
              <v-btn
                color="grey darken-2"
                align-center
                small
                @click.native.stop="downloadPdfInvoice"
              >
                <v-icon color="white"> mdi-file-pdf-box </v-icon>
              </v-btn>
            </v-col>

            <v-col v-if="isMaster" cols="1" align="center">
              <v-btn
                color="warning"
                align-center
                small
                @click.native.stop="createCreditNote"
                :disabled="isCreditNote || hasCreditNote"
                v-if="invoiceConfirmed"
              >
                <v-icon color="white"> mdi-alpha-n-circle </v-icon>
              </v-btn>
              <v-btn
                color="yellow darken-2"
                align-center
                small
                @click.native.stop="regenerateInvoice"
                v-if="!invoiceConfirmed"
              >
                <v-icon color="white"> mdi-sync </v-icon>
              </v-btn>
            </v-col>

            <v-col v-if="isMaster" cols="1" align="center">
              <v-btn
                color="error"
                align-center
                small
                @click.native.stop="destroyInvoice"
              >
                <v-icon color="white"> mdi-delete </v-icon>
              </v-btn>
            </v-col>

            <v-btn v-show="false" ref="openpanel"
              ><!-- SHIT USED FOR OPENING PANEL --></v-btn
            >
          </v-row>
          <v-row v-if="payment_attributes.from_sync"
            ><v-col cols="12"
              >{{
                $t("helpers.effects.downloaded.m.one", null, {
                  model: $t("activerecord.models.payment.one"),
                })
              }}
              {{ $t("common.from.u").toLowerCase() }} Fip</v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="ID"
                type="string"
                :value="payment_attributes.id"
                @click.native.stop=""
                outlined
                dense
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col v-if="payment_attributes.reverse_payment_id != null">
              <v-text-field
                :label="
                  $t('activerecord.attributes.payment.reverse_payment_id')
                "
                type="string"
                :value="payment_attributes.reverse_payment_id"
                @click.native.stop=""
                outlined
                dense
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('activerecord.attributes.payment.payment_kind')"
                type="string"
                :value="paymentKind"
                @click.native.stop=""
                outlined
                dense
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('activerecord.models.payment_method.one')"
                type="string"
                :value="paymentMethod"
                :error-messages="paymentMethodErrors"
                @click.native.stop=""
                outlined
                dense
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-if="invoiceDownloaded"
                :label="
                  $t('activerecord.attributes.payment.invoice_progressive')
                "
                type="string"
                :value="invoiceProgressive"
                @click.native.stop=""
                outlined
                dense
                readonly
                hide-details
              ></v-text-field>
              <v-text-field
                v-else
                :label="$t('activerecord.attributes.payment.document_kind')"
                type="string"
                :value="documentKind"
                @click.native.stop=""
                outlined
                dense
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col v-if="!invoiceDownloaded" cols="9">
            <v-textarea
              outlined
              :label="$t('activerecord.attributes.payment.payment_description')"
              hide-details="true"
              v-model="payment_attributes.description"
              auto-grow
              rows="1"
              dense
            ></v-textarea>
          </v-col>
          <v-col v-if="invoiceDownloaded" cols="7">
            <v-textarea
              outlined
              :label="$t('activerecord.attributes.payment.payment_description')"
              hide-details="true"
              v-model="payment_attributes.description"
              :disabled="invoiceDownloaded"
              auto-grow
              rows="1"
              dense
            ></v-textarea>
          </v-col>
          <v-col v-if="invoiceDownloaded" cols="2">
            <edit-description-modal
              :paymentDescription="payment_attributes.description"
              @confirm="confirmEditDescriptionModal($event)"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              outlined
              :label="$t('activerecord.attributes.payment.status')"
              hide-details="true"
              v-model="payment_attributes.status"
              :items="paymentStatuses"
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="10" :offset="1" align="center">
            <ul v-if="invoiceErrors != null">
              <li v-for="err in invoiceErrors" :key="err" style="color: red">
                {{ err }}
              </li>
            </ul>
          </v-col>
        </v-row>

        <item-payment-slot
          v-for="(
            item_payment_attributes, idx
          ) in payment_attributes.item_payments_attributes"
          v-bind:key="item_payment_attributes.uuid"
          v-show="!item_payment_attributes._destroy"
          v-model="payment_attributes.item_payments_attributes[idx]"
          :items="items"
          :invoiceConfirmed="invoiceConfirmed"
          :expenses="expenses"
        >
        </item-payment-slot>
        <v-row>
          <v-col>
            <v-btn color="success" depressed @click="addItemPaymentAttributes()">
              {{ $t("common.add") }}
            </v-btn>
          </v-col>
        </v-row>
        <br v-if="!invoiceDownloaded" />
        <br v-if="!invoiceDownloaded" />
        <v-row>
          <v-col align="center" cols="4">
            <v-select
              v-model="payment_attributes.payment_kind"
              :items="paymentKinds"
              outlined
              dense
              hide-details="auto"
              item-text="text"
              item-value="value"
              :label="$t('activerecord.attributes.payment.payment_kind')"
              readonly
              v-if="!invoiceDownloaded"
            />
          </v-col>
          <v-col align="center" cols="4">
            <v-select
              v-model="payment_attributes.payment_method_id"
              :items="paymentMethods"
              outlined
              dense
              hide-details="auto"
              item-text="description"
              item-value="id"
              :label="$t('activerecord.models.payment_method.one')"
              v-if="!invoiceConfirmed"
            />
          </v-col>
          <v-col align="center" cols="4">
            <v-select
              v-model="payment_attributes.document_kind"
              :items="documentKindsForSelect"
              outlined
              dense
              hide-details="auto"
              item-text="text"
              item-value="value"
              :label="$t('activerecord.attributes.payment.document_kind')"
              v-if="!invoiceDownloaded"
              :disabled="isCreditNote"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-snackbar
      v-model="snackbar.opened"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      left
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import ItemPaymentSlot from "./ItemPaymentSlot.vue";
import XDateTimePicker from "./utilities/x_date_time_picker.vue";
import RequestService from "@/services/request.service.js";
import moment from "moment";
import EditDescriptionModal from "./payment/EditDescriptionModal.vue";

export default {
  name: "payment-panel",
  props: {
    value: {
      type: Object,
    },
    items: {
      type: Array,
    },
    expenses: {
      type: Array,
    },
    paymentMethods: {
      type: Array,
    },
    documentKinds: {
      type: Array,
    },
    paymentKinds: {
      type: Array,
    },
    paymentStatuses: {
      type: Array,
    },
  },

  components: { ItemPaymentSlot, EditDescriptionModal, XDateTimePicker },

  data() {
    return {
      invoiceDownloaded: false,
      invoiceConfirmed: false,
      invoiceId: null,
      invoiceProgressive: null,
      invoiceErrors: null,
      sameChange: false,
      payment_attributes: JSON.parse(JSON.stringify(this.value)),
      snackbar: {
        opened: false,
        timeout: 2000,
        color: "",
        text: "",
      },
      paymentMethodErrors: null,
      clickedGenerate: false,
    };
  },

  watch: {
    payment_attributes: {
      handler(val) {
        this.sameChange = true;
        this.$emit("input", val);
      },
      deep: true,
    },
    itemPaymentTotalPrice(val) {
      this.payment_attributes.price = val;
    },
  },
  computed: {
    isBalance() {
      if (
        this.payment_attributes.payment_kind == 1 ||
        this.payment_attributes.payment_kind == "balance"
      )
        return true;
      return false;
    },
    isMaster() {
      return this.$store.state.auth.session.user.master;
    },
    documentKindsForSelect() {
      if (this.payment_attributes.document_kind == "credit_note")
        return this.documentKinds;
      else return this.documentKinds.filter((dk) => dk.value != "credit_note");
    },
    hasItemPayments: function () {
      if (this.payment_attributes.item_payments_attributes.length > 0)
        return true;
      else return false;
    },
    isCreditNote: function () {
      return this.payment_attributes.document_kind == "credit_note";
    },
    hasCreditNote: function () {
      return this.payment_attributes.has_credit_note;
    },
    itemPaymentTotalPrice: function () {
      return this.round2Decimals(
        this.payment_attributes.item_payments_attributes
          .filter((ip) => !ip._destroy)
          .map((x) => parseFloat(x.price))
          .filter((x) => !isNaN(x))
          .reduce((acc, cur) => acc + cur, 0)
      );
    },
    paymentKind() {
      const paymentKind = (
        this.paymentKinds.find(
          (x) => x.value == this.payment_attributes.payment_kind
        ) || { text: this.$t("errors.messages.not_inserted") }
      ).text;
      return `${paymentKind}`;
    },
    paymentMethod() {
      const paymentMethod = (
        this.paymentMethods.find(
          (x) => x.id == this.payment_attributes.payment_method_id
        ) || { description: this.$t("errors.messages.not_inserted") }
      ).description;
      return `${paymentMethod}`;
    },
    documentKind() {
      const documentKind = (
        this.documentKinds.find(
          (x) => x.value == this.payment_attributes.document_kind
        ) || { text: this.$t("errors.messages.not_inserted") }
      ).text;
      return `${documentKind}`;
    },
  },

  methods: {
    now() {
      return moment();
    },
    confirmInvoice() {
      var that = this;
      var address = "invoices/" + this.invoiceId + "/invoice_confirmation";
      if (that.sameChange) {
        that.snackbar = {
          opened: true,
          timeout: 4000,
          color: "error",
          text: that.$t("alert.save_before_action.f", null, {
            action: that
              .$t("common.confrim_action", null, { model: "" })
              .toLowerCase(),
            model: that.$t("activerecord.models.invoice.one").toLowerCase(),
          }),
        };
        return;
      }
      RequestService.request_post({}, address).then(
        (response) => {
          that.invoiceConfirmed = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    confirmEditDescriptionModal(params) {
      var that = this;
      var address = "invoices/" + this.invoiceId + "/update_description";
      RequestService.request_post(params, address).then(
        (response) => {
          that.payment_attributes.description = response.data;
        },
        (error) => {
          that.snackbar = {
            opened: true,
            timeout: 4000,
            color: "error",
            text:
              that.$t("errors.messages.error_during_action", null, {
                action: that.$t("helpers.actions.creation", null, {
                  model: that.$t("activerecord.models.invoice.one"),
                }),
              }) +
              that.$t(
                "activerecord.errors.messages.contact_technical_support"
              ) +
              error,
          };
        }
      );
    },
    createCreditNote() {
      this.$emit("credit-note", this.payment_attributes);
    },
    addItemPaymentAttributes() {
      this.payment_attributes.item_payments_attributes.push({
        id: null,
        item_id: null,
        price: 0,
        quantity: 1,
        uuid: Math.floor(Math.random() * 1000000),
        company_id: this.$store.state.auth.session.company.id,
        _destroy: false,
      });
    },
    getProgressive(invoice) {
      return invoice.data.FatturaElettronica.FatturaElettronicaHeader
        .DatiTrasmissione.ProgressivoInvio;
    },
    regenerateInvoice() {
      var that = this;
      if (that.sameChange) {
        that.snackbar = {
          opened: true,
          timeout: 4000,
          color: "error",
          text: that.$t("alert.save_before_action.f", null, {
            action: that
              .$t("common.confrim_action", null, { model: "" })
              .toLowerCase(),
            model: that.$t("activerecord.models.invoice.one").toLowerCase(),
          }),
        };
        return;
      }
      this.invoiceErrors = null;
      if (this.paymentMethod == this.$t("errors.messages.not_inserted")) {
        this.paymentMethodErrors = this.$t(
          "activerecord.errors.messages.mandatory_field"
        );
        return;
      } else {
        this.paymentMethodErrors = null;
      }
      var params = {
        payment: this.payment_attributes,
        sender_auth_id: this.$store.state.auth.session.user.id,
      };
      var address =
        "payments/" + this.payment_attributes.id + "/regenerate_invoice";
      RequestService.request_post(params, address).then(
        (response) => {
          console.log(response);
          that.invoiceDownloaded = true;
          that.invoiceId = response.data.id;
          that.invoiceProgressive = that.getProgressive(response.data);
          that.invoiceConfirmed = response.data.confirmed;
          that.snackbar = {
            opened: true,
            timeout: 2000,
            color: "success",
            text:
              that.$t("activerecord.models.invoice.one") +
              that.$t("common.regenerated") +
              that.$t("common.correctly"),
          };
          that.payment_attributes.item_payments_attributes =
            response.data.payment.item_payments.map((ip) => ({
              id: ip.id,
              item_id: ip.item_id,
              expense_id: ip.expense_id,
              price: ip.price,
              quantity: ip.quantity,
              _destroy: false,
              uuid: Math.floor(Math.random() * 1000000),
            }));
        },
        (error) => {
          if (error.response.status == 500) {
            this.snackbar = {
              opened: true,
              timeout: 2000,
              color: "error",
              text:
                that.$t("errors.messages.error_during_action", null, {
                  action: that.$t("helpers.actions.creation", null, {
                    model: that.$t("activerecord.models.invoice.one"),
                  }),
                }) +
                that.$t(
                  "activerecord.errors.messages.contact_technical_support"
                ),
            };
          }
          if (error.response.status == 422) {
            that.invoiceErrors = error.response.data.data; // errors on data field of invoices
          }
          setTimeout(() => {
            that.$refs.openpanel.$el.click();
          }, 10);
        }
      );
    },
    generateInvoice() {
      this.clickedGenerate = true;
      var that = this;
      this.invoiceErrors = null;
      if (this.paymentMethod == this.$t("errors.messages.not_inserted")) {
        this.paymentMethodErrors = this.$t(
          "activerecord.errors.messages.mandatory_field"
        );
        return;
      } else {
        this.paymentMethodErrors = null;
      }
      var params = {
        payment: this.payment_attributes,
        sender_auth_id: this.$store.state.auth.session.user.id,
      };
      var address =
        "payments/" + this.payment_attributes.id + "/generate_invoice";
      console.log(address);
      RequestService.request_post(params, address).then(
        (response) => {
          that.invoiceDownloaded = true;
          that.invoiceId = response.data.id;
          that.invoiceProgressive = that.getProgressive(response.data);
          that.invoiceConfirmed = response.data.confirmed;
        },
        (error) => {
          if (error.response.status == 500) {
            this.snackbar = {
              opened: true,
              timeout: 2000,
              color: "error",
              text:
                that.$t("errors.messages.error_during_action", null, {
                  action: that.$t("helpers.actions.creation", null, {
                    model: that.$t("activerecord.models.invoice.one"),
                  }),
                }) +
                that.$t(
                  "activerecord.errors.messages.contact_technical_support"
                ),
            };
          }
          if (error.response.status == 422) {
            that.invoiceErrors = error.response.data.data; // errors on data field of invoices
            if (!error.response.data.data) {
              // NOT Invoice errors
              this.snackbar = {
                opened: true,
                timeout: 4000,
                color: "error",
                text: that.$t("alert.error_during_action_try_to", null, {
                  action: that.$t("helpers.actions.creation", null, {
                    model: that.$t("activerecord.models.invoice.one"),
                  }),
                  try_to_action: that.$t("common.save_action.f", null, {
                    model: that.$t("activerecord.models.invoice.one"),
                  }),
                }),
              };
            }
          }
          setTimeout(() => {
            that.$refs.openpanel.$el.click();
          }, 10);
        }
      );
    },
    downloadXmlInvoice() {
      var that = this;
      var address = "invoices/" + this.invoiceId + "/download_xml.xml";
      RequestService.request_get({}, address).then(
        (response) => {
          var xmltext = response.data;

          var now = moment().format("DD-MM-YYYY_HH-mm");
          var fileName = now + "_fattura-" + that.invoiceId + ".xml";
          var pom = document.createElement("a");
          var bb = new Blob([xmltext], { type: "text/plain" });

          pom.setAttribute("href", window.URL.createObjectURL(bb));
          pom.setAttribute("download", fileName);

          pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
          );
          pom.draggable = true;
          pom.classList.add("dragout");

          pom.click();
        },
        (error) => {
          console.log(error);
        }
      );
      console.log(address);
    },
    downloadPdfInvoice() {
      var that = this;
      var address = "invoices/" + this.invoiceId + "/download_pdf.pdf";
      var params = { sender_auth_id: this.$store.state.auth.session.user.id };
      RequestService.request_get(params, address, "blob").then(
        (response) => {
          var now = moment().format("DD-MM-YYYY_HH-mm");
          var fileName = now + "_fattura-" + that.invoiceId + ".pdf";
          var blob = new Blob([response.data], { type: "application/pdf" });
          var url = window.URL.createObjectURL(blob);
          window.open(url);
          var a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
      console.log(address);
    },
    destroyInvoice() {
      var that = this;
      var address = "invoices/" + this.invoiceId + ".json";
      RequestService.request_delete(address).then(
        (response) => {
          that.invoiceDownloaded = false;
          that.invoiceId = null;
          console.log(response);
        },
        (error) => {
          that.invoiceErrors = error.response.data.base;
          setTimeout(() => {
            that.$refs.openpanel.$el.click();
          }, 10);
        }
      );
    },
    round2Decimals(val) {
      return Math.round((val + Number.EPSILON) * 100) / 100;
    },
  },
  mounted() {
    if (this.payment_attributes.invoice) {
      this.invoiceDownloaded = true;
      this.invoiceId = this.payment_attributes.invoice.id;
      this.invoiceConfirmed = this.payment_attributes.invoice.confirmed;
      this.invoiceProgressive = this.getProgressive(
        this.payment_attributes.invoice
      );
    } else {
      this.invoiceDownloaded = false;
    }
  },
};
</script>
