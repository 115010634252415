<template>
  <v-data-table :headers="manualMovementsHeaders" :items="movements" :item-class="genClass" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" class="elevation-1">
  </v-data-table>
</template>

<script>
export default {
  name: "ManualMovement",
  props: {
    value: {
      type: Array,
    }
  },
  data() {
    return {
      movements: JSON.parse(JSON.stringify(this.value)),
      sortBy: 'idx',
      sortDesc: true,
      manualMovementsHeaders: [
        {
          text: '',
          value: "idx",
        },
        {
          text: this.$t('activerecord.attributes.item.name'),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t('activerecord.models.order.one'),
          align: "center",
          sortable: false,
          value: "orderIdentifier",
        },
        {
          text: this.$t('activerecord.attributes.item.order_line'),
          align: "center",
          sortable: false,
          value: "orderLine",
        },
        {
          text: this.$t('activerecord.attributes.item.identifier'),
          align: "center",
          sortable: false,
          value: "identifier",
        },
        {
          text: this.$t('activerecord.attributes.item.expected_stock'),
          align: "center",
          sortable: false,
          value: "expectedStock",
        },
        {
          text: this.$t('activerecord.attributes.item.unitary_packages'),
          align: "center",
          sortable: false,
          value: "unitaryPieces",
        },
        {
          text: this.$t('activerecord.attributes.item.confirmed_quantity'),
          align: "center",
          sortable: false,
          value: "qtaMoved",
        }
      ]
    };
  },
  computed: {
    
  },
  watch: {
    value: {
      handler(val) {
        this.movements = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
  },
  methods: {
    genClass(item) {
      console.log(item)
      const isComplited = this.itemCompleted(item);
      if(isComplited)
        return "green"
      return "yellow"
    },
    itemCompleted(item) {
      return (
        item.qtaMoved ==
        item.expectedStock * item.unitaryPieces
      );
    },
  },
};
</script>
