<template>
  <v-row align-content="center" no-gutters>
    <v-col cols="10">
      <v-autocomplete
        v-model="item"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        item-text="composedIdentifier"
        return-object
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        :label="$t('search', null, { model: $t('activerecord.models.item.one') })"
        @change="addItem()"
        @keyup.enter="addItem()"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`${item.identifier}`"
              ></v-chip>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`${item.name}`"
              ></v-chip>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`${item.article_code}`"
                v-if="item.article_code"
              ></v-chip>
              <br />
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`Prevista: ${item.expected_stock || 0}`"
              ></v-chip>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`In magazzino: ${item.stock || 0}`"
              ></v-chip>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`Ordine: ${item.order.identifier}`"
              ></v-chip>
            </v-list-item-title>
            <v-divider></v-divider>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="2">
      <v-checkbox
        v-model="senderWarehouse"
        :label="$t('common.in_shop')"
        hide-details="true"
        class="mt-0 ml-4"
      />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable prettier/prettier */
import RequestService from "@/services/request.service.js";

export default {
  name: "add-item-field",
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    senderId: {
      type: Number,
      required: true,
    },
    filterBySender: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      item: null,
      items: [],
      search: null,
      loading: false,
      senderWarehouse: false,
    };
  },
  methods: {
    addItem() {
      if (!this.item) return;

      this.$emit("add-item", this.item);
      this.item = null;
      this.items = [];
    },
    clear() {
      this.item = null;
      (this.search = null),
        (this.loading = false),
        (this.senderWarehouse = false);
    },
    fetchItems(val) {
      var that = this;
      this.loading = true;
      return RequestService.request_post(
        {
          query: val,
          sender_id: this.filterBySender ? this.senderId : null,
          warehouse_area_id: this.senderWarehouse
            ? this.$store.state.auth.session.user.warehouse_area_id
            : null,
        },
        "items/search"
      ).then(
        (response) => {
          that.items = response.data.items.map((item) => {
            item.composedIdentifier = `${item.identifier} - ${item.name}`;
            return item;
          });
          that.loading = false;
        },
        (error) => {
          this.$emit("error", error);
          alert("Errore di comunicazione con il server.");
          console.log(error);
          that.loading = false;
        }
      );
    },
  },
  watch: {
    search(val) {
      if (!val || val.length < 3) {
        this.items = [];
        return;
      }
      this.fetchItems(val);
    },
  },
};
</script>
