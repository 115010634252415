class inventoryExport {
  constructor() {
    
  }
  init() {
    
  }


  

  //METHODS
  realExport(lines, inventoryName) {
    const ExcelJS = require("exceljs");
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Ermes-X';
    workbook.lastModifiedBy = 'Ermes-X';
    // Create worksheets with headers and footers
    lines.forEach(sender_orders => {
      var sender_name = sender_orders[0][0].order_sender_username
      const ws = workbook.addWorksheet(sender_name, {
        pageSetup: { 
          paperSize: 9, 
          orientation:'landscape',
          fitToPage: true, fitToHeight: 1, fitToWidth: 1,
          margins: {
            left: 0.1, right: 0.1,
            top: 0.1, bottom: 0.1,
            header: 0.1, footer: 0.1
          }
        }
      });
      ws.views = [{}]
      var rowidx = 3;
      this.writeData(ws, sender_orders, rowidx);
      this.writeHeader(ws, sender_name);
    }),
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = inventoryName+'.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    })
  }
  
  writeHeader(ws,sender_name) {
  
    ws.mergeCells('A1:D1');
    ws.getCell('A1').value = 'Situazione del magazzino TRANSITO di:';
    ws.getCell('A1').border = {
      bottom: { style: "thin" },
    };
    
    ws.getCell('A1').font = { size: 14 };
    ws.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle' }
     
    ws.mergeCells('E1:F1');
    ws.getCell('E1').value = sender_name;
    ws.getCell('E1').border = {
      bottom: { style: "thin" },
    };
    
    ws.getCell('E1').font = { size: 14 };
    ws.getCell('E1').alignment = { horizontal: 'center', vertical: 'middle' }
    var headers = ["Qta. Prev.", "Qta. reale", "Codice", "Descrizione", "Configurazione", "Ord. Riassortimento"];
    ws.getColumn('A').width = 10;
    ws.getColumn('B').width = 10;
    ws.getColumn('C').width = 20;
    ws.getColumn('D').width = 40;
    ws.getColumn('E').width = 40;
    ws.getColumn('F').width = 30;
    var headerRow = ws.getRow(3);
    for (var i = 0; i < headers.length; i++) {
     headerRow.getCell(i + 1).value = headers[i];
     headerRow.getCell(i + 1).alignment = { horizontal: 'center', vertical: 'middle' };
    }
  }
  
  writeData(ws, lines, fromRow) {
    var data = [];
  
    for (var i = 0; i <= fromRow; i++) {
      data.push([]);
    }
  
    var row_to_merge_cells = [fromRow +1 ]
    lines.forEach(line => {
      row_to_merge_cells.push(line.length +row_to_merge_cells[row_to_merge_cells.length - 1]+1)
    })
  
    lines.forEach(line => {
  
        data.push([
          [line[0].order_identifier,line[0].order_receiver_name].join('-')
        ]);
  
        line.forEach(item => { 
          var exp_qta = item.exp_qta
          var code = item.code || '';
          var real_qta = item.real_qta || 0;
          var desc = ''
          if (item.description){
            desc = item.description.split("\n").find(a =>a.includes("Articolo")).split('Articolo: ').pop();
          }
          var config = item.config || '';
          var order_rif = item.order_rif;
  
          data.push([
            exp_qta,
            real_qta,
            code,
            desc,
            config, 
            order_rif
          ]);
        })
        
    })
  
    for (var i0=0; i0<data.length; i0++) {
      for (var j=0; j<data[i0].length; j++)  {
        ws.getRow(i0+1).getCell(j+1).value = data[i0][j];
        var style = { }
        var alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
        if (row_to_merge_cells.includes(i0)  ){
          style = { bottom: { style: "thin" } };
          alignment = { vertical: 'middle', wrapText: true }
          ws.getRow(i0+1).getCell(j+1).font = { size: 14, bold: true };
          var cell_from = ws.getCell(i0+1,1)._address
          var cell_to = ws.getCell(i0+1,6)._address
          var merge = cell_from+':'+cell_to
          ws.mergeCells(merge)
        }
        ws.getRow(i0+1).getCell(j+1).border = style;
        
        ws.getRow(i0+1).getCell(j+1).alignment = alignment;
      }
    }
  
    var defaultRowHeight = 20;
    for (var i1=0; i1<data.length; i1++) {
      var maxLines = 1;
      for (var j1=0; j1<data[i1].length; j1++) {
        var val = ws.getRow(i1+1).getCell(j1+1).value;
        if (val) {
          var lines1 = Math.ceil(val.toString().length / 40)
          if (lines1 > maxLines)
            maxLines = lines1;
        }
      }
      ws.getRow(i+1).height = maxLines * defaultRowHeight;
    }
  
    // ws.columns.forEach(function (column, i) {
    //   var maxLength = 6;
    //   column["eachCell"]({ includeEmpty: true }, function (cell) {
    //     if (cell.value) {
    //       var strVal = cell.toString();
    //       var splitted = strVal.split("\n");
    //       splitted.forEach((line) => {
    //         var len = line.length;
    //         if (len > maxLength)
    //         maxLength = len;
    //       })
    //     }
    //   });
    //   column.width = maxLength < column.width ? column.width : maxLength + 4;
    // });
  
  }


  //UTILITY FUNCTIONS

}
  
export default new inventoryExport();
  