<template>
  <v-dialog v-model="dialog" max-width="80vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        :color="kind == IN ? 'success' : 'warning'"
        v-bind="attrs"
        v-on="on"
      >
        <h2>
          <b>{{ kind == IN ? "+" : "-" }}</b>
        </h2>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="#343a40">
        <h2>
          <font color="#FFFFFF"
            >{{ $t("common.change") }}
            {{ $t("activerecord.models.warehouse.one") }}
            {{
              kind == IN ? $t("common.kind_in") : $t("common.kind_out")
            }}</font
          >
        </h2>
        <v-spacer />
        <v-btn
          @click="dialog = false"
          color="white"
          outlined
          dense
          hide-details
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="quantity"
            outlined
            dense
            hide-details="auto"
            :label="$t('activerecord.attributes.movement.quantity')"
            :rules="[rules.present, rules.greaterThenZero, rules.outSmallerThenStock]"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions style="background-color: #343a40">
        <v-spacer />
        <v-btn color="white" outlined dense hide-details @click="confirm()">
          {{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable radix */

import RequestService from "@/services/request.service.js";

export default {
  name: "create-movement-dialog",
  props: {
    itemId: {
      type: Number,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    maxCanRemove: {
      type: Number,
    },
    inventory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      IN: "in",
      OUT: "out",
      dialog: false,
      quantity: null,
      rules: {
        present: (value) => !!value || "Campo obbligatorio",
        greaterThenZero: (value) =>
          value > 0 || "La quantità deve essere maggiore di 0",
        outSmallerThenStock: (value) =>
          (value <= this.maxCanRemove || this.kind != 'out') || "La quantità deve essere inferiore  alla quantità confermata",
      },
    };
  },
  methods: {
    confirm() {
      if (!this.$refs.form.validate()) return;
      var that = this;
      var qta = this.quantity;
      if (this.kind == "out") {
        qta = qta * -1;
      }

      const params = {
        order_id: this.orderId,
        kind: this.kind,
        from_open: true,
        movement: {
          quantity: qta,
          item_id: this.itemId,
          kind: this.kind,
          warehouse_area_id:
            this.$store.state.auth.session.user.warehouse_area_id,
          place_id: null,
          suggested_place_id: null,
          damaged: false,
          inventory_id: this.inventory.id,
        },
      };
      RequestService.request_post(params, "items/move")
        .then((res) => {
          console.log(res);
          that.$emit("movement-added", that.itemId);

          this.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>