<template>
  <v-card 
    elevation="2"
    outlined
    class="mt-5"
    v-if="show"
  >
    <v-card-title>{{ $t('activerecord.models.item.other') }}</v-card-title>
    <v-card-text
      style="max-height: 500px; overflow-y: scroll"
    >
      <v-layout align-center justify-center column fill-height v-if="loading">
        <v-flex row align-center>
            <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
        </v-flex>
      </v-layout>
      <v-data-table
        v-else
        :headers="headers"
        :items="items"
        :items-per-page="5"
        >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable prettier/prettier */


import RequestService from "@/services/request.service.js";

export default {
  name: "DeliveryItemsTable",
  props: {
    deliveryId: {
      default: null
    }
  },
  components: {
    
  },
  data() {
    return {
      loading: true,
      headers: [],
      items: [],
      show: true
    };
  },
  mounted(){
    this.get_delivery_items()
  },
  watch: {
    value: {
    },
  },
  computed: {
    
  },
  methods: {
    get_delivery_items(){
      var that = this;
      return RequestService.request_get(
        {},
        "deliveries/" + this.deliveryId + "/get_delivery_items"
      ).then(
        (response) => {
          that.headers = response.data.headers;
          that.items = response.data.items;
          that.loading = false;
          if(that.items.length <= 0 )
            that.show = false;
        },
        (error) => {
          console.log(error);
          that.loading = false;
        }
      );
    }
  },
};
</script>
