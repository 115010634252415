<template>
  <div>
    <v-row>
      <v-col>
        <h3>Contatti destinatario</h3>
      </v-col>
      <v-col>
        <v-btn color="success" align-center @click="addContact()">
          <v-icon color="white"> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="contact in contacts" v-bind:key="contact.uuid">
      <v-text-field
        label="Contatto"
        type="string"
        v-model="contact.number"
        outlined
        dense
      ></v-text-field>
      <v-btn color="error" align-center @click="removeContact(contact)">
        <v-icon color="white"> mdi-delete </v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
//import RequestService from "@/services/request.service.js";

export default {
  name: "order-contacts",
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      contacts: JSON.parse(JSON.stringify(this.value || [])).map((v) => ({
        uuid: this.generateUUID(),
        number: v,
      })),
    };
  },
  watch: {
    contacts: {
      handler(val) {
        this.$emit(
          "input",
          val.map((v) => v.number)
        );
      },
      deep: true,
    },
  },

  methods: {
    addContact() {
      this.contacts.push({ uuid: this.generateUUID(), number: "" });
    },
    removeContact(toRemove) {
      this.contacts = this.contacts.filter((c) => c.uuid != toRemove.uuid);
    },
    generateUUID() {
      return Math.floor(Math.random() * 1000000);
    },
  },
};
</script>