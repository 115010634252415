import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import Pusher from 'pusher-js';
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = false;

var PUSHER_KEY = process.env.VUE_APP_APP_KEY;

var PUSHER_CLUSTER = process.env.VUE_APP_APP_CLUSTER;

Vue.prototype.$pusher = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  icons: {
    iconfont: "md"
  },
  render: h => h(App)
}).$mount("#app");
