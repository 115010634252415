import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "auth/login", {
        user: {
          username: user.username,
          password: user.password,
          kind: "sender"
        }
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("session", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  login_as_sender(senders_ids) {
    return axios
      .post(API_URL + "sign_in_as/login_as_sender", {
        sender_to_log_id: senders_ids.senderToLogId,
        current_sender_id: senders_ids.currentSenderId
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("session", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("session");
  }
}

export default new AuthService();
