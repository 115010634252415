<template>
  <v-dialog
    v-model="dialog"
    width="80%"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="toggleMedia(plan_delivery)"
        depressed
        small
        color="primary"
      >
        <v-icon>
          mdi-camera
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('common.show_media') }}</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="pl in imagesData[plan_delivery.id]"
              :key="pl.key"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card flat tile class="d-flex">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-btn
                        @click="downloadMedia(pl.src, pl.name)"
                        depressed
                        small
                        color="secondary"
                        style="margin-top: 2vh"
                      >
                        Scarica foto
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="pl.src"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        :contain="true"
                        :max-width="400"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col
              v-for="vl in videosData[plan_delivery.id]"
              :key="vl.key"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card flat tile class="d-flex">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-btn
                        @click="downloadMedia(vl.src, vl.name)"
                        depressed
                        small
                        color="secondary"
                        style="margin-top: 2vh"
                      >
                        Scarica video
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <video
                        style="
                          width: 100%;
                          max-width: 400px;
                          max-height: 400px;
                        "
                        :src="vl.src"
                        controls
                        class="video"
                      >
                        <source :src="vl.src" type="video/mp4" />
                        Errore
                      </video>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  
  
</template>

<script>
/* eslint-disable prettier/prettier */
import S3MediaService from "@/services/s3media.service.js";
import { saveAs } from "file-saver";


export default {
  name: "PdMediaDialog",
  props: {
    plan_delivery: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    
  },
  data() {
    return {
      dialog: false,
      imagesData: {},
      videosData: {},
    };
  },
  watch: {
    value: {
    },
  },
  computed: {
    
  },
  methods: {
    downloadMedia: function (link, key) {
      saveAs(link, key);
    },
    toggleMedia(plan_delivery) {
      var that = this;
      this.$set(this.imagesData, plan_delivery.id, []);
      this.$set(this.videosData, plan_delivery.id, []);
      
      S3MediaService.login(
        () => {
          plan_delivery.medias
            .filter((m) => m.kind != "signature")
            .filter((m) => m["photo?"])
            .forEach((m) => {
              S3MediaService.getMedia(m.bucket, m.name, (err, data) => {
                var blob = new Blob([data.Body.buffer]);
                var srcUrl = URL.createObjectURL(blob);
                that.imagesData[plan_delivery.id].push({
                  src: srcUrl,
                  name: m.name,
                  key: m.id,
                });
              });
            });

          plan_delivery.medias
            .filter((m) => m.kind != "signature")
            .filter((m) => m["video?"])
            .forEach((m) => {
              S3MediaService.getMedia(m.bucket, m.name, (err, data) => {
                var blob = new Blob([data.Body.buffer]);
                var srcUrl = URL.createObjectURL(blob);
                that.videosData[plan_delivery.id].push({
                  src: srcUrl,
                  name: m.name,
                  key: m.id,
                });
              });
            });
        },
        (err) => {
          console.log(err);
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.error_during_request_retry"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
  },
};
</script>
