<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="success" v-if="!inventory">
          {{ $t("helpers.submit.new.m.one", null, { model: "" }) }}
        </v-btn>
        <v-icon v-if="inventory" v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('activerecord.attributes.inventory.identifier')"
                  type="string"
                  v-model="inventory_attrs.identifier"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="!inventory">
                <v-menu
                  v-model="dateToMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="inventory_attrs.date"
                      label="Data"
                      single-line
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="inventory_attrs.date"
                    locale="it"
                    scrollable
                    @change="dateToMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn color="primary" text @click="createInventory()">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable prettier/prettier */
import RequestService from "@/services/request.service.js";

export default {
  name: "new-inventory",
  props: {
    inventory: {
      type: Object,
    },
  },

  data() {
    return {
      dialog: false,
      inventory_attrs: this.buildOrderAttributes(),
      dateToMenu: false,
      poll_intervall: 1000,
      myInterval: null,
    };
  },

  watch: {
    dialog(val) {
      if (val) {
        this.inventory_attrs = this.buildOrderAttributes();
      } else {
        this.inventory_attrs = this.buildOrderAttrs(this.emptyOrder());
        this.errors = {};
      }
    },
  },

  methods: {
    emptyOrder() {
      return {
        date: "",
        identifier: "",
        sender_id: this.$store.state.auth.session.user.id,
      };
    },
    buildOrderAttributes() {
      var inventory = this.inventory;
      if (this.inventory == null) inventory = this.emptyOrder();
      return this.buildOrderAttrs(inventory);
    },
    buildOrderAttrs(inventory) {
      return {
        id: inventory.id,
        identifier: inventory.identifier,
        date: inventory.date,
        company_id: this.$store.state.auth.session.company.id,
        sender_id: inventory.sender_id,
      };
    },
    createInventory() {
      var address = "";
      address = "inventories/" + this.inventory_attrs.id + "/update_from_open";

      if (!this.inventory) {
        address = "inventories/create_from_open";
      }
      var params = { inventory: this.inventory_attrs };
      RequestService.request_post(params, address)
        .then(
          (response) => {
            console.log(response);
            this.errors = {};
            this.dialog = false;
          },
          (error) => {
            console.log(error);
            this.errors = error.response.data;
          }
        )
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    title() {
      if (this.inventory) {
        return (
          this.$t("common.change") +
          " " +
          this.$t("activerecord.models.inventory.one") +
          " " +
          this.inventory.identifier
        );
      }
      return this.$t("helpers.submit.new.m.one", null, {
        model: this.$t("activerecord.models.inventory.one"),
      });
    },
  },
};
</script>
