<template>
  <v-dialog v-model="dialog" max-width="80vh">
    <template v-slot:activator="{ on, attrs }">
      <slot :name="'activator'" v-bind="{ on: on, attrs: attrs }">
        <v-btn class="ml-2 mr-2" color="primary" v-bind="attrs" v-on="on">
          <icon > mdi-alert </icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar flat color="#343a40">
        <h2><font color="#FFFFFF">{{title}}</font></h2>
        <v-spacer />
        <v-btn
          @click="rejected()"
          color="white"
          outlined
          dense
          hide-details
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <br />
      <v-card-text>
        <div v-for="textLine in text.split('\n')" v-bind:key="textLine">
          <h2 class="text-center">{{textLine}}</h2>
        </div>
      </v-card-text>
      <v-card-actions style="background-color: #343a40">
        <v-spacer />
        <v-btn color="white" outlined dense hide-details @click="confirm()">
          {{$t('common.confirm')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: "confirm-dialog",
  props: {
    title: { type: String, default: "Confermi l'opearazione?" },
    text: { type: String, default: "Attenzione, l'azione che sta per eseguire è irreversibile! \nSicuro di voler confermare?" }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirmed");
      this.dialog = false;
    },
    rejected() {
      this.$emit("rejected");
      this.dialog = false;
    }
  },
};
</script>
