<template>
  <v-row align="center">
    <v-col cols="7">
      <v-select
        :items="items"
        :item-text="'name'"
        :item-value="'id'"
        v-model="item_payment_attributes.item_id"
        label="Prodotto"
        hide-details
        outlined
        dense
        :readonly="invoiceConfirmed"
        v-show="item_payment_attributes.expense_id == undefined"
        :error-messages="entityMustBePresent"
        clearable
      >
        <template v-slot:item="{ item }">
          <v-chip label class="mt-1" v-text="item.name"></v-chip>
          <v-chip label class="mt-1" v-text="item.identifier"></v-chip>
          <v-chip label class="mt-1" v-text="itemTotalPrice(item)"></v-chip>
          <v-chip label class="mt-1" v-text="itemIvaValue(item)"></v-chip>
        </template>
        <template v-slot:selection="{ item }">
          <v-chip label class="mt-1" v-text="item.name"></v-chip>
          <v-chip label class="mt-1" v-text="item.identifier"></v-chip>
          <v-chip label class="mt-1" v-text="itemTotalPrice(item)"></v-chip>
          <v-chip label class="mt-1" v-text="itemIvaValue(item)"></v-chip>
        </template>
      </v-select>
      <v-select
        :items="expenses"
        :item-text="'description'"
        :item-value="'id'"
        v-model="item_payment_attributes.expense_id"
        label="Spesa"
        outlined
        hide-details="auto"
        dense
        :error-messages="entityMustBePresent"
        :readonly="invoiceConfirmed"
        v-show="item_payment_attributes.item_id == undefined"
        clearable
      >
        <template v-slot:item="{ item }">
          <v-chip label class="mt-1" v-text="item.description"></v-chip>
          <v-chip label class="mt-1" v-text="item.code"></v-chip>
          <v-chip label class="mt-1" v-text="itemTotalPrice(item)"></v-chip>
          <v-chip label class="mt-1" v-text="itemIvaValue(item)"></v-chip>
        </template>
        <template v-slot:selection="{ item }">
          <v-chip label class="mt-1" v-text="item.description"></v-chip>
          <v-chip label class="mt-1" v-text="item.code"></v-chip>
          <v-chip label class="mt-1" v-text="expenseTotalPrice(item)"></v-chip>
          <v-chip label class="mt-1" v-text="itemIvaValue(item)"></v-chip>
        </template>
      </v-select>
    </v-col>
    <v-col cols="2">
      <v-text-field
        label="Importo"
        type="string"
        v-model="item_payment_attributes.price"
        :rules="priceRules"
        required
        outlined
        dense
        :readonly="invoiceConfirmed"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-text-field
        label="Quantità"
        type="number"
        v-model="item_payment_attributes.quantity"
        :rules="quantityRules"
        required
        outlined
        dense
        :readonly="invoiceConfirmed"
      ></v-text-field>
    </v-col>

    <v-col cols="1">
      <v-btn
        color="red"
        align-center
        small
        @click="item_payment_attributes._destroy = true"
        :disabled="invoiceConfirmed"
      >
        <v-icon color="white"> mdi-delete </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable prettier/prettier */

export default {
  name: "item-payment-slot",
  props: {
    value: {
      type: Object,
    },
    items: {
      type: Array,
    },
    expenses: {
      type: Array,
    },
    invoiceConfirmed: Boolean,
  },

  data() {
    return {
      item_payment_attributes: JSON.parse(JSON.stringify(this.value)),
    };
  },
  methods: {
    itemTotalPrice(item) {
      return ["Prezzo totale:", Math.round(item.unitary_price * item.expected_stock * 100) / 100].join(
        " "
      );
    },
    itemIvaValue(item) {
      if (item.cod_iva) return "iva: " + item.cod_iva.substring(1) + "%";
      else return "iva: non inserita";
    },
    expenseTotalPrice(item) {
      return ["Prezzo totale:", Math.round(item.unitary_price * item.quantity * 100) / 100].join(" ");
    },
  },

  watch: {
    item_payment_attributes: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  computed: {
    item: function () {
      return this.items.find(
        (i) => i.id == this.item_payment_attributes.item_id
      );
    },
    expense: function () {
      return this.expenses.find(
        (i) => i.id == this.item_payment_attributes.expense_id
      );
    },
    quantityRules() {
      return [
        (v) =>
          parseFloat(v) >= 0 || "Campo quantità deve essere maggiore di zero",
        (v) =>
          !this.item ||
          parseFloat(v) <= this.item.expected_stock ||
          "Campo quantità deve essere minore del numero di item",
      ];
    },
    priceRules() {
      return [
        (v) =>
          !this.item ||
          parseFloat(v) <= this.item.unitary_price * this.item.expected_stock ||
          "Campo Importo deve essere minore del prezzo totale",
      ];
    },
    entityMustBePresent() {
      if (
        this.item_payment_attributes.item_id == undefined &&
        this.item_payment_attributes.expense_id == undefined
      ) {
        return ["Seleziona un item o una spesa"];
      }
      return [];
    },
  },
};
</script>