<style type="text/css">
html,
body {
  height: 100%;
  width: 100%;
}

.filter-margin {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 0px;
}
</style>

<template>
  <v-container fluid style="margin-top: 35px" v-if="inventory">
    <v-row class="filter-margin">
      <v-col :cols="4">
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.attributes.inventory.identifier')"
          v-model="inventory.identifier"
          hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col :cols="4">
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.attributes.inventory.date')"
          v-model="inventory.date"
          hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col :cols="4" align="center" align-self="center">
        <v-btn class="mr-2" color="primary" dark @click="downloadExcel()">
          {{ $t("common.print") }}
        </v-btn>
        <inventory-confirmation
          :inventory="inventory"
          @inventory-confirm="inventoryConfirmation"
        >
        </inventory-confirmation>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-tabs
      v-model="tab"
      centered
      slider-color="yellow"
    >
      <v-tab
        v-for="tabItem in tabItems"
        :key="tabItem"
      >
        {{ tabItem }}
      </v-tab>
      <v-tab-item>
        <v-row class="filter-margin">
          <inventory-filters
            @load-data="loadData($event)"
            @set-filter-options="setFilterOptions($event)"
            @set-filter-options-from-click-serch="setFilterOptionsFromClickSerch($event)"
            @add-snackbar-error="addMessageOnSnackbar($event)"
            :order-statuses="orderStatuses"
            :filter-options-prop="filterOptions"
          >
          </inventory-filters>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-pagination
            v-model="currentOrderPage"
            :length="orderPages"
            :total-visible="8"
          >
          </v-pagination>
          <v-spacer></v-spacer>
        </v-row>  
        <v-row>
          <v-col>
            <v-container>
              <v-expansion-panels>
                <inventory-row
                  v-for="(order, index) in orders"
                  v-bind:key="'order_' + order.id"
                  :order="orders[index]"
                  :ind="index"
                  :disabletodel="false /*!(serviceTime && serviceDate) */"
                  :mov="expectedItems"
                  :orderStatus="orderStatuses"
                  :inventory="inventory"
                  :includeItemNotWaited="includeItemNotWaited"
                  @saved="snackbar = true"
                  @failed="failsnackbar = true"
                  @update-order-status="updateOrderStatus($event)"
                  @movement-created="loadData()"
                >
                </inventory-row>
              </v-expansion-panels>
              <v-spacer></v-spacer>
            </v-container>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="filter-margin">
          <v-col :cols="4" align="center" align-self="center">
            <v-text-field
              v-model="barcodeField"
              @keyup.enter="addMovement"
              outlined
              dense
              label="Barcode"
              hide-details="true"
            />
          </v-col>
          <v-col :cols="4" align="center" align-self="center">
            <v-btn class="mr-2" color="primary" dark @click="addMovement">
              {{ $t("search", null, { model: "" }) }}
            </v-btn>
          </v-col>
          <v-col :cols="4" align="center" align-self="center">
            <v-btn class="mr-2" color="error" dark @click="clearManualMovementTable">
              {{ $t("common.clean") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="filter-margin">
            <manual-movement
              v-model="tableManualMovementDetails"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <new-movement-from-order
      v-if="!!orderNewMovements"
      :open-dialog-new-movement="openDialogNewMovement"
      :inventory="inventory"
      :value="orderNewMovements"
      @close="openDialogNewMovement = false"
      @refresh-orders="refreshOrdersList($event)"
    >
    </new-movement-from-order>
    <v-dialog v-model="confirmationInProgress" max-width="50%">
      <v-card>
        <v-layout align-center justify-center column fill-height>
          <v-card-title> {{ $t("common.infentory_confirmation_in_progress") }} </v-card-title>
          <v-card-subtitle>{{ $t("common.do_not_reload") }}</v-card-subtitle>
          <v-flex row align-center>
              <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>
          </v-flex>
          <br/>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      left
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import RequestService from "@/services/request.service.js";
import InventoryRow from "./inventoryRow.vue";
import InventoryConfirmation from "./inventoryConfirmation.vue";
import InventoryFilters from "./InventoryFilters.vue";
import NewMovementFromOrder from "./NewMovementFromOrder.vue";
import ManualMovement from "./ManualMovement.vue";
import InventoryExport from "../../services/inventory_export.service";

export default {
  name: "inventory-show",
  data() {
    return {
      tab: null,
      tabItems: [
        this.$t('activerecord.models.order.other'), 
        this.$t('common.barcode.one')
      ],
      inventory: null,
      filterSelected: null,
      countOrders: 0,
      elementRequire: 0,
      currentOrderPage: 1,
      perOrderPage: 10,
      filterOptions: {
        filterSelected: null,
        states: "",
        elementRequire: 0,
        includeItemNotWaited: false,
        identifier: "",
      },
      find: "",
      states: "",
      orders: [],
      orderStatuses: [],
      expectedMovements: [],
      expectedItems: [],
      includeItemNotWaited: false,
      barcodeField: null,
      snackbar: {
        color: "success",
        text: "",
        value: false,
        timeout: 5000,
      },
      openDialogNewMovement: false,
      orderNewMovements: null,
      barcodesSet: new Set(),
      manualMovements: {},
      tableManualMovementDetails: [],
      channelName: "",
      confirmationInProgress: false,
    };
  },

  components: {
    InventoryRow,
    InventoryConfirmation,
    InventoryFilters,
    NewMovementFromOrder,
    ManualMovement,
  },

  mounted() {
    this.loadData();
    this.intOrderStatuses();
  },

  beforeDestroy() {
    this.$pusher.unsubscribe(this.channelName);
  },

  watch: {
    channelName(val) {
      var that = this;
      if (val != "") {
        var channel = this.$pusher.subscribe(val);
        channel.bind("inventory-messages", function (data) {
          if (data.message) {
            alert(data.message);
          }
          if (data.completed) {
            that.loadData();
          }
        });
      }
    },
    currentOrderPage() {
      this.loadData();
    },
  },


  methods: {
    clearManualMovementTable(){
      this.manualMovements = {};
      this.tableManualMovementDetails = [];
    },
    setTableDetails(){
      this.tableManualMovementDetails = Object.entries(this.manualMovements).map((manualMovement) => ( manualMovement[1] ))
    },
    updateManualMovement(order, item) {
      if (this.manualMovements[item.id]) {
        this.manualMovements[item.id].qtaMoved += 1;
      } else {
        this.$set(this.manualMovements, item.id, {
          orderIdentifier: order.identifier,
          stock: item.stock,
          expectedStock: item.expected_stock,
          unitaryPieces: item.unitary_pieces,
          identifier: item.identifier,
          qtaMoved: item.movements
            .filter((m) => m.inventory_id == this.inventory.id)
            .map((m) => parseFloat(m.quantity))
            .reduce((a, c) => a + c, 0.0),
          name: item.name,
          idx: Object.keys(this.manualMovements).length,
          orderId: order.id,
          orderLine: item.order_line,
          serial_number:  item.serial_number
        });
      }
      if (Object.keys(this.manualMovements).length > 0)
        this.setTableDetails()
      this.barcodeField = "";
    },
    addMovement() {
      if (this.addOrderBlocked()){
        return
      }
      const params = {
        barcode: this.barcodeField,
        movement: {
          quantity: 1,
          item_id: null,
          kind: "in",
          warehouse_area_id:
            this.$store.state.auth.session.user.warehouse_area_id,
          place_id: null,
          suggested_place_id: null,
          damaged: false,
          inventory_id: this.inventory.id,
        },
      };
      RequestService.request_post(
        params,
        "inventories/add_movement_from_barcode"
      )
        .then((response) => {
          console.log(response);
          this.updateManualMovement(response.data.order, response.data.item);
          if (this.barcodesSet.has(response.data.item.identifier)) {
            this.loadData();
          }
        })
        .catch((error) => {
          var snackbar = {
            color: "error",
            text: error.response.data.message,
          };
          this.addMessageOnSnackbar(snackbar);
        });
    },
    intOrderStatuses: function () {
      var that = this;
      RequestService.request_get({}, "inventories/get_statuses")
        .then((response) => {
          that.orderStatuses = response.data.statuses;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearData() {
      this.orders = [];
      this.expectedMovements = [];
      this.expectedItems = [];
      this.inventory = null;
    },
    getInventoryStatus(order) {
      if (order["itemsChecked"] == 0) return "not_scanned";
      return order["items"].length != order["itemsChecked"]
        ? "scan_partial"
        : "scan_completed";
    },
    findOrder(identifier) {
      if (this.addOrderBlocked()){
        return
      }
      RequestService.request_post(
        { identifier: identifier, id: this.inventory.id },
        "inventories/search_order"
      )
        .then((response) => {
          console.log(response);
          this.orderNewMovements = response.data.order;
          this.openDialogNewMovement = true;
        })
        .catch((error) => {
          console.log(error);
          var snackbar = {
            color: "error",
            text:
              this.$t("activerecord.errors.messages.not_found", null, {
                model: this.$t("activerecord.models.order.one"),
              }) + identifier,
          };
          this.addMessageOnSnackbar(snackbar);
        });
    },
    refreshOrdersList(identifier) {
      this.filterOptions.elementRequire = 1;
      this.filterOptions.identifier = identifier;
      this.filterOptions.includeItemNotWaited = true;
      this.orderNewMovements = null;
      this.loadData();
    },
    setFilterOptions(value) {
      this.filterOptions = value;
      this.loadData();
    },
    setFilterOptionsFromClickSerch(value) {
      this.filterOptions = value;
      this.loadData(true);
    },
    loadData(fromClickSerch) {
      var that = this;

      var params = {
        filter: that.filterSelected,
        per_page: that.perOrderPage,
        page: that.currentOrderPage,
        kind: that.elementRequire,
        search: that.find,
        states: that.states,
      };

      if (this.filterOptions) {
        params.filter = this.filterOptions.filterSelected;
        params.states = this.filterOptions.states;
        params.kind = this.filterOptions.elementRequire;
        params.search = this.filterOptions.identifier;
        params.includeItemNotWaited = this.filterOptions.includeItemNotWaited;
        this.includeItemNotWaited = this.filterOptions.includeItemNotWaited;
      }

      RequestService.request_get(
        params,
        "inventories/" + this.$route.query.id,
        {}
      ).then(
        (response) => {
          that.clearData();
          that.inventory = response.data.inventory;
          this.channelName =
            this.inventory.company_id + "-inventory-" + this.inventory.id;
          if (
            this.filterOptions.identifier &&
            response.data.orders.length == 0 &&
            !response.data.identifier_present &&
            fromClickSerch
          ) {
            var text = that.$t("common.search_order_in_logistics");
            if (confirm(text)) {
              this.findOrder(this.filterOptions.identifier);
            }
          }
          if (this.filterOptions.identifier &&
              response.data.orders.length == 0 &&
              response.data.identifier_present)
          {
            var snackbar = {
              color: "success",
              text:
                "Prova a cercare l'ordine "+ this.filterOptions.identifier + " tra gli ordini extra",
            };
            this.addMessageOnSnackbar(snackbar);
          }
          that.orders = response.data.orders;
          that.orders.forEach((o) =>
            o.items.forEach((i) => that.barcodesSet.add(i.identifier))
          );
          that.expectedItems = response.data.expected_items;
          var delivery_details = Object.values(
            response.data.delivery_details
          ).reduce((unique, vt) => ({ ...unique, [vt.order_id]: vt }), {});
          that.expectedItems.map((i) => {
            i["expected_movements"] = response.data.expected_movements.filter(
              (e) => e.item_id == i.id
            );
            i["movements"] = response.data.movements.filter(
              (e) => e.item_id == i.id
            );
            i["checkedQuantity"] = i["movements"].reduce(
              (acc, cur) => acc + cur.quantity,
              0
            );
            i["notPresentinInventory"] = !(i["expected_movements"].length > 0);
          });

          that.orders.map((o) => {
            var items = that.expectedItems.filter((i) => i.order_id == o.id);
            if (that.includeItemNotWaited == false) o["items"] = items;
            else {
              o["items"] = o["items"].filter(
                (value) => !items.some((i) => i.id == value.id)
              );
              o["items"].map((i) => {
                i["expected_movements"] = {};
                i["movements"] = [];
                i["notPresentinInventory"] = true;
              });
              o["items"] = [...items, ...o["items"]];
            }

            o["itemsExpected"] = o["items"].length;
            if (that.includeItemNotWaited)
              o["itemsChecked"] = o["items"].filter((i) =>
                i.checkedQuantity
                  ? i.checkedQuantity == i.expected_stock * i.unitary_pieces
                  : false
              ).length;
            else
              o["itemsChecked"] = o["items"].filter((i) =>
                i.checkedQuantity ? i.checkedQuantity == i.stock : false
              ).length;
            o["inventoryStatus"] = that.getInventoryStatus(o);
            o["deliveryDetails"] = delivery_details[o["id"]];
          });

          that.countOrders = response.data.orders_count;
          that.orderPages = Math.ceil(that.countOrders / that.perOrderPage);
          this.updateConfirmationInProgress(this.inventory.confirmation_in_progress)
          if (this.inventory.confirmation_in_progress) {
            this.startPolling(2);
          }
        },
        (error) => {
          this.$emit("errorServer", error);
          alert("Errore nel recupero dell'inventario.");
          console.log(error);
        }
      );
    },
    downloadExcel() {
      var url = "inventories/" + this.inventory.id + "/download_xls";
      var that = this;
      RequestService.request_get({}, url)
        .then((response) => {
          InventoryExport.realExport(
            response.data.data,
            that.inventory.identifier
          );
        })
        .catch((error) => {
          console.log(error);
          var snackbar = {
            color: "error",
            text: this.$t("common.impossible_to_download_inventory_excel"),
          };
          this.addMessageOnSnackbar(snackbar);
        });
    },
    updateOrderStatus(ord){
      var order = this.orders.find(element => element.id == ord.id)
      order.status = ord.status
      order.status_label = ord.status_label
    },
    addMessageOnSnackbar(snackbar) {
      this.snackbar = {
        color: snackbar.color,
        text: snackbar.text,
        timeout: 3000,
        opened: true,
      };
    },
    inventoryConfirmation() {
      var url = "inventories/" + this.inventory.id + "/completed";
      RequestService.request_post({}, url)
        .then((response) => {
          this.updateConfirmationInProgress(true)
          this.startPolling(2);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          var snackbar = {
            color: "error",
            text: this.$t("common.impossible_confirm_inventory"),
          };
          this.addMessageOnSnackbar(snackbar);
        });
    },
    updateConfirmationInProgress(val){
      this.confirmationInProgress = val;
      this.inventory.confirmation_in_progress = val;
    },
    startPolling(attempt){
      if (attempt > 30 ){
        var snackbar = {
          color: "error",
          text: this.$t("common.impossible_confirm_inventory"),
        };
        this.addMessageOnSnackbar(snackbar);
        return;
      }
      var pollInterval = 4;
      if (attempt < 4 )
        pollInterval = attempt;
      setTimeout(() => {
        this.pollingConfirmationProgress(attempt)
      }, pollInterval * 1000);
    },
    pollingConfirmationProgress(attempt){
      var url = "inventories/" + this.inventory.id + "/polling_confirmation_progress";
      RequestService.request_get({}, url)
        .then((response) => {
          if (response.data.in_progress) {
            this.updateConfirmationInProgress(true)
            this.startPolling( attempt + 1 )
          } else {
            this.updateConfirmationInProgress(false)
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    addOrderBlocked(){
      var check = (this.inventory.completed || this.inventory.confirmationInProgress)
      if (check){
        var snackbar = {
            color: "error",
            text: this.$t("common.block_add_order_on_completed_inventory"),
          };
          this.addMessageOnSnackbar(snackbar);
      }
      return check;
    },
  },
};
</script>
