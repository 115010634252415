import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Orders from "../views/Orders.vue";
import Inventories from "../views/inventories/Inventories.vue";
import InventoryShow from "../views/inventories/InventoryShow"
import Login from "../views/Login.vue";
import Plan from "../views/Plan.vue";
import NewService from "../views/NewService.vue";
import Report from "../views/Report.vue";
import DeliveryImport from "../views/DeliveryImport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/home",
    component: Home
  },
  {
    path: "/orders",
    component: Orders
  },
  {
    path: "/inventories",
    component: Inventories
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/inventory-show",
    component: InventoryShow,
  },
  {
    path: "/new-service",
    component: NewService
  },
  {
    path: "/plan",
    component: Plan
  },
  {
    path: "/report",
    component: Report
  },
  {
    path: "/delivery-import",
    component: DeliveryImport
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("session");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
