<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>

<template>
  <v-card max-height="85vh">
    <v-toolbar color="grey darken-3" dark
      ><h2>{{ $t("activerecord.attributes.delivery.delivery_note") }}</h2>
      <v-spacer></v-spacer>
      <v-icon outlined @click="$emit('updateMessages')" large>
        mdi-update
      </v-icon>
    </v-toolbar>
    <v-card-text ref="messageList">
      <x-message
        v-for="message in deliveryNotes"
        v-bind:key="message.id"
        :isSelf="isSelf(message.writer_name)"
        :sender="message.writer_name"
        :createdAt="formatDate(message.created_at)"
        :body="message.description"
        @openMail="
          $emit('openMail', {
            message: message.description,
            id: deliveryId,
            controller: 'deliveries',
          })
        "
        :order-flag="!!message.order_id"
      >
      </x-message>
    </v-card-text>
    <v-footer class="justify-end">
      <v-textarea
        clearable
        filled
        auto-grow
        rows="1"
        :append-outer-icon="'mdi-send'"
        @click:append-outer="sendMessage"
        clear-icon="mdi-close-circle"
        v-model="message"
        hide-details="true"
        @keyup.native.enter="sendMessage"
      >
      </v-textarea>
    </v-footer>
  </v-card>
</template>

<script>
import XMessage from "../views/XMessage";

export default {
  props: {
    deliveryNotes: {
      type: Array,
    },
    writerName: {
      type: String,
    },
    deliveryId: {
      type: Number,
    },
  },
  data() {
    return {
      message: "",
    };
  },
  watch: {
    deliveryNotes() {
      this.scrollToEnd();
    },
  },
  methods: {
    sendMessage() {
      if (this.message.length == 0) return;

      this.$emit("newMessage", this.message);
      this.message = "";
    },
    formatDate(date) {
      var [year, month, day] = date.substring(0, 10).split("-");
      return [day, month, year].join("/") + " " + date.substring(11, 19);
    },
    isSelf(writer) {
      return this.writerName == writer;
    },
    scrollToEnd() {
      var container = this.$refs.messageList;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
  },
  mounted() {
    this.scrollToEnd();
  },
  components: { XMessage },
};
</script>
