<template>
  <v-card>
    <v-form ref="form">
      <v-row>
        <v-col :cols="10" :offset="1" align="center">
          <v-select
            :items="items"
            :item-text="'name'"
            :item-value="'id'"
            v-model="itemsToPay"
            hide-details="auto"
            label="Prodotti"
            outlined
            multiple
            dense
          >
            <template v-slot:selection="{ item }">
              <v-chip label class="ma-1" v-text="item.name"></v-chip>
            </template>
          </v-select>
          <v-select
            :items="expenses"
            :item-text="'description'"
            :item-value="'id'"
            v-model="expensesSelectToPay"
            label="Spese"
            outlined
            multiple
            dense
          >
            <template v-slot:selection="{ item }">
              <v-chip label class="ma-1" v-text="item.code"></v-chip>
              <v-chip label class="ma-1" v-text="item.description"></v-chip>
            </template>
          </v-select>
          <v-btn color="primary" dense @click="selectAll">
            Seleziona tutti
          </v-btn>
          <v-text-field
            class="mt-5"
            label="Totale da pagare"
            type="string"
            :value="totalPrice"
            outlined
            dense
            readonly
            hide-details
          ></v-text-field>
          <v-row class="pa-0 mt-3">
            <v-col>
              <v-text-field
                label="Euro"
                type="number"
                v-model="euro"
                :rules="euroRules"
                :disabled="isBalance()"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Percentuale %"
                type="number"
                v-model="fixedPercentage"
                :rules="percentageRules"
                :disabled="isBalance()"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 mt-3 mb-0">
            <v-col :cols="10" :offset="1" align="center" class="pa-0">
              <v-btn-toggle v-model="paymentType" mandatory>
                <v-btn :value="'Deposit'">Acconto</v-btn>
                <v-btn :value="'Balance'" @click="setEuro">Saldo</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="pa-0 mt-3 mb-0">
            <v-col :cols="10" :offset="1" align="center" class="pa-0">
              <v-select
                v-model="paymentMethod"
                :items="paymentMethods"
                :rules="paymentMethodRules"
                outlined
                dense
                hide-details="auto"
                item-text="description"
                item-value="id"
                label="Metodo di pagamento"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 mt-3">
            <v-col :cols="10" :offset="1" align="center" class="pa-0">
              <v-select
                v-model="documentKind"
                :items="documentKinds"
                :rules="documentKindRules"
                outlined
                dense
                hide-details="auto"
                item-text="text"
                item-value="value"
                label="Tipo di documento"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                class="ma-2"
                color="success"
                white--text
                depressed
                :disabled="totalPrice <= 0 || euro <= 0 || fixedPercentage <= 0"
                @click="addPayment"
              >
                Aggiungi pagamento
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
//import RequestService from "@/services/request.service.js";

export default {
  name: "add-payment",
  props: {
    items: {
      type: Array,
    },
    expenses: {
      type: Array,
    },
    toPay: {
      type: Object,
    },
    expensesToPay: {
      type: Object,
    },
    paymentMethods: {
      type: Array,
    },
    documentKinds: {
      type: Array,
    },
  },
  data() {
    return {
      allItems: JSON.parse(JSON.stringify(this.items)),
      itemPaymentAttrs: [],
      itemsToPay: [],
      expensesSelectToPay: [],
      paymentType: "Deposit",
      paymentMethod: null,
      documentKind: null,
      euro: null,

      percentageRules: [
        (v) =>
          parseFloat(v) >= 0 ||
          "Campo Percentuale deve essere maggiore di zero",
        (v) =>
          parseFloat(v) <= 100 ||
          "Campo Percentuale deve essere minore di cento",
      ],
      paymentMethodRules: [
        (v) =>
          v != null || "Deve essere selezionato almeno un metodo di pagamento",
      ],
      documentKindRules: [
        (v) =>
          v != null || "Deve essere selezionato almeno un tipo di pagamento",
      ],
    };
  },

  watch: {
    items: {
      handler(val) {
        this.allItems = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
  },

  computed: {
    fixedPercentage: {
      get() {
        return this.euro == null
          ? null
          : parseFloat(
              this.round2Decimals((this.euro * 100) / this.totalPrice)
            );
      },
      set(val) {
        this.percentage = val == null ? null : val;
      },
    },
    totalPrice: function () {
      var total = this.allItems
        .filter((item) => this.itemsToPay.includes(item.id))
        .map((x) => parseFloat(this.toPay[x.id]))
        .filter((x) => !isNaN(x))
        .reduce((acc, cur) => acc + cur, 0);

      total += this.expenses
        .filter((item) => this.expensesSelectToPay.includes(item.id))
        .map((x) => parseFloat(this.expensesToPay[x.id]))
        .filter((x) => !isNaN(x))
        .reduce((acc, cur) => acc + cur, 0);

      return parseFloat(this.round2Decimals(total));
    },
    euroRules() {
      return [
        (v) => parseFloat(v) >= 0 || "Campo Euro deve essere maggiore di zero",
        (v) =>
          parseFloat(v) <= this.totalPrice ||
          "Campo Euro deve essere minore di totale da pagare",
      ];
    },
    percentage: {
      get() {
        return this.euro == null
          ? null
          : parseFloat((this.euro * 100) / this.totalPrice);
      },
      set(val) {
        this.euro =
          val == null
            ? null
            : parseFloat(this.round2Decimals((this.totalPrice * val) / 100));
      },
    },
  },

  methods: {
    isBalance() {
      return this.paymentType == "Balance";
    },
    setEuro() {
      this.euro = this.totalPrice;
    },
    selectAll() {
      this.itemsToPay = this.allItems.map((i) => i.id);
      this.expensesSelectToPay = this.expenses.map((i) => i.id);
    },
    round2Decimals(val) {
      return Math.round((val + Number.EPSILON) * 100) / 100;
    },
    getPriceToPayDepositPayment(item, kind) {
      var toPay = item.to_pay;
      var toPayObject = kind === "item" ? this.toPay : this.expensesToPay;
      toPay = parseFloat(
        this.round2Decimals((toPayObject[item.id] * this.percentage) / 100)
      );
      return toPay;
    },
    generateItemsPayment() {
      var that = this;
      var totToPay = 0;
      this.itemsToPay.forEach((itemId) => {
        var toPay = 0;
        var item = that.allItems.find((i) => i.id == itemId);
        if (that.paymentType == "Deposit") {
          toPay = that.getPriceToPayDepositPayment(item, "item");
        } else {
          toPay = that.toPay[item.id];
        }
        var itemPayment = {
          item_id: itemId,
          price: toPay,
          quantity: item.expected_stock,
          company_id: that.$store.state.auth.session.company.id,
        };
        that.itemPaymentAttrs.push(itemPayment);
        totToPay += itemPayment.price;
      });
      this.expensesSelectToPay.forEach((expenseId) => {
        var toPay = 0;
        var expense = that.expenses.find((i) => i.id == expenseId);
        if (that.paymentType == "Deposit") {
          toPay = that.getPriceToPayDepositPayment(expense, "expense");
        } else {
          toPay = that.expensesToPay[expense.id];
        }
        var itemPayment = {
          expense_id: expenseId,
          price: toPay,
          quantity: 1,
          company_id: that.$store.state.auth.session.company.id,
        };
        that.itemPaymentAttrs.push(itemPayment);
        totToPay += itemPayment.price;
      });
      if (totToPay != this.euro) {
        var diff = this.round2Decimals(totToPay - this.euro);
        this.itemPaymentAttrs[this.itemPaymentAttrs.length - 1].price -= diff;
      }

      return this.itemPaymentAttrs;
    },
    clearAddPayment() {
      this.allItems = JSON.parse(JSON.stringify(this.items));
      this.itemPaymentAttrs = [];
      this.itemsToPay = [];
      this.expensesSelectToPay = [];
      this.paymentType = "Deposit";
      this.euro = null;
      this.paymentMethod = null;
      this.documentKind = null;
      this.$refs.form.reset();
    },
    addPayment() {
      if (!this.$refs.form.validate()) {
        return;
      }
      var item_payments = this.generateItemsPayment();
      var price = this.round2Decimals(
        item_payments
          .map((x) => parseFloat(x.price))
          .filter((x) => !isNaN(x))
          .reduce((acc, cur) => acc + cur, 0)
      );
      var paymentKind = 0; //deposit in ermes
      if (this.paymentType == "Balance") {
        paymentKind = 1; //balance in ermes
      }
      var payment_attributes = {
        price: price,
        item_payments_attributes: item_payments,
        payment_kind: paymentKind,
        payment_method_id: this.paymentMethod,
        document_kind: this.documentKind,
        company_id: this.$store.state.auth.session.company.id,
        _destroy: false,
        uuid: this.generateUUID(),
        description: "",
      };
      this.clearAddPayment();
      this.$emit("paymentAdded", payment_attributes);
    },
    generateUUID() {
      return Math.floor(Math.random() * 1000000);
    },
  },
};
</script>