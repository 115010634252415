<style type="text/css">
.col-no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.row-margin {
  margin-top: 1vh;
  margin-bottom: 1vh;
}
</style>

<template>
  <v-row justify="start" style="margin-left: 0px">
    <!--overlay di caricamento-->
    <v-overlay
      :value="loadingResults || saveAlert || errorAlerts"
      opacity="0.3"
    >
      <template v-for="(error, idx) in errorsArray">
        <v-alert type="error" elevation="8" v-bind:key="error">
          {{ errorMessages[error] }}
          <v-icon right @click="errorsArray.splice(idx, 1)">mdi-close</v-icon>
        </v-alert>
      </template>
    </v-overlay>
    <!--card reale-->
    <v-container fluid style="max-width: 80vw">
      <v-row>
        <v-col md="12">
          <h2>Impostazioni di report</h2>
          <br />
          <v-row>
            <v-col :cols="12" :md="10">
              <v-expansion-panels v-model="filter">
                <v-expansion-panel>
                  <v-expansion-panel-header>Filtri</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <v-menu
                          v-model="dateMenuSince"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          max-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              v-model="serviceDateSince"
                              label="Da *"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="validateIsoDate"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="serviceDateSince"
                            locale="it"
                            scrollable
                            @change="dateMenuSince = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-menu
                          v-model="dateMenuTill"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          max-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              v-model="serviceDateTill"
                              label="A *"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="validateIsoDate"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="serviceDateTill"
                            locale="it"
                            scrollable
                            @change="dateMenuTill = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          v-model="address"
                          label="Indirizzo"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          dense
                          v-model="paymentTypeFilter"
                          :items="paymentTypes"
                          outlined
                          multiple
                          label="Tipologia di Pagamento"
                        >
                        </v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          dense
                          v-model="urgencyFilter"
                          :items="urgencies"
                          outlined
                          label="Urgenza"
                          multiple
                        >
                        </v-select>
                      </v-col>
                      <v-col>
                        <v-select
                          dense
                          v-model="selectedSkills"
                          :items="skills"
                          item-text="name"
                          item-value="id"
                          outlined
                          label="Mezzo"
                          multiple
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          dense
                          v-model="retrieveVouchers"
                          label="Tagliandi da restituire"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          dense
                          v-model="monthlyBillFilter"
                          label="Fatturazione a fine mese"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="monthlyBillFilter">
                        <v-autocomplete
                          dense
                          v-model="selectedItems"
                          :loading="loadingItems"
                          :items="items"
                          :search-input.sync="searchItems"
                          item-text="name"
                          item-value="id"
                          outlined
                          hide-no-data
                          hide-details="auto"
                          label="Voce di fatturazione"
                          multiple
                          chips
                          small-chips
                          cache-items
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col align="center">
                        <v-range-slider
                          dense
                          v-model="shipment_range"
                          :max="max"
                          :min="min"
                          hide-details
                          class="align-center"
                          outlined
                          label="Tagliandi"
                        >
                          <template v-slot:prepend>
                            <v-text-field
                              dense
                              :value="shipment_range[0]"
                              class="mt-0 pt-0"
                              hide-details
                              outlined
                              single-line
                              type="number"
                              style="width: 100px"
                              @change="$set(shipment_range, 0, $event)"
                            ></v-text-field>
                          </template>
                          <template v-slot:append>
                            <v-text-field
                              dense
                              :value="shipment_range[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              outlined
                              type="number"
                              style="width: 100px"
                              @change="$set(shipment_range, 1, $event)"
                            ></v-text-field>
                          </template>
                        </v-range-slider>
                        <v-range-slider
                          dense
                          v-model="delivery_range"
                          :max="max"
                          :min="min"
                          hide-details
                          class="align-center"
                          outlined
                          label="Tagliandi per consegna"
                        >
                          <template v-slot:prepend>
                            <v-text-field
                              dense
                              :value="delivery_range[0]"
                              class="mt-0 pt-0"
                              hide-details
                              outlined
                              single-line
                              type="number"
                              style="width: 100px"
                              @change="$set(delivery_range, 0, $event)"
                            ></v-text-field>
                          </template>
                          <template v-slot:append>
                            <v-text-field
                              dense
                              :value="delivery_range[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              outlined
                              type="number"
                              style="width: 100px"
                              @change="$set(delivery_range, 1, $event)"
                            ></v-text-field>
                          </template>
                        </v-range-slider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col :cols="12" :md="2" align="center">
                        <v-btn
                          dark
                          :disabled="disabledSearch"
                          color="success"
                          @click="createNewReport(true)"
                        >
                          cerca
                        </v-btn>
                      </v-col>
                      <v-col :cols="12" :md="2" align="center">
                        <v-btn color="error" @click="clearFilterFields">
                          Pulisci
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col :cols="12" :md="2" align="center">
              <v-btn
                style="margin-top: 0.8vh"
                dark
                large
                color="primary"
                @click="exportFenixMonthlyBilling"
              >
                esporta
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Totale Servizi"
                readonly
                v-model="itemsCount"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Totale Tagliandi"
                readonly
                v-model="itemsTotalPrice"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <template>
            <v-data-table
              :headers="headers"
              :items="data"
              :options.sync="options"
              :server-items-length="itemsCount"
              class="elevation-1"
            >
              <!--
                  <template #item.pickup_addresses="{ item }">
                    <span v-html="(item.pickup_addresses || '').split(' - ').join('<br/>')"></span>
                  </template>
                  <template #item.delivery_addresses="{ item }">
                    <span v-html="(item.delivery_addresses || '').split(' - ').join('<br/>')"></span>
                  </template>
                  <template #item.skill_names="{ item }">
                    <span v-html="(item.skill_names || '').split(' - ').join('<br/>')"></span>
                  </template>
                  -->
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>


<script>
/* eslint-disable prettier/prettier */

import RequestService from "@/services/request.service.js";

const ExcelJS = require("exceljs");

export default {
  name: "report-vue",

  data: function () {
    var d = new Date();

    return {
      headers: [
        {
          text: "Spedizione",
          align: "start",
          value: "shipment",
        },
        {
          text: "Data",
          value: "date",
        },
        {
          text: "Voce di Fatturazione",
          value: "item",
        },
        {
          text: "Prezzo",
          value: "price",
        },
        {
          text: "Tipologia di Pagamento",
          value: "payment_type",
        },
        {
          text: "Indirizzi di ritiro",
          value: "pickup_addresses",
        },
        {
          text: "Indirizzi di consegna",
          value: "delivery_addresses",
        },
        {
          text: "Veicolo",
          value: "skill_names",
        },
      ],
      paymentTypes: [
        {
          text: "Euro",
          value: "euro",
        },
        {
          text: "Tagliandi",
          value: "voucher",
        },
        {
          text: "Online",
          value: "online",
        },
      ],
      urgencies: [
        {
          text: "Normale",
          value: "normal",
        },
        {
          text: "Urgente",
          value: "urgent",
        },
        {
          text: "Urgentissimo",
          value: "very_urgent",
        },
        {
          text: "Diretto",
          value: "direct",
        },
      ],
      disabledSearch: false,

      paymentTypeFilter: [],
      urgencyFilter: [],

      data: [],
      options: {},
      itemsCount: 0,
      itemsTotalPrice: 0,

      filter: false,

      loadingResults: false,
      saveAlert: false,

      dateMenuSince: false,
      dateMenuTill: false,

      shipment_range: [0, 100],
      delivery_range: [0, 100],
      min: 0,
      max: 100,

      address: "",

      skills: this.$store.state.auth.session.skills,
      selectedSkills: [],

      items: [],
      selectedItems: [],
      searchItems: null,
      loadingItems: false,

      retrieveVouchers: false,
      monthlyBillFilter: false,

      sender: this.$store.state.auth.session.user.id,

      maxDaysRange: 31,

      serviceDateSince: d.toISOString().substr(0, 10),
      serviceDateTill: d.toISOString().substr(0, 10),

      validateIsoDate: [
        (value) =>
          /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/.test(
            value
          ) || "Data non valida, utilizzare formato yyyy-MM-dd",
      ],
      validateIsoTime: [
        (value) =>
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) ||
          value === "" ||
          !value ||
          "Utilizzare formato hh:mm",
      ],

      errorsArray: [],
      errorMessages: {
        toowideperiod:
          "Attenzione! Il massimo periodo di ricerca è di 31 giorni",
        toofewfilter:
          "È necessario aggiungere Fatturazione a fine mese o Tagliandi da recuperare per cercare consegne su più di due giorni",
        noPickups:
          "Errore! Per creare un servizio è necessario specificare almeno un ritiro",
        noDeliveries:
          "Errore! Per creare un servizio è necessario specificare almeno una consegna",
        missingFields:
          "Errore! Per creare un servizio è necessario compilare tutti i campi obbligatori",
        existingIdentifier: "Errore! L'identificativo inserito è già esistente",
        insufficientVouchers:
          "Errore! Il cliente non dispone di abbastanza tagliandi",
        general: "Errore durante il salvataggio del servizio",
      },
    };
  },

  computed: {
    errorAlerts() {
      return this.errorsArray.length > 0;
    },
  },

  watch: {
    options: function () {
      this.createNewReport(false);
    },
    searchItems: function (val) {
      if (!val || val.length < 3) {
        this.items = [];
        return;
      }
      this.fetchItems(val);
    },
    monthlyBillFilter: function (val) {
      if (!val) {
        this.selectedItems = [];
      }
    },
  },

  methods: {
    removeItem(item) {
      const index = this.selectedItems.indexOf(item);
      if (index >= 0) this.item.splice(index, 1);
    },
    fetchItems: function (val) {
      this.loadingItems = true;
      var that = this;
      RequestService.request_get(
        {
          query: val,
          limit: 5,
        },
        "items/search"
      )
        .then((response) => {
          that.items = [];
          that.loadingItems = false;
          that.items = response.data.items.filter(
            (i) =>
              (i.name || "").toLowerCase().indexOf((val || "").toLowerCase()) >
              -1
          );
        })
        .catch((error) => {
          this.$emit("errorServer", error);
          console.log(error);
        });
    },

    clearFilterFields: function () {
      (this.serviceDateSince = new Date().toISOString().substr(0, 10)),
        (this.serviceDateTill = new Date().toISOString().substr(0, 10)),
        (this.sender = this.$store.state.auth.session.user.id);
      this.selectedItems = [];
      this.shipment_range = [this.min, this.max];
      this.delivery_range = [this.min, this.max];
      this.selectedItems = [];
      this.address = "";
      this.retrieveVouchers = false;
      this.monthlyBillFilter = false;
      this.paymentTypeFilter = [];
      this.urgencyFilter = [];
      this.selectedSkills = [];
    },

    createNewReport: function (resetPage) {
      if (
        (Date.parse(this.serviceDateTill).valueOf() -
          Date.parse(this.serviceDateSince).valueOf()) /
          1000 >=
        31 * 24 * 60 * 60
      )
        this.errorsArray.push("toowideperiod");
      if (this.errorAlerts) return;
      this.disabledSearch = true;
      var that = this;
      if (resetPage) that.options.page = 1;

      var params = {
        report: {
          query: {
            datesince: that.serviceDateSince,
            datetill: that.serviceDateTill,
            sender: that.$store.state.auth.session.user.id,
            items: that.selectedItems,
            vouchers_range: that.shipment_range,
            vouchers_delivery_range: that.delivery_range,
            address: that.address,
            skills: that.selectedSkills,
            drivers: [],
            retrieve_vouchers: that.retrieveVouchers,
            monthly_billing: that.monthlyBillFilter,
            payment_type: that.paymentTypeFilter,
            urgency: that.urgencyFilter,
            is_report: true,
          },
          options: that.options,
        },
      };
      RequestService.request_post(params, "reports")
        .then((response) => {
          that.disabledSearch = false;
          that.data = response.data.deliveries;
          that.itemsCount = parseInt(response.data.items_count);
          that.itemsTotalPrice = parseInt(response.data.total_price);
          if (isNaN(that.itemsTotalPrice)) that.itemsTotalPrice = 0;
        })
        .catch((error) => {
          this.$emit("errorServer", error);
          that.disabledSearch = false;
          console.log(error);
        });
    },
    blankOrNull: function (x) {
      if (!x || x == "") return true;
      return false;
    },
    exportFenixMonthlyBilling: function () {
      if (
        (Date.parse(this.serviceDateTill).valueOf() -
          Date.parse(this.serviceDateSince).valueOf()) /
          1000 >=
        31 * 24 * 60 * 60
      )
        this.errorsArray.push("toowideperiod");
      if (this.errorAlerts) return;
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Ermes-x";
      workbook.lastModifiedBy = "Ermes-x";
      var ws;
      var shipmentsToWrite;
      var that = this;
      var params = {
        report: {
          query: {
            datesince: that.serviceDateSince,
            datetill: that.serviceDateTill,
            sender: that.$store.state.auth.session.user.id,
            items: that.selectedItems,
            vouchers_range: that.shipment_range,
            vouchers_delivery_range: that.delivery_range,
            address: that.address,
            skills: that.selectedSkills,
            drivers: [],
            retrieve_vouchers: that.retrieveVouchers,
            monthly_billing: that.monthlyBillFilter,
            payment_type: that.paymentTypeFilter,
            urgency: that.urgencyFilter,
            is_report: false,
          },
          options: that.options,
        },
      };
      RequestService.request_post(params, "reports")
        .then((response) => {
          console.log(response.data.deliveries);
          shipmentsToWrite = response.data.deliveries;
          // Create worksheets with headers and footers
          ws = workbook.addWorksheet("Export ", {
            pageSetup: {
              paperSize: 9,
              orientation: "landscape",
              fitToPage: true,
              fitToHeight: 1,
              fitToWidth: 1,
              margins: {
                left: 0.1,
                right: 0.1,
                top: 0.1,
                bottom: 0.1,
                header: 0.1,
                footer: 0.1,
              },
            },
            headerFooter: {
              firstHeader: "Hello Exceljs",
              firstFooter: "Hello World",
            },
          });
          this.writeHeader(ws);
          var rowIdx = 2;
          for (var i = 0; i <= shipmentsToWrite.length - 1; i++) {
            console.log(shipmentsToWrite[i]);
            this.writeShipment(shipmentsToWrite[i], ws, rowIdx);
            rowIdx++;
          }
          workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "export.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
        })
        .catch((error) => {
          this.$emit("errorServer", error);
          console.log(error);
        });
    },
    toVal: function (x) {
      return ((x || "") + "").trim();
    },
    writeHeader: function (ws) {
      var headers = [
        "Spedizione",
        "Data",
        "Voce di Fatturazione",
        "Prezzo",
        "Tipologia di Pagamento",
        "Indirizzi di ritiro",
        "Indirizzi di consegna",
        "Veicolo",
      ];
      var borders = [
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          bottom: { style: "thick" },
        },
        {
          top: { style: "thick" },
          left: { style: "thick" },
          right: { style: "thick" },
          bottom: { style: "thick" },
        },
      ];
      var widths = [20, 20, 20, 20, 20, 20, 20, 20];
      var headerRow = ws.getRow(1);
      for (var i = 0; i < headers.length; i++) {
        headerRow.getCell(i + 1).value = headers[i];
        headerRow.getCell(i + 1).border = borders[i];
        ws.getColumn(i + 1).width = widths[i];
      }
    },
    writeShipment: function (ship, ws, startRowIdx) {
      console.log(ship);
      var values = [
        this.toVal(ship.shipment),
        this.toVal(ship.date),
        this.toVal(ship.item),
        this.toVal(ship.price),
        this.toVal(ship.payment_type),
        this.toVal(ship.pickup_addresses),
        this.toVal(ship.delivery_addresses),
        this.toVal(ship.skill_names),
      ];
      var row = ws.getRow(startRowIdx);
      for (var i = 0; i < values.length; i++) {
        row.getCell(i + 1).value = values[i];
      }
    },

    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>