<template>
  <v-app :key="componentKey">
    <v-app-bar app color="grey darken-3" dark>
      <v-app-bar-nav-icon
        v-if="loggedIn"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>ERMES-X</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-col :cols="1" align="right">
        <v-btn @click="logWithDifferentSender()" v-if="isAdmin">Logga</v-btn>
      </v-col>
      <v-col :cols="2" align="right" style="margin-top: 25px">
        <v-autocomplete
          v-if="isAdmin"
          :items="senders"
          :item-text="'username'"
          :item-value="'id'"
          v-model="selectedSender"
          color="white"
          label="Seleziona mittente"
        ></v-autocomplete>
      </v-col>
    </v-app-bar>
    <v-main>
      <router-view @errorServer="showErrorServer" />
    </v-main>

    <v-navigation-drawer v-if="loggedIn" v-model="drawer" fixed temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>person</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ username }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="$store.state.auth.session.company.show_vouchers">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("activerecord.attributes.sender.vouchers") }}
            {{ $t("common.remaining") }}:
            <b>{{ $store.state.auth.session.user.vouchers }}</b>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item link to="home">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t("activerecord.models.delivery.other")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="orders"
          v-if="
            ($store.state.auth.session.roles || []).includes(
              'open_orders_table'
            )
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-card-text</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t("activerecord.models.order.other")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <delivery-with-orders-dialog
          v-if="
            ($store.state.auth.session.roles || []).includes(
              'open_create_delivery_with_orders'
            )
          "
          :company-id="$store.state.auth.session.company.id"
          :sender-id="$store.state.auth.session.user.id"
          :sender-location="$store.state.auth.session.user.location"
          :sender-username="$store.state.auth.session.user.username"
          :company-email="$store.state.auth.session.company.email"
          :additional-data-schema="
            $store.state.auth.session.company.additional_data_schema
          "
        />
        <v-list-item 
          link
          to="inventories"
          v-if="
            ($store.state.auth.session.roles || []).includes(
              'open_show_inventories'
            )
          "
          >
          <v-list-item-icon>
            <v-icon>mdi-warehouse</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t("activerecord.models.inventory.other")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="new-service"
          v-if="$store.state.auth.session.company.enable_insert_from_open"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $tc("helpers.submit.new.m.one", null, {
                model: $t("common.service.one"),
              })
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="report"
          v-if="$store.state.auth.session.company.name == 'Fenix'"
        >
          <v-list-item-icon>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t("activerecord.models.report.one")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="delivery-import"
          v-if="$store.state.auth.session.company.name == 'Antylia Logistics'"
        >
          <v-list-item-icon>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >{{ $t("common.import") }}
              {{ $t("activerecord.models.delivery.other") }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logOut" dark>Esci</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-snackbar
      v-model="errorSnackbar"
      absolute
      top
      center
      color="red"
      style="margin-top: 60px"
    >
      {{ $t("activerecord.errors.messages.error_during_request") }}: <br />
      {{ messageErrorServer }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script type="text/javascript">
import DeliveryWithOrdersDialog from "./views/newDeliveryWithOrders/DeliveryWithOrdersDialog.vue";
import RequestService from "@/services/request.service.js";

export default {
  data() {
    return {
      drawer: null,
      errorSnackbar: false,
      messageErrorServer: "",
      error: null,
      senders: [],
      selectedSender: null,
      componentKey: 1,
    };
  },
  components: {
    DeliveryWithOrdersDialog,
  },
  computed: {
    isAdmin() {
      if (this.loggedIn) {
        if (this.$store.state.auth.session.user.admin) this.fetchSenders();
        return this.$store.state.auth.session.user.admin;
      } else return false;
    },
    username() {
      return this.loggedIn ? this.$store.state.auth.session.user.username : "";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    showErrorServer(error) {
      console.log(error);
      this.errorSnackbar = true;
      this.messageErrorServer = error.message;
    },
    fetchSenders() {
      var that = this;
      if (!this.$store.state.auth.session.user.admin) return;
      else {
        var address =
          "senders/" +
          this.$store.state.auth.session.user.id +
          "/fetch_every_sender";
        RequestService.request_post({}, address).then(
          (response) => {
            that.senders = response.data;
          },
          (error) => {
            console.log(error);
            this.errors = error.response.data;
          }
        );
      }
    },
    logWithDifferentSender() {
      if (this.selectedSender != null) {
        if (this.selectedSender)
          this.$store
            .dispatch("auth/login_as_sender", {
              senderToLogId: this.selectedSender,
              currentSenderId: this.$store.state.auth.session.user.id,
            })
            .then(
              () => {
                this.$router.push("/home");
                this.componentKey += 1;
              },
              (error) => {
                console.log(error);
              }
            );
      }
    },
  },
};
</script>
