<style scoped>
.leaflet-routing-container {
  display: none !important;
}
</style>
<template>
  <v-container fluid fill-height>
    <v-row style="height: 90vh; max-height: 90vh">
      <v-col :cols="4" style="max-height: 90vh; overflow-y: auto">
        <v-row class="text-center">
          <v-col :cols="12">
            <v-btn color="primary" @click="updateDriver">
              Aggiorna posizione driver
            </v-btn>
          </v-col>
        </v-row>
        <v-list>
          <v-subheader>{{
            $t("activerecord.models.delivery.other")
          }}</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item v-for="d in deliveries" :key="d.key" two-line>
              <v-list-item-icon>
                {{ d.key }}
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ d.address }}</v-list-item-title>
                <v-list-item-subtitle>{{ d.identifier }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col :cols="8">
        <l-map v-if="showMap" ref="map" :options="mapOptions">
          <l-tile-layer
            :url="url"
            :attribution="attribution"
            :options="{ id: id }"
          />
        </l-map>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import L from "leaflet";
import "leaflet-routing-machine";
import { LMap, LTileLayer } from "vue2-leaflet";
import RequestService from "@/services/request.service.js";
import DeliveryService from "@/services/delivery.service.js";

export default {
  name: 'Plan-vue',
  data() {
    var token = process.env.VUE_APP_MAPBOX_TOKEN;
    return {
      plan: null,
      showMap: true,
      url:
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
        token,
      attribution: `Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, 
                    Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`,
      id: "mapbox/streets-v11",
      mapOptions: {
        zoomSnap: 0.5,
      },
      driverMarker: null,
    };
  },
  computed: {
    deliveries() {
      var that = this;
      if (this.plan == null) return [];
      var dels = Object.keys(this.plan.deliveries)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map((k) => {
          var d = that.plan.deliveries[k];
          return {
            sender_id: d.sender_id,
            address: d.formatted_address,
            identifier: DeliveryService.fields_to_identify(d),
            coordinates: L.latLng(
              parseFloat(d.location.lat),
              parseFloat(d.location.lng)
            ),
            key: parseInt(k) + 1,
          };
        });
      return dels.map((d) => {
        if (that.$store.state.auth.session.user.id != d.sender_id) {
          d.address = "-";
          d.identifier = "-";
        }
        return d;
      });
    },
  },
  mounted() {
    var that = this;
    RequestService.request_get({}, "plans/" + this.$route.query.id).then(
      (response) => {
        var plan = response.data;
        that.plan = plan;
        var coordinates = that.deliveries.map((p) => p.coordinates);
        var map = this.$refs.map;

        //map.fitBounds(coordinates, { padding: [2, 2] });

        var control = L.Routing.control({
          waypoints: coordinates,
          serviceUrl: "https://vrpsolver.ermes-x.com/osrm/car/route/v1",
          createMarker(i, c) {
            var url =
              "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red" +
              (i + 1) +
              ".png";
            var icon = L.icon({
              iconUrl: url,
              iconAnchor: [11, 40],
            });
            return L.marker(c.latLng, { icon: icon });
          },
          fitSelectedRoutes: true,
          draggableWaypoints: false,
          routeWhileDragging: false,
          lineOptions: {
            addWaypoints: false,
          },
        });

        control.addTo(map.mapObject);
        // clean this shit
        control._container.style.display = "none";
      },
      (error) => {
        this.$emit("errorServer", error);
        alert("Errore nel recupero del piano.");
        console.log(error);
      }
    );
  },
  methods: {
    updateDriver() {
      var that = this;
      RequestService.request_get(
        {},
        "plans/" + this.$route.query.id + "/position"
      ).then(
        (response) => {
          var driver = response.data;
          var map = that.$refs.map;
          if (that.driverMarker != null)
            map.mapObject.removeLayer(that.driverMarker);

          var pos = L.latLng(driver.last_latitude, driver.last_longitude);

          that.driverMarker = L.marker(pos, {
            title: [driver.name, driver.last_updated_formatted].join("\n"),
            zIndexOffset: 8000,
          });

          that.driverMarker.addTo(map.mapObject);

          map.mapObject.panTo(pos);
        },
        (error) => {
          this.$emit("errorServer", error);
          alert("Errore nel recupero del driver.");
          console.log(error);
        }
      );
    },
  },
  components: { LMap, LTileLayer },
};
</script>
