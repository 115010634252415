<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-col class="ma-0 pa-0">
        <h3>{{ order.identifier }}</h3>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" class="ma-0 pa-0">
        <v-chip @click="$emit('update-order')" outlined label>
          <v-icon color="warning"> mdi-sync </v-icon>
        </v-chip>
      </v-col>
      <v-col cols="1" class="ma-0 pa-0">
        <v-chip @click="$emit('remove-order')" outlined label>
          <v-icon color="error"> mdi-close </v-icon>
        </v-chip>
      </v-col>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table :headers="headers" :items="order.items" :sort-by.sync="sortBy" class="elevation-1">
        <template v-slot:[`item.quantityRequired`]="{ item }">
          <v-btn
            @click="item.quantityRequired -= 1"
            x-small
            class="mr-2"
            :disabled="item.quantityRequired <= 0"
            color="error"
          >
            -
          </v-btn>
          {{ item.quantityRequired }}
          <v-btn
            @click="item.quantityRequired += 1"
            x-small
            class="ml-2"
            color="success"
          >
            +
          </v-btn>
        </template>
        <template v-slot:[`item.quantityInWaehouse`]="{ item }">
          {{ parseInt((item.stock || 0)) / parseInt((item.unitary_pieces|| 1)) }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
/* eslint-disable prettier/prettier */
export default {
  name: "order-panel",
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      order: JSON.parse(JSON.stringify(this.value)),
      sortBy: 'order_line',
      headers: [
        {
          text: this.$t('activerecord.attributes.item.identifier'),
          align: "center",
          sortable: false,
          value: "identifier",
        },
        {
          text: this.$t('activerecord.attributes.item.name'),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t('activerecord.attributes.item.serial_number'),
          align: "center",
          sortable: false,
          value: "serial_number",
        },
        {
          text: this.$t('activerecord.attributes.item.order_line'),
          align: "center",
          sortable: false,
          value: "order_line",
        },
        {
          text: this.$t('activerecord.attributes.item.prescheduled_quantity'),
          align: "center",
          sortable: false,
          value: "expected_stock",
        },
        {
          text: this.$t('activerecord.attributes.item.warehouse_quantity'),
          align: "center",
          sortable: false,
          value: "quantityInWaehouse",
        },
        {
          text: this.$t('activerecord.attributes.item.stock'),
          align: "center",
          sortable: false,
          value: "quantityRequired",
        }
      ],
    };
  },
  watch: {
    order: {
      handler(order) {
        this.$emit("input", order);
      },
      deep: true,
    },
    value: {
      handler(order) {
        this.order = order;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>