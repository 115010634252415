<style scoped>
div.text-left {
  text-align: left;
}

div.text-right {
  text-align: right;
}
</style>

<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-left">
          <b>{{ isSelf ? $t("common.you") : sender }}</b>
          {{
            orderFlag
              ? ` - ${$t("common.from.u")} ${$t(
                  "activerecord.models.order.one"
                )}`
              : ""
          }}
        </div>
      </v-col>
      <v-col>
        <div class="text-right">
          <v-icon
            outlined
            @click="$emit('openMail')"
            style="margin-right: 15px"
          >
            mdi-email
          </v-icon>

          <b>{{ createdAt }} </b>
        </div>
      </v-col>
    </v-row>
    {{ body }}
    <v-divider />
  </div>
</template>

<script>
export default {
  props: {
    sender: {
      type: String,
    },
    createdAt: {
      type: String,
    },
    body: {
      type: String,
    },
    isSelf: {
      type: Boolean,
      dafault: false,
    },
    orderFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newMessages: [],
      message: "",
    };
  },
};
</script>
