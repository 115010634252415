<template>
  <v-dialog v-model="dialog" max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="success"
        :disabled="inventory.confirmation_in_progress"
        v-if="!inventory.completed"
      >
        {{ $t("common.confirm") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <h2>
            {{ $t("common.want_complete_ionventory") }}
          </h2>
        </v-row>
        <v-row justify="center">
          <v-icon size="10vh"> mdi-alert </v-icon>
        </v-row>
        <v-row justify="center">
          <h3>
            {{ $t("common.sure_scanned_all_products") }}
          </h3>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="dialog = false">
          {{ $t("common.revert") }}
        </v-btn>
        <v-btn text color="light-blue" @click="confirmInventory">
          {{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "new-inventory",
  props: {
    inventory: {
      type: Object,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    confirmInventory() {
      this.$emit("inventory-confirm");
      this.dialog = false;
    },
  },
};
</script>