<template>
  <v-card 
    elevation="2"
    outlined
  >
    <v-card-title>{{ $t('activerecord.attributes.delivery.additional_data') }}</v-card-title>
    <v-card-text
      style="max-height: 500px; overflow-y: scroll"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Label</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, key) in schema.schema" v-bind:key="item.id + '-keydetail-' + key">
              <td>
                {{v.title}}
              </td>
              <td>
                {{
                  ("" + (additionalDataToString(item, key) || "")).replace(
                    /\n/g,
                    " | "
                  )
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable prettier/prettier */



export default {
  name: "AdditionalData",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    schema: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  components: {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    value: {
    },
  },
  computed: {
    
  },
  methods: {
    additionalDataToString(item, key) {
      var value = item.additional_data[key];
      if (value && (key == "urgency" || key == "payment_type")) {
        var options =
          this.$store.state.auth.session.company.additional_data_schema.schema[
            key
          ].options || [];
        var elem =
          options
            .filter(Boolean)
            .find((o) => o[1] == (value ? value.toLowerCase() : null)) || [];
        return elem[0] || "";
      }
      if (value && key == "monthly_bill") {
        return value == "1"
          ? this.$t("common.adfirmative")
          : this.$t("common.negative");
      }
      if (value && key == "sender") return value.username;
      return value;
    },
  },
};
</script>
