<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="success" v-if="!order">
          {{ $t("helpers.submit.new.m.one", null, { model: "" }) }}
        </v-btn>
        <v-icon v-if="order" v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col :cols="12">
                <ul v-if="baseErrors.length > 0">
                  <li
                    v-for="err in baseErrors"
                    :key="err"
                    style="color: #ff5252"
                  >
                    {{ err }}
                  </li>
                </ul>
                <br />
              </v-col>
            </v-row>
            <v-row v-if="!order">
              <v-col :cols="8">
                <v-text-field
                  label="Identificatore"
                  type="string"
                  v-model="order_attrs.identifier"
                  :error-messages="errors.identifier"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="providerName == 'fip'">
              <v-col :cols="8">
                <v-select
                  :items="warehouses"
                  item-text="name"
                  item-value="id"
                  v-model="order_attrs.warehouse_id"
                  label="Magazzino"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="$store.state.auth.session.user.master">
              <v-col :cols="8">
                <v-select
                  :items="senders"
                  item-text="username"
                  item-value="id"
                  v-model="order_attrs.sender_id"
                  label="Mittente"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <h3>Destinatario</h3>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  dense
                  hide-details
                  class="mt-0"
                  v-model="order_attrs.non_profit_association"
                  :label="
                    $t('activerecord.attributes.order.non_profit_association')
                  "
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Cognome"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_surname"
                      :error-messages="errors.billing_surname"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Nome"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_name"
                      :error-messages="errors.billing_name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Codice fiscale destinatario"
                      type="string"
                      v-model="order_attrs.receiver_data.fiscal_code"
                      :error-messages="errors.fiscal_code"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Codice"
                      type="string"
                      v-model="order_attrs.receiver_data.code"
                      :error-messages="errors.code"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Denominazione"
                      type="string"
                      v-model="order_attrs.receiver_data.denomination"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Partita iva destinatario"
                      type="string"
                      v-model="order_attrs.receiver_data.vat_number"
                      :error-messages="errors.vat_number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Pec"
                      type="string"
                      v-model="order_attrs.receiver_data.pec"
                      :error-messages="errors.pec"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <order-contacts
                  v-model="order_attrs.receiver_data.contacts"
                ></order-contacts>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-row>
                  <h3>Informazioni consegna</h3>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Extra km"
                      type="number"
                      v-model="order_attrs.receiver_data.extra_km"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Piano"
                      type="string"
                      v-model="order_attrs.receiver_data.floor"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="Note Ordine"
                      type="string"
                      v-model="order_attrs.receiver_data.fip_order_notes"
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row>
                  <h3>Indirizzo destinatario</h3>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Via"
                      type="string"
                      v-model="order_attrs.address_data.street"
                      :error-messages="errors.street"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Città"
                      type="string"
                      v-model="order_attrs.address_data.town"
                      :error-messages="errors.town"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="CAP"
                      type="string"
                      v-model="order_attrs.address_data.zipcode"
                      :error-messages="errors.zipcode"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Nazione"
                      type="string"
                      v-model="order_attrs.address_data.nation"
                      :error-messages="errors.nation"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Provincia"
                      type="string"
                      v-model="order_attrs.address_data.province"
                      :error-messages="errors.province"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row>
                  <h3>Indirizzo fatturazione</h3>
                  <v-checkbox
                    dense
                    class="mb-3"
                    @change="fillBillingAddressWithAddressData()"
                    label="Indirizzo fatturazione uguale all’indirizzo di consegna"
                  >
                  </v-checkbox>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Via"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_street"
                      :error-messages="errors.billing_street"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Città"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_town"
                      :error-messages="errors.billing_town"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="CAP"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_zipcode"
                      :error-messages="errors.billing_zipcode"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Nazione"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_nation"
                      :error-messages="errors.billing_nation"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Provincia"
                      type="string"
                      v-model="order_attrs.receiver_data.billing_province"
                      :error-messages="errors.billing_province"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <v-btn
                  v-if="!addWithholdingTax"
                  color="success"
                  white--text
                  depressed
                  @click="
                    addWithholdingTax = true;
                    order_attrs.need_stamp = true;
                  "
                  >Aggiungi ritenuta d'acconto
                </v-btn>
                <v-card v-show="addWithholdingTax" v-bind:key="1">
                  <v-col cols="12" align="left">
                    <h3>Dati ritenuta d'acconto</h3>
                  </v-col>
                  <v-row align-content="center" no-gutters>
                    <v-col cols="4" class="ma-1">
                      <v-select
                        :items="withholding_tax_codes"
                        v-model="order_attrs.withholding_tax_code"
                        :error-messages="errors.withholding_tax_code"
                        label="Codice ritenuta"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2" class="ma-1">
                      <v-select
                        :items="withholding_tax_percentages"
                        v-model="order_attrs.withholding_tax_percentage"
                        label="Percentuale ritenuta (%)"
                        outlined
                        dense
                        :error-messages="errors.withholding_tax_percentage"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="3" class="ma-1">
                      <v-select
                        :items="import_percentages"
                        v-model="order_attrs.withholding_tax_import_percentage"
                        :error-messages="
                          errors.withholding_tax_import_percentage
                        "
                        label="Percentuale importo su cui calcolare (%)"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2" class="ma-1">
                      <v-checkbox
                        dense
                        class="ma-1"
                        label="Marca da bollo (2€)"
                        v-model="order_attrs.need_stamp"
                        :error-messages="errors.need_stamp"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row align-content="center">
                    <v-col cols="12" class="ma-1">
                      <v-btn
                        class="ma-0"
                        color="error"
                        white--text
                        depressed
                        @click="cleanWithholdingTaxData()"
                        >Cancella dati ritenuta
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>Mittente</h3>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Ordine Originale"
                  type="string"
                  :value="showOriginalOrder"
                  :disabled="true"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Negozio di appoggio"
                  type="string"
                  v-model="order_attrs.sender_data.leaning_store"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Negozio di supporto"
                  type="string"
                  v-model="order_attrs.sender_data.support_shop"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Note"
                  type="text"
                  v-model="order_attrs.sender_data.notes"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="text-align: center">
                <h2 style="text-align: left">Spese</h2>
                <br />
                <expense-card
                  v-for="(expense, idx) in order_attrs.expenses_attributes"
                  v-show="!expense._destroy"
                  v-bind:key="expense.uuid"
                  v-model="order_attrs.expenses_attributes[idx]"
                  :invoiceNatures="invoiceNatures"
                  :ivaCodes="ivaCodes"
                  @archiveExpense="archiveExpense($event)"
                  :errors="expenseErrors[expense.uuid]"
                ></expense-card>
                <v-btn
                  class="ma-2"
                  color="success"
                  white--text
                  depressed
                  @click="addEmptyExpense"
                  >Aggiungi Spesa
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="text-align: center">
                <h2 style="text-align: left">Prodotti</h2>
                <br />

                <v-expansion-panels>
                  <item-panel
                    v-for="(item, idx) in order_attrs.items_attributes"
                    v-show="!item._destroy"
                    v-bind:key="item.uuid"
                    v-model="order_attrs.items_attributes[idx]"
                    :invoiceNatures="invoiceNatures"
                    :ivaCodes="ivaCodes"
                    :errors="itemErrors[item.uuid]"
                    @archived="archiveItem($event)"
                    @errors="$emit('errors', $event)"
                  ></item-panel>
                </v-expansion-panels>
                <v-btn
                  class="ma-2"
                  color="success"
                  white--text
                  depressed
                  @click="addEmptyItem"
                  >Aggiungi Prodotto
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="text-align: center">
                <h2 style="text-align: left">Prodotti Archiviati</h2>
                <br />
                <v-expansion-panels>
                  <item-panel
                    v-for="(item, idx) in order_attrs.old_items_attributes"
                    v-show="!item._destroy"
                    v-bind:key="item.uuid"
                    v-model="order_attrs.old_items_attributes[idx]"
                    :errors="itemErrors[item.uuid]"
                    :is-archived-item="true"
                  ></item-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="text-align: center">
                <h2 style="text-align: left">
                  {{ $t("common.archived_expenses") }}
                </h2>
                <br />
                <expense-card
                  v-for="(item, idx) in order_attrs.old_expenses_attributes"
                  v-show="!item._destroy"
                  v-bind:key="item.uuid"
                  v-model="order_attrs.old_expenses_attributes[idx]"
                  :errors="itemErrors[item.uuid]"
                  :is-archived-expense="true"
                ></expense-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false"> Chiudi </v-btn>
          <v-btn color="primary" text @click="createOrder()"> Salva </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable prettier/prettier */
import RequestService from "@/services/request.service.js";
import ItemPanel from "./ItemPanel.vue";
import ExpenseCard from "./ExpenseCard.vue";
import OrderContacts from "./OrderContacts.vue";
export default {
  name: "new-order",
  props: {
    order: {
      type: Object,
    },
    senders: {
      type: Array,
    },
    withholding_tax_codes: { type: Array },
    warehouses: {
      type: Array,
    },
  },
  components: { ItemPanel, OrderContacts, ExpenseCard },

  data() {
    return {
      dialog: false,
      billingAddress: true,
      ivaCodes: ["I22", "I10", "I4", "I0"],
      order_attrs: this.buildOrderAttributes(),
      errors: {},
      withholding_tax_percentages: [20, 23, 30],
      import_percentages: [20, 50, 100],
      addWithholdingTax: false,
      invoiceNatures: [
        this.$t("invoices.natures.N1"),
        this.$t("invoices.natures.N2_1"),
        this.$t("invoices.natures.N2_2"),
        this.$t("invoices.natures.N3_1"),
        this.$t("invoices.natures.N3_2"),
        this.$t("invoices.natures.N3_3"),
        this.$t("invoices.natures.N3_4"),
        this.$t("invoices.natures.N3_5"),
        this.$t("invoices.natures.N3_6"),
        this.$t("invoices.natures.N4"),
        this.$t("invoices.natures.N5"),
        this.$t("invoices.natures.N6_1"),
        this.$t("invoices.natures.N6_2"),
        this.$t("invoices.natures.N6_3"),
        this.$t("invoices.natures.N6_4"),
        this.$t("invoices.natures.N6_5"),
        this.$t("invoices.natures.N6_6"),
        this.$t("invoices.natures.N6_7"),
        this.$t("invoices.natures.N6_8"),
        this.$t("invoices.natures.N6_9"),
        this.$t("invoices.natures.N7"),
      ],
      providerName: this.$store.getters["auth/providerName"],
    };
  },

  watch: {
    dialog(val) {
      if (val) {
        this.order_attrs = this.buildOrderAttributes();
      } else {
        this.order_attrs = this.buildOrderAttrs(this.emptyOrder());
        this.errors = {};
      }
    },
  },

  methods: {
    isNotBlankOrNull(x) {
      if (!x || x == "") return false;
      return true;
    },
    emptyOrder() {
      return {
        address_data: {},
        receiver_data: {
          contacts: [],
        },
        sender_data: {},
        items: [],
        expenses: [],
        old_items: [],
        old_expenses: [],
      };
    },
    buildOrderAttributes() {
      var order = this.order;
      if (this.order == null) order = this.emptyOrder();
      return this.buildOrderAttrs(order);
    },
    buildOrderAttrs(order) {
      var senderCode;
      if (order.sender_data != null) {
        senderCode = order.sender_data.code;
        if (senderCode == null || senderCode.length == 0) {
          senderCode = this.$store.state.auth.session.user.code;
        }
      } else {
        senderCode = this.$store.state.auth.session.user.code;
      }
      if (
        order.withholding_tax_code != null ||
        order.withholding_tax_percentage != null ||
        order.withholding_tax_import_percentage != null
      )
        this.addWithholdingTax = true;

      return {
        id: order.id,
        identifier: order.identifier,
        warehouse_id: order.warehouse_id,
        company_id: this.$store.state.auth.session.company.id,
        non_profit_association: order.non_profit_association,
        address_data: {
          street: order.address_data.street,
          town: order.address_data.town,
          zipcode: order.address_data.zipcode,
          province: order.address_data.province,
          nation: order.address_data.nation,
        },
        receiver_data: {
          contacts: order.receiver_data.contacts || [],
          denomination: order.receiver_data.denomination,
          fip_order_notes: order.receiver_data.fip_order_notes,
          fiscal_code: order.receiver_data.fiscal_code,
          code: order.receiver_data.code || "0000000",
          name: order.receiver_data.name,
          billing_name: order.receiver_data.billing_name,
          billing_surname: order.receiver_data.billing_surname,
          vat_number: order.receiver_data.vat_number,
          pec: order.receiver_data.pec,
          extra_km: order.receiver_data.extra_km,
          floor: order.receiver_data.floor,
          billing_street: order.receiver_data.billing_street,
          billing_town: order.receiver_data.billing_town,
          billing_zipcode: order.receiver_data.billing_zipcode,
          billing_nation: order.receiver_data.billing_nation,
          billing_province: order.receiver_data.billing_province,
        },
        sender_data: {
          code: senderCode,
          original_order: order.sender_data.original_order,
          notes: order.sender_data.notes,
          leaning_store: order.sender_data.leaning_store,
          support_shop: order.sender_data.support_shop,
        },
        sender_id: this.$store.state.auth.session.user.id,
        items_attributes: order.items.map((i) => this.toItemAttribute(i)),
        expenses_attributes: order.expenses.map((e) =>
          this.toExpenseAttribute(e)
        ),
        withholding_tax_code: order.withholding_tax_code,
        withholding_tax_percentage: order.withholding_tax_percentage,
        withholding_tax_import_percentage:
          order.withholding_tax_import_percentage,
        need_stamp: order.need_stamp,
        old_items_attributes: order.old_items.map((i) =>
          this.toItemAttribute(i)
        ),
        old_expenses_attributes: order.old_expenses.map((e) =>
          this.toExpenseAttribute(e)
        ),
      };
    },
    generateUUID() {
      return Math.floor(Math.random() * 1000000);
    },
    fillBillingAddressWithAddressData() {
      var address_data = {};
      if (this.billingAddress) {
        address_data = this.order_attrs.address_data;
      }
      this.billingAddress = !this.billingAddress;
      this.order_attrs.receiver_data.billing_zipcode = address_data.zipcode;
      this.order_attrs.receiver_data.billing_town = address_data.town;
      this.order_attrs.receiver_data.billing_street = address_data.street;
      this.order_attrs.receiver_data.billing_province = address_data.province;
      this.order_attrs.receiver_data.billing_nation = address_data.nation;
    },
    createOrder() {
      var address = "";
      var that = this;
      this.order_attrs.receiver_data.name = [
        this.order_attrs.receiver_data.billing_name,
        this.order_attrs.receiver_data.billing_surname,
      ]
        .filter((e) => e)
        .join(" ");
      address = "orders/update_from_open";

      if (!this.order) {
        address = "orders/create_from_open";
        this.order_attrs.items_attributes =
          this.order_attrs.items_attributes.filter((i) => !i._destroy);
        this.order_attrs.expenses_attributes =
          this.order_attrs.expenses_attributes.filter((e) => !e._destroy);
        this.order_attrs.old_items_attributes =
          this.order_attrs.old_items_attributes.filter((i) => !i._destroy);
      }

      var params = { order: this.order_attrs };

      RequestService.request_post(params, address).then(
        (response) => {
          console.log(response);
          that.$emit("created");
          this.errors = {};
          this.dialog = false;
        },
        (error) => {
          console.log(error);
          this.errors = error.response.data;
        }
      );
    },
    toExpenseAttribute(expense) {
      return {
        id: expense.id,
        code: expense.code,
        description: expense.description,
        quantity: expense.quantity,
        unitary_price: expense.unitary_price,
        order_id: expense.order_id,
        extra: expense.extra,
        from_fip: expense.from_fip,
        old_order_id: expense.old_order_id,
        _destroy: false,
        uuid: this.generateUUID(),
        company_id: expense.company_id,
        cod_iva: expense.cod_iva,
        invoice_nature: expense.invoice_nature,
      };
    },
    addEmptyExpense() {
      this.order_attrs.expenses_attributes.push(
        this.toItemAttribute({
          company_id: this.$store.state.auth.session.company.id,
        })
      );
    },
    toItemAttribute(item) {
      return {
        id: item.id,
        name: item.name,
        stock: item.stock || 0,
        config: item.config,
        identifier: item.identifier,
        expected_stock: item.expected_stock,
        unitary_price: item.unitary_price,
        unitary_volume: item.unitary_volume,
        order_line: item.order_line,
        description: item.description,
        expo: item.expo,
        unitary_pieces: item.unitary_pieces,
        unitary_weight: item.unitary_weight,
        height: item.height,
        length: item.length,
        width: item.width,
        article_code: item.article_code,
        warehouse_area_id: item.warehouse_area_id,
        order_id: item.order_id,
        old_order_id: item.old_order_id,
        _destroy: false,
        uuid: this.generateUUID(),
        company_id: item.company_id,
        cod_iva: item.cod_iva,
        invoice_nature: item.invoice_nature,
      };
    },
    addEmptyItem() {
      this.order_attrs.items_attributes.push(
        this.toItemAttribute({
          company_id: this.$store.state.auth.session.company.id,
        })
      );
    },
    archiveItem(item_uuid) {
      var item = this.order_attrs.items_attributes.find(
        (i) => i.uuid == item_uuid
      );
      item.old_order_id = item.order_id;
      item.order_id = null;
      this.order_attrs.items_attributes.splice(
        this.order_attrs.items_attributes.findIndex((i) => i.uuid == item_uuid),
        1
      );
      this.order_attrs.old_items_attributes.splice(0, 0, item);
    },
    archiveExpense(expense_uuid) {
      var expense = this.order_attrs.expenses_attributes.find(
        (e) => e.uuid == expense_uuid
      );
      expense.old_order_id = expense.order_id;
      expense.order_id = null;
      this.order_attrs.expenses_attributes.splice(
        this.order_attrs.expenses_attributes.findIndex(
          (e) => e.uuid == expense_uuid
        ),
        1
      );
      this.order_attrs.old_expenses_attributes.splice(0, 0, expense);
    },
    cleanWithholdingTaxData() {
      this.order_attrs.withholding_tax_percentage = null;
      this.order_attrs.withholding_tax_code = null;
      this.order_attrs.withholding_tax_import_percentage = null;
      this.order_attrs.need_stamp = false;
      this.addWithholdingTax = false;
    },
  },
  computed: {
    showOriginalOrder() {
      var res = "";
      if (this.order) {
        var senderData = this.order.sender_data;
        if (this.isNotBlankOrNull(senderData)) {
          var originalOrder = senderData.original_order;
          if (this.isNotBlankOrNull(originalOrder))
            res = [originalOrder.number, originalOrder.type, originalOrder.year]
              .filter((n) => n)
              .join("-");
        }
      }
      return res;
    },
    baseErrors() {
      if (this.errors == null || this.errors.base == null) return [];
      return this.errors.base;
    },
    itemErrors() {
      if (this.errors == null || this.errors.items_attributes == null)
        return {};
      return this.errors.items_attributes;
    },
    expenseErrors() {
      if (this.errors == null || this.errors.expenses_attributes == null)
        return {};
      return this.errors.expenses_attributes;
    },
    visibleItems() {
      return this.order_attrs.items_attributes.filter((i) => !i._destroy);
    },
    title() {
      if (this.order) {
        return (
          this.$t("common.change") +
          " " +
          this.$t("activerecord.models.order.one") +
          " " +
          this.order.identifier
        );
      }
      return this.$t("helpers.submit.new.m.one", null, {
        model: this.$t("activerecord.models.order.one"),
      });
    },
  },
};
</script>
