<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>

<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on"> mdi-message-processing </v-icon>
    </template>
    <template>
      <v-overlay :value="overlayActive">
        <v-alert color="red" elevation="8" v-if="createNewOrderAlert">
          <b>
            {{
              $t(
                "activerecord.errors.messages.not_found_wanna_seach_it.m",
                null,
                { model: $t("activerecord.models.order.one") }
              )
            }}
          </b>
          <br />
          <br />
          <div align="center">
            <v-btn
              @click="creteOrder()"
              dense
              outlined
              width="20vh"
              class="ma-2"
              >{{ $t("common.create") }}</v-btn
            >
            <v-btn
              @click="createNewOrderAlert = false"
              dense
              outlined
              class="ma-2"
              width="20vh"
            >
              {{ $t("common.close") }}
            </v-btn>
          </div>
        </v-alert>
        <v-alert type="warning" elevation="8" v-if="hasDeliveryWarning">
          <b>
            {{
              $t(
                "activercord.errors.messages.warining_assigned_error.fm",
                null,
                {
                  model_object: $t("activerecord.models.delivery.one"),
                  model_subject: $t("activerecord.models.order.one"),
                }
              )
            }}
          </b>
          <v-icon @click="hasDeliveryWarning = false">mdi-close</v-icon>
        </v-alert>
        <v-alert type="success" elevation="8" v-if="orderCreatedAlert">
          <b>
            {{
              $t("common.created.m", null, {
                model: $t("activerecord.models.order.one"),
              })
            }}
          </b>
          <v-icon @click="orderCreatedAlert = false">mdi-close</v-icon>
        </v-alert>
        <v-alert type="success" elevation="8" v-if="orderFindAlert">
          <b>
            {{
              $t("common.presnet.one", null, {
                model: $t("activerecord.models.order.one"),
              })
            }}
          </b>
          <v-icon @click="orderFindAlert = false">mdi-close</v-icon>
        </v-alert>
        <v-alert type="error" elevation="8" v-if="generalError">
          <b>
            {{
              $t("activerecord.errors.messages.connection_error_reload_page")
            }}
          </b>
        </v-alert>
      </v-overlay>
      <v-card max-height="85vh">
        <v-toolbar color="grey darken-3" dark>
          <v-row style="text-align: center">
            <v-col cols="4">
              <h2>
                {{
                  $t("search", null, {
                    model: $t("activerecord.models.order.one"),
                  })
                }}:
              </h2>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-text-field
                dense
                outlined
                v-model="number"
                hide-details
                placeholder="NNNN"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                outlined
                v-model="kind"
                hide-details
                placeholder="KK"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                outlined
                v-model="year"
                placeholder="YYYY"
                hide-details
              />
            </v-col>
            <v-col cols="1" align-self="center">
              <v-icon
                outlined
                @click="searchOrder()"
                :disabled="!orderIdentiferCompleted"
              >
                mdi-magnify
              </v-icon>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text ref="messageList">
          <x-message
            v-for="message in deliveryNotes"
            v-bind:key="message.id"
            :is-self="isSelf(message.writer_name)"
            :sender="message.writer_name"
            :created-at="formatDate(message.created_at)"
            :body="message.description"
            :order-flag="!!message.order_id"
            @openMail="
              $emit('openMail', {
                message: message.description,
                id: orderId,
                controller: 'orders',
                identifier: getIdentifer(),
              })
            "
          >
          </x-message>
        </v-card-text>
        <v-footer class="justify-end">
          <v-textarea
            clearable
            filled
            auto-grow
            rows="1"
            :append-outer-icon="'mdi-send'"
            @click:append-outer="sendMessage"
            clear-icon="mdi-close-circle"
            v-model="message"
            hide-details="true"
            @keyup.native.enter="sendMessage"
            :disabled="!orderId"
          >
          </v-textarea>
        </v-footer>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
/* eslint-disable prettier/prettier */

import XMessage from "../views/XMessage";
import RequestService from "@/services/request.service.js";
import moment from "moment";

export default {
  name: "orderNoteDialog",
  props: {
    writerName: {
      type: String,
    },
    userId: {
      type: Number,
    },
  },
  components: { XMessage },
  data() {
    return {
      message: "",
      deliveryNotes: [],
      number: null,
      kind: null,
      year: moment().year(),
      orderId: null,

      createNewOrderAlert: false,
      hasDeliveryWarning: false,
      orderCreatedAlert: false,
      orderFindAlert: false,
      generalError: false,
    };
  },
  watch: {
    deliveryNotes() {
      this.scrollToEnd();
    },
  },
  computed: {
    orderIdentiferCompleted() {
      return !!this.kind && !!this.number && !!this.year;
    },
    overlayActive() {
      return (
        this.createNewOrderAlert ||
        this.hasDeliveryWarning ||
        this.orderCreatedAlert ||
        this.orderFindAlert ||
        this.generalError
      );
    },
  },
  methods: {
    searchOrder() {
      const params = this.oxParams();
      var that = this;
      RequestService.request_get(params, "/delivery_notes/find_order_chat")
        .then((res) => {
          if (!res.data.order_id) {
            that.createNewOrderAlert = true;
            return;
          }

          that.orderId = res.data.order_id;
          if (res.data.has_delivery) that.hasDeliveryWarning = true;
          else that.orderFindAlert = true;

          that.deliveryNotes.splice(
            0,
            that.deliveryNotes.length,
            ...res.data.delivery_notes
          );
        })
        .catch((err) => {
          that.$emit("error", err);
          that.generalError = true;
        });
    },
    sendMessage() {
      if (this.message.length == 0) return;
      var that = this;
      const params = {
        description: this.message,
        sender_id: this.userId,
        order_id: this.orderId,
      };

      RequestService.request_post(
        params,
        "delivery_notes/create_with_order"
      ).then(
        (response) => {
          that.deliveryNotes.push(response.data);
          that.message = "";
        },
        (err) => {
          that.$emit("error", err);
          that.generalError = true;
        }
      );
    },
    creteOrder() {
      const params = this.oxParams();
      var that = this;
      RequestService.request_post(params, "/delivery_notes/open_new_order_chat")
        .then((res) => {
          that.orderId = res.data.order_id;
          that.deliveryNotes.splice(0, that.deliveryNotes.length);
          that.createNewOrderAlert = false;
          that.orderCreatedAlert = true;
        })
        .catch((err) => {
          that.$emit("error", err);
          that.generalError = true;
        });
    },
    oxParams() {
      return {
        kind: this.kind,
        year: this.year,
        number: this.number,
      };
    },
    getIdentifer() {
      return [this.number, this.kind, this.year].join("-");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YY hh:mm:ss");
    },
    isSelf(writer) {
      return this.writerName == writer;
    },
    scrollToEnd() {
      var container = this.$refs.messageList;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>