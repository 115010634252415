<template>
  <v-card elevation="5">
    <v-card-text>
      <v-row
        v-for="(attr, idx) in rows"
        v-bind:key="idx"
        style="padding: 0px 15px 0px 15px"
      >
        <b>{{ attr.text }} :</b>
        <v-spacer></v-spacer>
        {{ attr.format(payment[attr.value]) }}
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <payment-modal
        :value="payment"
        :document-enum="documentEnum"
        :payment-enum="paymentEnum"
        :status-enum="statusEnum"
        @save="$emit('update', $event)"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import PaymentModal from "./PaymentModal.vue";

/* eslint-disable prettier/prettier */
export default {
  name: "PaymentCard",
  components: {
    PaymentModal,
  },
  props: {
    value: { type: Object },
    documentEnum: { type: Array },
    paymentEnum: { type: Array },
    statusEnum: { type: Array },
  },
  data() {
    var that = this;
    return {
      payment: JSON.parse(JSON.stringify(this.value)),
      rows: [
        {
          text: "ID",
          value: "id",
          format(val) {
            return val;
          },
        },
        {
          text: "Documento",
          value: "document_kind",
          format(val) {
            return that.translate(that.documentEnum, val);
          },
        },
        {
          text: "Pagamento",
          value: "payment_kind",
          format(val) {
            return that.translate(that.paymentEnum, val);
          },
        },
        {
          text: "Importo",
          value: "price",
          format(val) {
            return val;
          },
        },
        {
          text: "Sconto",
          value: "discount",
          format(val) {
            return `${val || 0}%`;
          },
        },
        {
          text: "Emesso il",
          value: "created_at",
          format(val) {
            return moment(val).format("HH:mm DD/MM/YY");
          },
        },
        {
          text: "Stato",
          value: "status",
          format(val) {
            return that.translate(that.statusEnum, val);
          },
        },
      ],
    };
  },
  watch: {
    value: {
      handler(val) {
        this.payment = JSON.parse(JSON.stringify(val));
      },
    },
  },
  methods: {
    translate(enums, val) {
      if (!enums) return val;

      const trad = enums.find((e) => e.value == val);
      if (!trad || !trad.text) return val;

      return trad.text;
    },
  },
};
</script>
