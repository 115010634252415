<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="user.username"
                label="Username"
                name="username"
                prepend-icon="mdi-account"
                type="text"
                @keyup.capture="hideMessageIfEmptyPassword"
              ></v-text-field>

              <v-text-field
                v-model="user.password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                @keydown.enter="handleLogin"
                @keyup.capture="hideMessageIfEmptyPassword"
              ></v-text-field>

              <span
                v-if="loginFailed"
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  color: red;
                "
              >
                {{ $t("activerecord.errors.messages.credentials_not_valid") }}
              </span>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.prevent="handleLogin" color="primary">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../models/user";

export default {
  name: "Login-vue",

  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      loginFailed: false,
      lastUser: new User("", ""),
    };
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },

  methods: {
    handleLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.loginFailed = false;
            this.$router.push("/home");
          },
          (error) => {
            this.loading = false;
            this.loginFailed = true;
            Object.assign(this.lastUser, this.user);
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },

    hideMessageIfEmptyPassword() {
      if (
        this.user.username != this.lastUser.username ||
        this.user.password != this.lastUser.password
      )
        this.loginFailed = false;
    },
  },
};
</script>
