<style>
</style>

<template>
  <tr :class="genClass()">
    <td>
      {{ index + 1 }} <b>{{ ismov ? "" : "*" }}</b>
    </td>
    <td>{{ invenotryIdentifier() }}<br />{{ inventoryDate() }}</td>
    <td>{{ (item.expo ? "EXPO - " : "") + item.name }}</td>
    <td>
      {{ item.identifier }}
      <span v-if="item.order_line">/ {{ item.order_line }}</span>
    </td>
    <td>
      {{
        [
          item.warehouse_area ? item.warehouse_area.name : null,
          item.place ? item.place.name : null,
        ]
          .filter((e) => !!e)
          .join(" - ")
      }}
    </td>
    <td>{{ item.unitary_price }}</td>
    <td>{{ item.unitary_volume }}</td>
    <td>{{ item.unitary_pieces }}</td>
    <td>{{ item.expected_stock }}</td>
    <td>{{ item.stock }}</td>
    <td>
      {{ qtaMoved }}
    </td>
    <td v-if="!inventory.completed">
      <create-movement-dialog
        :item-id="item.id"
        kind="in"
        :order-id="orderId"
        :inventory="inventory"
        @movement-added="$emit('movement-created')"
      />
      <create-movement-dialog
        :item-id="item.id"
        :max-can-remove="qtaMoved"
        kind="out"
        :order-id="orderId"
        :inventory="inventory"
        @movement-added="$emit('movement-created')"
      />
      <!-- <modal-media-show
        v-model="item.movement_medias"
        :notes="item.movements.map(mov => mov.notes).filter(note => (note!= null && note != ''))"
        v-if="medias_botton_to_show()"
        :title="`${item.name} - ${item.identifier}`"
      /> -->
    </td>
  </tr>
</template>

<script type="text/javascript">
import CreateMovementDialog from "./CreateMovementDialog.vue";
import moment from "moment";
// import ModalMediaShow from "../../media_components/modal_media_show.vue";

export default {
  name: "item-row",

  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
    ismov: {
      type: Boolean,
      default: true,
    },
    inventory: {
      type: Object,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    companyUseWarehousePlace: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      required: true,
    },
    notPresentinInventory: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreateMovementDialog,
  },
  data() {
    return {
      item: JSON.parse(JSON.stringify(this.value)),
    };
  },
  methods: {
    // medias_botton_to_show: function () {
    //   return ((this.item.movement_medias || []).length > 0 && this.item.damaged) || (this.item.damaged && this.item.movements.filter((mov) => mov.notes != '').length > 0)
    // },
    invenotryIdentifier() {
      if (!this.inventory || this.notPresentinInventory) return "";
      return this.inventory.identifier || "";
    },
    inventoryDate() {
      if (!this.inventory || this.notPresentinInventory) return "";
      return moment(this.inventory.date).format("DD/MM/YYYY") || "";
    },
    genClass: function () {
      return {
        warning: this.item.damaged,
        "grey lighten-1": this.notPresentinInventory,
        "green lighten-3": this.completed,
      };
    },
  },
  watch: {
    value: {
      handler(val) {
        this.item = val;
      },
      deep: true,
    },
    item: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  computed: {
    qtaMoved() {
      return (this.item.movements || [])
        .map((m) => parseFloat(m.quantity))
        .reduce((a, c) => a + c, 0.0);
    },
  },
};
</script>