import moment from "moment";
class newSeriviceControl {
  constructor() {
    this.companyName = null;
  }
  init(companyName) {
    this.companyName = companyName;
  }

  //PROP
  get companyName() {
    return this._companyName;
  }
  set companyName(value) {
    this._companyName = value;
  }

  get errorMessages() {
    return {
      missingShipmentFields: "Errore! Mancano dei dati di imballaggio",
      missingZipcodes: "Errore! Mancano dei Codici di Avviamento Postale",
      missingTimePickupEarliest: "Errore! Mancano gli orari in Ritiri da...",
      missingTimePickupLatest: "Errore! Mancano gli orari in Ritiri entro...",
      missingTimeDeliveryEarliest: "Errore! Mancano gli orari in Consegne da...",
      missingTimeDeliveryLatest: "Errore! Mancano gli orari in Consegne entro...",
      missingTowns: "Errore! Mancano dei Comuni",
      missingStreets: "Errore! Mancano delle Strade",
      missingProvinces: "Errore! Mancano delle Province",
      missingPaymentServices:
        "Errore! Per creare un servizio sono necessari almeno un ritiro e una consegna",
      missingSkill: "Errore! Per creare un servizio è necessario un veicolo",
      missingUrgency:
        "Errore! Per creare un servizio è necessaria una tempistica",
      invalidPrice: "Errore! Il prezzo calcolato non è aggiornato",
      noPaymentAddress:
        "Errore! Deve essere presente almeno un indirizzo collegato al pagamento",
      noPickups:
        "Errore! Per creare un servizio è necessario specificare almeno un ritiro",
      noDeliveries:
        "Errore! Per creare un servizio è necessario specificare almeno una consegna",
      missingFields:
        "Errore! Per creare un servizio è necessario compilare tutti i campi obbligatori",
      existingIdentifier: "Errore! L'identificativo inserito è già esistente",
      existingReceiver: "Cliente già presente in un'altra rubrica. Modificare la ragione sociale.",
      insufficientVouchers:
        "Errore! Il cliente non dispone di abbastanza tagliandi",
      warningVouchers: "Attenzione! hai meno di 8 tagliandi",
      noDenominationForRubric:
        "Errore! Per salvare in rubrica, Destinatario deve avere almeno 3 caratteri",
      proviceLengthIsWrong:
        "Errore! Il campo Provincia deve essere di due caratteri",
      zipcodeLengthIsWrong: "Errore! Il campo CAP deve essere massimo di 5 caratteri",
      zipcodeFormatIsWrong: "Errore! Il campo CAP deve essere formato da solo numeri",
      missingPaymentType: "Errore! Selezionare un metodo di pagamento",
      timeNotSelected: "Errore! Orari non inseriti correttamente",
      timeIsBeforeNow: "Errore! Non è possibile selezionare data e/o orari precedenti all'orario attuale",
      dataNotPresent: "Errore! È necessario compilare correttamente il campo Data servizio",
      general: "Errore durante il salvataggio del servizio",
      phoneNumberMissing: "Errore! È necessario compilare il campo Numero di Telefono",
      phoneNumberNotOnlyNumber: "Errore! Il campo Numero di telefono deve essere composto da soli numeri",
      intercomMissing: "Errore! È necessario compilare il campo Citofono"
    };
  }

  //METHODS

  getErrorMessage(error) {
    return this.errorMessages[error];
  }

  getServicesErrors(services, hasNotShipmentFields) {
    var errorsArray = [];
    if (!services) services = [];

    if (hasNotShipmentFields) errorsArray.push("missingShipmentFields");
    if (services.filter(s => s.type === "pickup").length < 1)
      errorsArray.push("noPickups");
    if (services.filter(s => s.type === "delivery").length < 1)
      errorsArray.push("noDeliveries");
    if (this.checkSaveInRubric(services))
      errorsArray.push("noDenominationForRubric");
    if (services.filter(s => s.address.province.length != 2).length > 0)
      errorsArray.push("proviceLengthIsWrong");
    if (services.filter(s => s.address.zipcode.length > 5).length > 0)
      errorsArray.push("zipcodeLengthIsWrong");
    if (services.filter(s => /[a-zA-Z]/.test(s.address.zipcode)).length > 0)
      errorsArray.push("zipcodeFormatIsWrong");

    return errorsArray;
  }
  getDataErrors(data) {
    var errorsArray = [];

    if (this.companyName == "DeliverMI") {
      errorsArray = this.getTimeErrors(data);
    }

    if (this.companyName == "Fenix" && this.timeErrorsPresent(data.timeWindows)) errorsArray.push("timeNotSelected");
    if (!data.updatedPrice || data.price == 0) errorsArray.push("invalidPrice");
    if (this.companyName == "DeliverMI") {
      if (this.hasNotPhoneNumber(data.services)) errorsArray.push("phoneNumberMissing");
      if (this.hasNotIntercom(data.services)) errorsArray.push("intercomMissing");
    }
    if (this.dataNotPresent(data.serviceDate)) errorsArray.push("dataNotPresent");
    if (this.timeIsBeforeNow(data.timeWindows,data.serviceDate)) errorsArray.push("timeIsBeforeNow");

    return errorsArray;
  }
  getSettingsErrors(data) {
    var errorsArray = [];

    if (this.hasNotAddressData(data.services, "zipcode")) errorsArray.push("missingZipcodes");
    if (this.hasNotAddressData(data.services, "town")) errorsArray.push("missingTowns");
    if (this.hasNotAddressData(data.services, "street")) errorsArray.push("missingStreets");
    if (this.hasNotAddressData(data.services, "province")) errorsArray.push("missingProvinces");
    if (data.urgency == "") errorsArray.push("missingUrgency");
    if (data.vehicleType == "") errorsArray.push("missingSkill");
    if (data.paymentType == "") errorsArray.push("missingPaymentType");
    if (this.companyName == "Fenix" && this.timeErrorsPresent(data.timeWindows))
      errorsArray.push("timeNotSelected");
    if (this.companyName == "DeliverMI" && data.hasNotShipmentFields)
      errorsArray.push("missingShipmentFields");
    return errorsArray;
  }

  getTimeErrors(data){
    var errorsArray = [];
    if (!data.timeWindows.pickup.earliest)  errorsArray.push("missingTimePickupEarliest");
    if (!data.timeWindows.pickup.latest)  errorsArray.push("missingTimePickupLatest");
    if (!data.timeWindows.delivery.earliest)  errorsArray.push("missingTimeDeliveryEarliest");
    if (!data.timeWindows.delivery.latest)  errorsArray.push("missingTimeDeliveryLatest");
    return errorsArray;
  }

  dataNotPresent(serviceDate){
    if (!serviceDate || serviceDate.trim() === "" || !(/^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/.test(serviceDate))){
      return true
    }
    return false
  }

  timeIsBeforeNow(timeWindows, date){
    var now = moment()
    var minutesNow = parseInt(now.hour()) * 60 + parseInt(now.minutes())

    if (moment().isSame(date, 'day')){
      if( this.getMinutesfromTime(timeWindows.pickup.earliest, minutesNow)< minutesNow ){
        return true
      }
      if( this.getMinutesfromTime(timeWindows.pickup.latest, minutesNow)< minutesNow ){
        return true
      }
      if( this.getMinutesfromTime(timeWindows.delivery.earliest, minutesNow)< minutesNow ){
        return true
      }
      if( this.getMinutesfromTime(timeWindows.delivery.latest, minutesNow)< minutesNow ){
        return true
      }
    }
    if (moment().isAfter(date, 'day')){
      return true;
    }
    return false;
  }
  getMinutesfromTime( time, minutesNow) {
    var minutesTime = minutesNow + 1
    if (time){
      minutesTime = parseInt(time.split(':')[0]) * 60 + parseInt(time.split(':')[1])
    }
    return minutesTime;
  }

  timeErrorsPresent(timeWindows) {
    if (
      !(
        timeWindows.pickup.earliest &&
        timeWindows.delivery.earliest &&
        timeWindows.delivery.latest
      )
    )
      return true;

    var p_e = this.newDateTime(timeWindows.pickup.earliest);
    var d_e = this.newDateTime(timeWindows.delivery.earliest);
    var d_l = this.newDateTime(timeWindows.delivery.latest);

    if (!timeWindows.pickup.latest) return d_l < p_e;

    var p_l = this.newDateTime(timeWindows.pickup.latest);

    return d_l < p_e || p_l < p_e || d_l < d_e;
  }

  getErrorsOnMounted(sender) {
    var errorsArray = [];

    //controlli esclusivi per fenix
    if (this.companyName == "Fenix") {
      if (sender.vouchers < 8 && sender.open_has_billing_flags == false)
        errorsArray.push("warningVouchers");
    }

    return errorsArray;
  }
  packagingControl(service) {
    if (this.companyName == "Antylia Logistics") {
      return (
        service.packaging_data.volume > 0 && service.packaging_data.weight > 0
      );
    }

    if (this.companyName == "DeliverMI") {
      return (
        service.packaging_data.weight > 0.0 && service.packaging_data.volume > 0
      );
    }

    if (this.companyName == "Fenix") {
      if (service.packaging == "bag") {
        return (
          service.packaging_data.length > 0 &&
          service.packaging_data.weight > 0 &&
          service.packaging_data.width > 0 &&
          service.packaging_data.height > 0
        );
      } else if (service.packaging == "box") {
        return (
          service.packaging_data.length > 0 &&
          service.packaging_data.weight > 0 &&
          service.packaging_data.width > 0 &&
          service.packaging_data.height > 0
        );
      } else {
        return true;
      }
    }

    return true;
  }

  //UTILITY FUNCTIONS

  checkSaveInRubric(services) {
    var that = this;
    return (
      services.filter(
        s => s.saveInRubric && that.getServiceDenomintation(s).length < 3
      ).length > 0
    );
  }

  getServiceDenomintation(s) {
    return !s.denomination ? "" : s.denomination.text || s.denomination;
  }
  newDateTime(time) {
    if (!time) {
      return null;
    } else {
      var newDate = new Date();
      newDate.setHours(time.substr(0, 2));
      newDate.setMinutes(time.substr(3, 2));
      newDate.setSeconds(0);
      return newDate;
    }
  }
  hasNotAddressData(services, addressData) {
    return (
      services.filter(
        (s) =>
          (!s.address[addressData] || s.address[addressData].trim() == "") &&
          s.hasRubricAddress == false &&
          s.hasSenderAddress == false
      ).length > 0
    );
  }
  hasNotPhoneNumber(services) {
    return (
      services.filter(s => !s.phone_number || s.phone_number == "").length > 0
    );
  }
  hasNotIntercom(services) {
    var value = 0;
    services.forEach(s => {
      if (s.intercom && s.intercom != "") value += 1;
    });
    return value < 1;
  }
}

export default new newSeriviceControl();
