<template>
  <v-row align-content="center" no-gutters>
    <v-col cols="10">
      <v-autocomplete
        dense
        v-model="order"
        :loading="loading"
        :items="orders"
        :search-input.sync="search"
        :no-filter="true"
        return-object
        outlined
        hide-details="auto"
        :label="$t('search', null, { model: $t('activerecord.models.order.one') })"
        @change="addOrder()"
        @keyup.enter="addOrder()"
      >
        <template v-slot:no-data>
          <div v-if="search && search.length > 2" class="px-4">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="identifier.number"
                  :label="$t('activerecord.attributes.order.number')"
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="identifier.type"
                  :label="$t('activerecord.attributes.order.type')"
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="identifier.year"
                  :label="$t('activerecord.attributes.order.year')"
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col>
                <v-btn color="warning" @click="downloadOrderFromFip" dense>
                  {{ $t("helpers.submit.download", null, { model: "" }) }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`${item.identifier}`"
              ></v-chip>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-text="`${item.billing_receiver}`"
              ></v-chip>
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-if="item.address_data.address"
                v-text="`${item.address_data.address}`"
              />
              <v-chip
                label
                class="ma-2"
                color="info"
                outlined
                v-if="item.in_warehouse"
              >
                <v-icon>mdi-warehouse</v-icon>
              </v-chip>
            </v-list-item-title>
            <v-divider></v-divider>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="2">
      <v-checkbox
        v-model="senderWarehouse"
        :label="$t('common.in_shop')"
        hide-details="true"
        class="mt-0 ml-4"
      />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable prettier/prettier */
import RequestService from "@/services/request.service.js";

export default {
  name: "add-order-field",
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    senderId: {
      type: Number,
      required: true,
    },
    filterBySender: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      order: null,
      orders: [],
      search: null,
      loading: false,
      senderWarehouse: false,
      identifier: {},
    };
  },
  methods: {
    addOrder() {
      if (!this.order) return;

      this.$emit("add-order", this.order);
      this.order = null;
      this.orders = [];
    },
    clear() {
      this.item = null;
      this.search = null;
      this.loading = false;
      this.senderWarehouse = false;
    },
    downloadOrderFromFip() {
      if (!this.identifierInserted) return;
      var address = "orders/get_fip_orders";
      var that = this;
      var snackbar = {
        color: "error",
        text: "",
      };
      RequestService.request_get(this.identifier, address).then(
        (response) => {
          if (response.data.success) {
            snackbar.color = "success";
            (snackbar.text = this.$t("helpers.effects.downloaded.m.one", null, {
              model: this.$t("activerecord.models.order.one"),
            })),
              (that.search = null);
            that.identifier = {};
            that.order = response.data.order;
            that.addOrder();
            that.$emit("snackbar", snackbar);
          } else {
            snackbar.color = "error";
            (snackbar.text = this.$t("errors.messages.not_found_on", null, {
              model: this.$t("activerecord.models.order.one"),
            })),
              (that.search = null);
            that.identifier = {};
            that.$emit("snackbar", snackbar);
          }
        },
        (error) => {
          snackbar.color = "error";
          snackbar.text = error.response.data.error;
          that.search = null;
          that.identifier = {};
          that.$emit("snackbar", snackbar);
          console.log(error);
        }
      );
    },
    identifierInserted() {
      return (
        this.identifier.number != null &&
        this.identifier.number.length > 0 &&
        this.identifier.type != null &&
        this.identifier.type.length > 0 &&
        this.identifier.year != null &&
        this.identifier.year.length > 0
      );
    },
    fetchOrders(val) {
      var that = this;
      this.loading = true;
      return RequestService.request_post(
        {
          query: val,
          sender_id: this.filterBySender ? this.senderId : null,
          warehouse_area_id: this.senderWarehouse
            ? this.$store.state.auth.session.user.warehouse_area_id
            : null,
        },
        "orders/search"
      ).then(
        (response) => {
          that.orders = response.data;
          that.loading = false;
        },
        (error) => {
          this.$emit("error", error);
          alert("Errore di comunicazione con il server.");
          console.log(error);
          that.loading = false;
        }
      );
    },
  },
  watch: {
    search(val) {
      if (!val || val.length < 3) {
        this.orders = [];
        return;
      }
      this.fetchOrders(val);
    },
  },
};
</script>
