export default function authHeader() {
  let session = JSON.parse(localStorage.getItem("session"));

  if (session && session.token) {
    return {
      Authorization: "Bearer " + session.token,
      "User-kind": "sender"
    };
  } else {
    return {};
  }
}
