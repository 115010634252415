import RequestService from "@/services/request.service.js";
import moment from "moment";

class AntyliaLogisticsXLSXImporter {
  constructor(xlsxRows, fileName, skills) {
    this.xlsxRows = xlsxRows;
    this.fileName = fileName;
    this.skills = skills;
    var that = this;

    this.import = function() {
      var params = {
        import: { file_name: this.fileName, total_rows: this.xlsxRows.length },
        authenticity_token: window._token
      };
      var problem = {
        deliveries: this.xlsxRows.map(that.parseRow, that).filter(d => d != null)
      };

      RequestService.request_post(params, "imports").then(
        response => {
          console.log(response);
          this.importId = response.data.id;
          if (this.importId != null)
            problem.deliveries.forEach(d => (d.import_id = this.importId));

          RequestService.request_post(problem, "deliveries/create_deliveries").then(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );
        },
        error => {
          console.log(error);
        }
      );
    };
  }
  getCell(row, key) {
    if (row[key] == undefined) return "";
    else return row[key] + "";
  }
  formatDate(date) {
    var d = new Date(date), month = "" + (d.getMonth() + 1), day = "" + d.getDate(), year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  parseRow(row) {
    var that = this;
    var start_time = that.getCell(row, "DALLE").trim();
    var stop_time = that.getCell(row, "ALLE").trim();
    if (start_time == "") start_time = "00:00";
    if (stop_time == "") stop_time = "23:59";
    var contained_skills = that.getCell(row, "ABILITA").split("-").map(t => t.trim());
    var found_skills = [];
    for (var key in that.skills) {
      if (contained_skills.includes(that.skills[key].name)) {
        found_skills.push(that.skills[key].id);
      }
    }
    return {
      "first_date": that.formatDate(that.getCell(row, "DATA CONSEGNA PREVISTA")),
      "last_date": that.formatDate(that.getCell(row, "DATA CONSEGNA PREVISTA")),
      "weight": that.getCell(row, "PESO"),
      "volume": that.getCell(row, "VOLUME"),
      "identifier": that.getCell(row, "CODICE"),
      "start_time": start_time,
      "stop_time": stop_time,
      "skill_ids": found_skills,
      "address": {
        "zipcode": that.getCell(row, "CAP"),
        "province": that.getCell(row, "PROVINCIA").trim(),
        "town": that.getCell(row, "CITTA").trim(),
        "street": that.getCell(row, "INDIRIZZO").trim(),
        "address_notes": (that.getCell(row, "NOTE INDIRIZZO") + '').trim()
      },
      "additional_data": {
        "volume_data": that.getCell(row, "COLLI").trim(),
        "sender_company": that.getCell(row, "AZIENDA MITTENTE").trim(),
        "recipient": that.getCell(row, "AZIENDA DESTINATARIO").trim(),
        "sender_code": that.getCell(row, "CODICE MITTENTE").trim(),
        "delivery_company_code": that.getCell(row, "CODICE ORDINE INTERNO").trim(),
        "phone_number": that.getCell(row, "TELEFONO").trim(),
        "email": that.getCell(row, "EMAIL").trim(),
        "floor": that.getCell(row, "PIANO").trim(),
        "receiving_date": new moment().format('Y-MM-DD')
      }
    };
  }
}

export default AntyliaLogisticsXLSXImporter;
