<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h3>{{ $t("common.filter.other") }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-spacer></v-spacer>
          <v-col align-self="center" :md="3">
            <v-select
              outlined
              :label="$t('common.filter.one')"
              v-model="filterSelected"
              hide-details="false"
              :items="elementRequire == 0 ? filterItemsOrder : filterItemsExtra"
              item-text="text"
              item-value="value"
              @change="currentOrderPage = 1"
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="center" :md="3">
            <v-btn-toggle
              v-model="elementRequire"
              @change="ordersOrExtraChanges()"
              mandatory
              class="filter-align"
            >
              <v-btn>
                {{ $t("activerecord.models.order.other") }}
              </v-btn>
              <v-btn>
                {{ $t("common.extra") }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="center" :md="3">
            <v-checkbox
              v-model="includeItemNotWaited"
              :disabled="elementRequire == 1"
              :label="$t('common.view_unanticipated_products')"
              class="filter-align"
              @change="$emit('load-data')"
            >
            </v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col align-self="center">
            <v-select
              outlined
              :label="$t('activerecord.attributes.order.status')"
              v-model="states"
              :items="orderStatuses"
              multiple
              :item-text="[1]"
              :item-value="[0]"
              small-chips
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="center">
            <v-text-field
              v-model="identifier.number"
              :label="$t('activerecord.attributes.order.number')"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col align-self="center">
            <v-text-field
              v-model="identifier.type"
              :label="$t('activerecord.attributes.order.type')"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col align-self="center">
            <v-text-field
              v-model="identifier.year"
              :label="$t('activerecord.attributes.order.year')"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col align-self="center">
            <v-btn
              color="warning"
              class="ma-2"
              @click="findByIdentifier()"
              dense
            >
              {{ $t("search", null, { model: "" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script type="text/javascript">
export default {
  name: "inventory-filter",
  props: {
    filterOptionsProp: {
      type: Object,
    },
    orderStatuses: {
      type: Array,
    },
  },
  data() {
    return {
      filterOptions: JSON.parse(JSON.stringify(this.filterOptionsProp)),
      identifier: {
        number: "",
        type: "",
        year: "",
      },
      filterSelected: null,
      elementRequire: 0,
      includeItemNotWaited: false,
      find: "",
      states: "",
      currentOrderPage: 1,

      filterItemsOrder: [
        {
          text: "",
          value: null,
        },
        {
          text: this.$t("enum.order.filter_inventory_order.product_not_found"),
          value: "product_not_found",
        },
        {
          text: this.$t(
            "enum.order.filter_inventory_order.orders_with_discrepancies"
          ),
          value: "orders_with_discrepancies",
        },
        {
          text: this.$t(
            "enum.order.filter_inventory_order.order_not_completed"
          ),
          value: "order_not_completed",
        },
        {
          text: this.$t("enum.order.filter_inventory_order.control"),
          value: "control",
        },
        {
          text: this.$t(
            "enum.order.filter_inventory_order.order_checked_completed"
          ),
          value: "order_checked_completed",
        },
        {
          text: this.$t("enum.order.filter_inventory_order.order_completed"),
          value: "order_completed",
        },
      ],

      filterItemsExtra: [
        {
          text: "",
          value: null,
        },
        {
          text: this.$t(
            "enum.order.filter_inventory_order.orders_with_discrepancies"
          ),
          value: "orders_with_discrepancies",
        },
        {
          text: this.$t(
            "enum.order.filter_inventory_order.order_not_completed"
          ),
          value: "order_not_completed",
        },
        {
          text: this.$t("enum.order.filter_inventory_order.order_completed"),
          value: "order_completed",
        },
      ],
    };
  },
  watch: {
    states: {
      handler(val) {
        this.filterOptions.states = val;
        this.$emit("set-filter-options",this.filterOptions);
      },
      deep: true,
    },
    filterSelected: {
      handler(val) {
        this.filterOptions.filterSelected = val;
        this.$emit("set-filter-options",this.filterOptions);
      },
      deep: true,
    },
    elementRequire: {
      handler(val) {
        this.filterOptions.elementRequire = val;
        this.$emit("set-filter-options",this.filterOptions);
      },
      deep: true,
    },
    includeItemNotWaited: {
      handler(val) {
        this.filterOptions.includeItemNotWaited = val;
        this.$emit("set-filter-options",this.filterOptions);
      },
      deep: true,
    },
    identifier: {
      handler(val) {
        if (
          val.number.trim() == "" ||
          val.type.trim() == "" ||
          val.year.trim() == ""
        ) {
          this.filterOptions.identifier = "";
        }
        this.$emit("set-filter-options",this.filterOptions);
      },
      deep: true,
    },
  },
  methods: {
    ordersOrExtraChanges(){
      this.filterSelected = null;
      this.currentOrderPage = 1;
      this.includeItemNotWaited = !this.includeItemNotWaited;
      if(this.elementRequire == 1){
        this.includeItemNotWaited = true;
      }
    },
    findByIdentifier() {
      if (
        this.identifier.number.trim() == "" ||
        this.identifier.type.trim() == "" ||
        this.identifier.year.trim() == ""
      ) {
        var snackbar = {
          color: "error",
          text: this.$t("common.inventory_order_search_identifier_error"),
        };
        this.$emit("add-snackbar-error", snackbar);
      } else {
        var identifier = [
          this.identifier.number,
          this.identifier.type,
          this.identifier.year,
        ].join("-");
        this.filterOptions.identifier = identifier;
        this.$emit("set-filter-options-from-click-serch",this.filterOptions);
      }
    },
  },
};
</script>