import AuthService from "../services/auth.service";

const session = JSON.parse(localStorage.getItem("session"));
const initialState = session
  ? { status: { loggedIn: true }, session }
  : { status: { loggedIn: false }, session: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        sess => {
          commit("loginSuccess", sess);
          return Promise.resolve(sess);
        },
        error => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    login_as_sender({ commit }, senders_ids) {
      return AuthService.login_as_sender(senders_ids).then(
        sess => {
          commit("loginSuccess", sess);
          return Promise.resolve(sess);
        },
        error => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  getters: {
    providerName (state) {
      let provider = state.session.user.provider;
      let company = state.session.company;
      if (provider == null) {
        if (company.oms == "fip") {
          return "fip";
        } else {
          return null;
        }
      } else {
        return (provider.name || '').toLowerCase();
      }
    }
  },
  mutations: {
    loginSuccess(state, sess) {
      state.status.loggedIn = true;
      state.session = sess;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.session = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.session = null;
    }
  }
};
