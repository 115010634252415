<style type="text/css">
</style>

<template>
  <v-container fluid v-if="order" :class="genClass()">
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.attributes.order.identifier')"
          v-model="order.identifier"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-if="order.receiver_data.name"
          outlined
          readonly
          :label="$t('activerecord.models.receiver.one')"
          v-model="order.receiver_data.name"
          :hide-details="true"
        ></v-text-field>
        <v-text-field
          v-if="!order.receiver_data.name"
          outlined
          readonly
          :label="$t('activerecord.models.receiver.one')"
          v-model="order.receiver_data.denomination"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          outlined
          :label="$t('activerecord.attributes.order.status')"
          :items="statuses_list"
          :item-text="[1]"
          :item-value="[0]"
          v-model="order.status"
          @change="$emit('status', order.status)"
          :hide-details="true"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="mb-2">
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.attributes.order.formatted_address')"
          v-model="order.address_data.address"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.attributes.order.denomination')"
          v-model="order.receiver_data.denomination"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.models.contact.other')"
          v-model="contactText"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('activerecord.attributes.order.notes')"
          v-model="order.receiver_data.fip_order_notes"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-spacer></v-spacer>
    <v-divider v-if="deliveryDetails"></v-divider>
    <v-row class="mt-2" v-if="deliveryDetails">
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field
          outlined
          readonly
          :value="
            $t('activerecord.models.delivery.one') +
            ': ' +
            (deliveryDetails ? deliveryDetails.id || '' : '')
          "
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('common.delivery_date')"
          :value="deliveryDetails ? deliveryDetails.date || '' : ''"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('common.plan_name')"
          :value="deliveryDetails ? deliveryDetails.plan_name || '' : ''"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          readonly
          :label="$t('common.driver_name')"
          :value="deliveryDetails ? deliveryDetails.driver_names || '' : ''"
          :hide-details="true"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-divider v-if="deliveryDetails"></v-divider>
    <v-simple-table>
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("activerecord.models.inventory.one") }}</th>
          <th>{{ $t("activerecord.attributes.item.name") }}</th>
          <th>{{ $t("activerecord.attributes.item.identifier") }}</th>
          <th>{{ $t("activerecord.models.warehouse_area.one") }}</th>
          <th>{{ $t("activerecord.attributes.item.unitary_price") }}</th>
          <th>{{ $t("activerecord.attributes.item.unitary_weight") }}</th>
          <th>{{ $t("activerecord.attributes.item.unitary_packages") }}</th>
          <th>{{ $t("activerecord.attributes.item.expected_stock") }}</th>
          <th>{{ $t("activerecord.attributes.item.stock_in_warehouse") }}</th>
          <th>{{ $t("activerecord.attributes.item.confirmed_quantity") }}</th>
          <th v-if="!inventory.completed">{{ $t("common.action.other") }}</th>
        </tr>
      </thead>
      <tbody>
        <item-row
          v-for="(item, index) in order.items"
          v-bind:key="item.id"
          v-model="order.items[index]"
          :index="index"
          :ismov="expecteditem(order.items[index].id)"
          :order-id="order.id"
          :inventory="inventory"
          :completed="
            item.notPresentinInventory == false &&
            item.stock ==
              item.movements.reduce((acc, cur) => acc + cur.quantity, 0)
          "
          :notPresentinInventory="item.notPresentinInventory"
          @movement-created="$emit('movement-created')"
        >
        </item-row>
      </tbody>
    </v-simple-table>
    <hr />
    <v-row>
      <v-col>
        <h4>{{ $t("common.expected_goods") }}</h4>
        <v-simple-table v-if="itemsExpected.length > 0">
          <thead>
            <tr>
              <th>{{ $t("activerecord.attributes.item.name") }}</th>
              <th>{{ $t("activerecord.attributes.item.movement_date") }}</th>
              <th>{{ $t("activerecord.attributes.item.stock") }}</th>
              <th>{{ $t("activerecord.models.warehouse_worker.one") }}</th>
              <th>{{ $t("activerecord.models.inventory.one") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in itemsExpected" v-bind:key="item.id">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <td v-bind="attrs" v-on="on">
                    {{ itemsName[item.item_id] }}
                  </td>
                </template>
                <span>{{ itemsIdentifier[item.item_id] }}</span>
              </v-tooltip>
              <td>{{ momentTime(item.created_at) }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ getMovementWarehouseWorkerName(item) }}</td>
              <td>
                {{ getMovementInventoryIdentifier() }}<br />{{
                  getMovemetInventoryDate()
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <h4>{{ $t("common.confirmed_goods") }}</h4>
        <v-simple-table v-if="itemChecked.length > 0">
          <thead>
            <tr>
              <th>{{ $t("activerecord.attributes.item.name") }}</th>
              <th>{{ $t("activerecord.attributes.item.movement_date") }}</th>
              <th>{{ $t("activerecord.attributes.item.stock") }}</th>
              <th>{{ $t("activerecord.models.warehouse_worker.one") }}</th>
              <th>{{ $t("activerecord.models.inventory.one") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in itemChecked" v-bind:key="item.id">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <td v-bind="attrs" v-on="on">
                    {{ itemsName[item.item_id] }}
                  </td>
                </template>
                <span>{{ itemsIdentifier[item.item_id] }}</span>
              </v-tooltip>
              <td>{{ momentTime(item.created_at) }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ getMovementWarehouseWorkerName(item) }}</td>
              <td>
                {{ getMovementInventoryIdentifier() }}<br />{{
                  getMovemetInventoryDate()
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script type="text/javascript">
import ItemRow from "./ItemRow.vue";
import moment from "moment";

export default {
  name: "inventory-content",

  props: {
    orderpass: {
      type: Object,
    },
    mov: {
      type: Array,
      default: function () {
        return [];
      },
    },
    orderstatuses: {
      type: Array,
    },
    inventory: {
      type: Object,
    },
    includeItemNotWaited: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      order_id: null,
      order: null,
      statuses_list: [],
      deliveryDetails: null,
    };
  },

  created: function () {
    this.statuses_list = this.orderstatuses;
    this.order = this.orderpass;
    this.deliveryDetails = this.orderpass.deliveryDetails;
  },

  watch: {
    orderpass: {
      deep: true,
      handler(newVal) {
        this.order = newVal;
      },
    },
  },

  computed: {
    contactText() {
      if (!this.order.receiver_data) return "";
      if (!this.order.receiver_data.contacts) return "";
      return this.order.receiver_data.contacts.length == 1
        ? this.order.receiver_data.contacts[0] + ""
        : this.order.receiver_data.contacts.slice(0, 2).join(" - ");
    },
    itemsExpected() {
      return this.order.items
        .filter((t) => t.expected_movements.length > 0)
        .map((t) => {
          t.expected_movements.map((e) => {
            e.quantity *= t.unitary_pieces;
            return e;
          });
          return t;
        })
        .flatMap((t) => t.expected_movements);
    },
    itemChecked() {
      return this.order.items
        .filter((t) => t.movements.length > 0)
        .flatMap((t) => t.movements);
    },
    itemsName() {
      return this.order.items.reduce(
        (unique, d) => ({ ...unique, [d.id]: d.name }),
        {}
      );
    },
    itemsIdentifier() {
      return this.order.items.reduce(
        (unique, d) => ({ ...unique, [d.id]: d.identifier }),
        {}
      );
    },
  },

  methods: {
    getMovementInventoryIdentifier: function () {
      if (!this.inventory) return "";
      return this.inventory.identifier || "";
    },
    getMovemetInventoryDate: function () {
      if (!this.inventory) return "";
      return !this.inventory.date ? "" : this.momentDate(this.inventory.date);
    },
    getMovementWarehouseWorkerName: function (movement) {
      if (!movement) return "";
      if (!movement.warehouse_worker) return "";
      return movement.warehouse_worker.name || "";
    },

    missingAddress: function () {
      return !this.order.address_data.town.trim();
    },
    orderComplete: function () {
      return this.order.status.includes("complete");
    },
    orderPartial: function () {
      return this.order.status.includes("partial");
    },
    genClass: function () {
      return {
        "green lighten-2": this.order.inventoryStatus === "scan_completed",
        "yellow lighten-2": this.order.inventoryStatus === "scan_partial",
      };
    },
    expecteditem: function (id) {
      return this.mov.includes(id);
    },
    momentDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    momentTime: function (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
  components: {
    ItemRow,
  },
};
</script>
>