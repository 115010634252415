<style scoped></style>
<template>
  <v-container fluid class="mb-16">
    <v-card class="mb-4">
      <v-card-title>
        {{ $t("activerecord.models.delivery.other") }}
        <v-spacer></v-spacer>
        <v-col>
          <v-text-field
            v-model="search.text"
            prepend-icon="mdi-magnify"
            :label="$t('search', null, { model: '' })"
            single-line
            hide-details
            @click:prepend="run_search"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="search.dateSince"
                :label="$t('common.from.u')"
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.dateSince"
              locale="it"
              scrollable
              @change="dateMenu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="search.dateTill"
                :label="$t('common.to.u')"
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="search.dateTill"
              locale="it"
              scrollable
              @change="dateMenu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-select
            style="max-width: 30vw"
            class="ml-2"
            v-model="search.states"
            :items="states"
            item-text="text"
            item-value="value"
            attach
            :label="$t('activerecord.attributes.plan.status')"
            single-line
            hide-details
            multiple
          ></v-select>
        </v-col>
        <v-col v-if="this.$store.state.auth.session.company.oms == 'fip'">
          <v-select
            style="max-width: 30vw"
            class="ml-2"
            v-model="search.assistances"
            :items="assistanceStatus"
            item-text="text"
            item-value="value"
            attach
            :label="$t('activerecord.attributes.plan_delivery.assistance')"
            single-line
            hide-details
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="search.createdInOpen"
            :label="
              $t('activerecord.attributes.delivery.created_in_open.other')
            "
            hide-details="true"
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :sort-by.sync="defaultSort"
        :sort-desc.sync="defaultDesc"
        :server-items-length="options.itemsPerPage * pageCount"
        :loading="loading"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        class="elevation-1"
        style="height: 100%"
      >
        <template
          v-slot:[`header.delivery_note_chat`]="{  }"
          v-if="
            this.$store.state.auth.session.company.oms == 'fip' &&
            this.$store.state.auth.session.company.use_note_chat
          "
        >
          <order-note-dialog
            :writerName="$store.state.auth.session.user.username"
            :userId="$store.state.auth.session.user.id"
            @openMail="openMail"
          />
        </template>
        <template v-slot:[`item.receiver.to_s`]="{ item }">
          {{ getReceiverName(item) }}
        </template>
        <template v-slot:[`item.identifier`]="{ item }">
          <div v-if="company.name != 'MVA'">
            <a :href="genTrackingUrl(item)" target="_blank">
              {{ getIdentifier(item) }}
            </a>
          </div>
          <div v-if="company.name == 'MVA'">
            {{ getIdentifier(item) }}
          </div>
        </template>
        <template v-slot:[`item.shipments`]="{ item }">
          <v-chip>
            {{ getShipment(item) }}
          </v-chip>
        </template>
        <template v-slot:[`item.plan_name`]="{ item }">
          <div v-if="company.name != 'Fenix'">
            <router-link
              v-if="item.latest_plan_delivery != null"
              :to="{
                path: '/plan',
                query: { id: item.latest_plan_delivery.plan_id },
              }"
            >
              {{ get_latest_plan_name(item) }}
            </router-link>
          </div>
        </template>
        <template v-slot:[`item.print_to_pdf`]="{ item }">
          <v-btn
            v-if="item.latest_plan_delivery != null"
            @click="startPrint(item.latest_plan_delivery, item)"
            text
            small
            color="info"
          >
            Stampa
          </v-btn>
        </template>
        <template v-slot:[`item.send_help_mail`]="{ item }">
          <v-btn text small :href="genMailingUrl(item)" color="alert">
            Mail
          </v-btn>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ isNaN(parseInt(item.price)) ? "" : parseInt(item.price) }}
        </template>
        <template v-slot:[`item.plan_date`]="{ item }">
          {{ get_latest_plan_date(item) }}
        </template>
        <template v-slot:[`item.volume_data`]="{ item }">
          {{ get_volume_data(item) }}
        </template>
        <template #[`item.service_type`]='{ item }'>
          <v-chip color="primary" dark v-if="item.additional_data['extra_activity']">
            {{$t('enum.delivery.extra_activity.'+ item.additional_data['extra_activity'])}}
          </v-chip>
          <v-chip :color="get_color(item.service_type)" dark v-else>
            {{ translate(item.service_type) }}
          </v-chip>
        </template>
        <template #[`item.latest_plan_delivery.state`]="{ item }">
          <v-chip
            v-if="item.latest_plan_delivery"
            :color="get_color(item.latest_plan_delivery.state)"
            dark
          >
            {{ translate(item.latest_plan_delivery.state) }}
            <v-icon
              small
              v-show="
                item.latest_plan_delivery.confirmed &&
                item.latest_plan_delivery.state == 'planned'
              "
              class="ml-1"
            >
              mdi-check
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.latest_plan_delivery.assistance`]="{ item }">
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="item.latest_plan_delivery"
                :color="get_color(item.latest_plan_delivery.assistance)"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{ translate(item.latest_plan_delivery.assistance) }}
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                v-for="(status, i) in assistanceStatus"
                :key="i"
                @click="updateAssistance(item, status.value)"
              >
                <v-list-item-title>{{ status.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.delivery_note_chat`]="{ item }">
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="
                  deliveryChatNote = {
                    delivery_notes: item.delivery_notes,
                    delivery_id: item.id,
                  }
                "
                v-bind="attrs"
                v-on="on"
              >
                mdi-message-processing-outline
              </v-icon>
            </template>
            <template>
              <deliveryNoteChat
                @newMessage="saveMessage"
                @updateMessages="
                  updateMessages(deliveryChatNote['delivery_id'])
                "
                @openMail="openMail"
                :deliveryNotes="deliveryChatNote['delivery_notes']"
                :writerName="$store.state.auth.session.user.username"
                :delivery-id="item.id"
              >
              </deliveryNoteChat>
            </template>
          </v-dialog>
          <!-- This next button should have its own column and not be added in 'delivery_note_chat' -->
          <!-- But it's 2 AM and I'm tired -->
          <v-icon
            v-if="
              ($store.state.auth.session.roles || []).includes(
                'open_create_delivery_with_orders'
              )
            "
            @click="printItemLabelPdf(item.id)"
          >
            mdi-file-pdf-box
          </v-icon>
          <v-icon
            v-if="($store.state.auth.session.roles || []).includes('open_create_delivery_with_orders') && item.created_in_open"
            @click="downloadLogisticServicePdf(item.id)"
          >
            mdi-truck
          </v-icon>
          <v-icon
            color="red"
            v-if="canDeleteDelivery(item)"
            @click="destroyDeliveryCreatedInOpen(item.id)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="row">
              <div class="col-sm-5">
                <additional-data
                  :schema="schema"
                  :item="item"
                >
                </additional-data>
              </div>
              <div class="col-sm-7">
                <v-card 
                  elevation="2"
                  outlined
                >
                  <v-card-title>{{ $t('activerecord.models.plan_delivery.other') }}</v-card-title>
                  <v-card-text
                    style="max-height: 500px; overflow-y: scroll"
                  >
                    <v-simple-table
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>{{ $t("activerecord.attributes.plan.date") }}</th>
                            <th>{{ $t("activerecord.attributes.plan.name") }}</th>
                            <th>{{ $t("activerecord.attributes.driver.name") }}</th>
                            <th>{{ $t("activerecord.attributes.plan_delivery.message") }}</th>
                            <th>{{ $t("activerecord.models.missed_delivery_reason.one") }}</th>
                            <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="plan_delivery in item.plan_deliveries"
                            :key="plan_delivery.id"
                          >
                            <td>{{ get_plan_date(plan_delivery) }}</td>
                            <td>{{ get_plan_name(plan_delivery) }}</td>
                            <td>
                              <template v-if="plan_delivery.plan && plan_delivery.plan.driver">
                                {{ plan_delivery.plan.driver.name }}
                              </template>
                              <template v-else>
                                {{ $t("common.n/a") }}
                              </template>
                            </td>
                            <td>{{ plan_delivery.driver_notes }}</td>
                            <td>{{ get_missed_delivery_reason_description(plan_delivery) }}</td>
                            <td>
                              <template v-if="videoOrPhotoPresent(plan_delivery)">
                                <pd-media-dialog
                                  :plan_delivery="plan_delivery"
                                >
                                </pd-media-dialog>
                              </template>
                              <template v-else>
                                {{ $t("common.n/a") }}
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
                <delivery-items-table
                  :delivery-id="item.id"
                >
                </delivery-items-table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4" v-if="company.name == 'Fenix'">
                <div style="font-weight: bold">
                  {{ $t("activerecord.models.vehicle_type.other") }}:
                </div>
                {{ ("" + (vehicleSkills(item) || "")).replace(/\n/g, " | ") }}
              </div>
            </div>
            <div v-if="company.use_payments">
              <delivery-payments
                v-model="item.payments"
                @new="newPayment($event, item.id)"
                @update="updatePayment($event)"
                :document-enum="documentEnum"
                :payment-enum="paymentEnum"
                :status-enum="statusEnum"
              />
              <hr />
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="snackbar.value"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable prettier/prettier */

import RequestService from "@/services/request.service.js";
import DeliveryService from "@/services/delivery.service.js";
import S3MediaService from "@/services/s3media.service.js";
import deliveryNoteChat from "../views/DeliveryNoteChat.vue";
import DeliveryPayments from "./payment/DeliveryPayments.vue";
import OrderNoteDialog from "./OrderNoteDialog.vue";

import { jsPDF } from "jspdf";
import AdditionalData from './delivery_details/AdditionalData.vue';
import DeliveryItemsTable from './delivery_details/DeliveryItemsTable.vue';
import PdMediaDialog from './delivery_details/PdMediaDialog.vue';
const APP_URL = process.env.VUE_APP_URL;

export default {
  name: 'Home-vue',
  data() {
    return {
      dateSince: [],
      company: null,
      user: this.$store.state.auth.session.user,
      dateMenu1: false,
      dateMenu2: false,
      pageCount: 0,
      headers: [],
      items: [],
      defaultSort:
        this.$store.state.auth.session.company.name == "Fenix"
          ? "created_at"
          : "plan_date",
      defaultDesc: true,
      schema: {},
      expanded: [],
      singleExpand: false,
      imagesData: {},
      videosData: {},
      loading: false,
      options: {},
      search: {},
      // TODO replace with it18n
      translations: null,
      colors: {
        delivery: "green",
        pickup: "red",
        planned: "orange",
        traveling: "blue",
        completed: "green",
        failed: "red",
        opened: "red",
        closed: "green",
        read: "warning",
        to_pay: "red",
        payed: "green",
      },
      assistanceStatus: [
        { text: "", value: null },
        {
          text: this.$t("enum.plan_delivery.assistance.opened"),
          value: "opened",
        },
        { text: this.$t("enum.plan_delivery.assistance.read"), value: "read" },
        {
          text: this.$t("enum.plan_delivery.assistance.closed"),
          value: "closed",
        },
      ],
      deliveryChatNote: {},
      documentEnum: null,
      paymentEnum: null,
      statusEnum: null,
      states: null,
      delivermiSignatureUrl: require("@/assets/delivermi_sigla_firma.png"),
      delivermiLogoUrl: require("@/assets/delivermi_logo.png"),

      snackbar: {
        color: "success",
        text: "",
        value: false,
        timeout: 5000,
      },
      channelName: "",
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch_deliveries();
      },
      deep: true,
    },
    channelName(val, oldVal) {
      if (val != "" && val != oldVal) {
        var channel = this.$pusher.subscribe(val);
        channel.bind("open-generic-messages", function (data) {
          if (data.message) {
            alert(data.message);
          }
          if (data.file_url) {
            window.open(data.file_url, '_blank');
          }
        });
      }
    },
  },

  beforeDestroy() {
    this.$pusher.unsubscribe(this.channelName);
  },

  components: { deliveryNoteChat, DeliveryPayments, OrderNoteDialog, AdditionalData, PdMediaDialog, DeliveryItemsTable },

  created() {
    this.fetchEnums();
  },

  computed: {
    additionalDataKeyGroups() {
      var array = Object.keys(this.schema.schema);
      var groups = [];
      var i,
        j,
        chunk = 3;
      for (i = 0, j = array.length; i < j; i += chunk)
        groups.push(array.slice(i, i + chunk));
      return groups;
    },
  },

  methods: {
    canDeleteDelivery(delivery) {
      return (
        (this.$store.state.auth.session.roles || []).includes('open_create_delivery_with_orders') &&
        delivery.created_in_open &&
        delivery.plan_deliveries.length == 0
      )
    },
    destroyDeliveryCreatedInOpen(deliveryId) {
      var that = this;
      if (confirm(this.$t('common.operation_confimed'))) {
        RequestService.request_post(
          {},
          "deliveries/" + deliveryId + "/destroy_delivery_created_in_open",
        ).then((response) => {
          console.log(response)
          this.fetch_deliveries();
        })
        .catch((error) => {
          console.log(error);
          that.snackbar = {
              text: error.response.data.message,
              color: "error",
              timeout: 5000,
              value: true,
            };
        });
      }
    },
    printItemLabelPdf(id) {
      RequestService.request_get(
        {},
        "deliveries/" + id + "/print_delivery_items_label",
      ).then((response) => {this.channelName = response.data.channel_name});
    },
    downloadLogisticServicePdf(id) {
      RequestService.request_get(
        {},
        "deliveries/" + id + "/download_logistic_service_pdf",
      ).then((response) => {this.channelName = response.data.channel_name});
    },
    startPrint(plan_delivery, item) {
      var signatures = [];
      signatures = plan_delivery.medias.filter(
        (m) => m.kind == "signature"
      );
      var that = this;
      if (signatures.length > 0) {
        var latest_signature = signatures.reduce((a, b) =>
          a.id > b.id ? a : b
        );
        S3MediaService.login(
          () => {
            S3MediaService.getMedia(
              latest_signature.bucket,
              latest_signature.name,
              (err, data) => {
                var blob = new Blob([data.Body.buffer]);
                var srcUrl = URL.createObjectURL(blob);
                this.generatePdf(item, srcUrl);
              }
            );
          },
          (err) => {
            console.log(err);
            that.snackbar = {
              text: that.$t(
                "activerecord.errors.messages.error_during_request_retry"
              ),
              color: "error",
              timeout: 5000,
              value: true,
            };
          }
        );
      } else {
        this.generatePdf(item, null);
      }
    },
    generatePdf(item, signatureUrl) {
      var n_notes = [];

      if (item.additional_data.notes) {
        item.additional_data.notes = this.chunk(
          item.additional_data.notes,
          45
        ).join("\n");
        n_notes = item.additional_data.notes.split("\n");
      }

      var plan_date = "";
      if (item.latest_plan_delivery) {
        plan_date = item.latest_plan_delivery.plan.formatted_date;
      }
      var payment = item.additional_data.payment_method || "";

      if (!signatureUrl) {
        var img = new Image();
        img.src = signatureUrl;
      }

      const doc = new jsPDF();
      var boxwidth = 208;
      var midpoint = boxwidth / 2;
      var startx = 1;
      var starty = 1;
      var firstline = 39;
      var secondline = 10;
      var thirdline = 35;
      var fourthline = 30;
      var fifthline = 14;
      var sixthline = 17;
      var boxheight =
       firstline + secondline + thirdline + fourthline + fifthline + sixthline;

      var headercell = 7;
      var datacolumns = 14;
      var signaturespace = 32;

      var textcol = 4;
      var notecol = 10;

      doc.rect(startx, starty, boxwidth, boxheight);
      doc.rect(startx, starty + firstline, midpoint, boxheight - firstline);
      doc.rect(
        midpoint + startx,
        starty + firstline,
        midpoint,
        boxheight - firstline
      );
      doc.rect(startx, starty + firstline + secondline, midpoint, thirdline);
      doc.rect(
        midpoint + startx,
        starty + firstline + secondline,
        midpoint,
        thirdline
      );
      doc.rect(
        startx,
        starty + firstline + secondline + thirdline + fourthline,
        midpoint,
        sixthline + fifthline
      );
      doc.rect(
        midpoint + startx,
        starty + firstline + secondline + thirdline + fourthline,
        midpoint,
        fifthline
      );

      doc.rect(
        midpoint + startx,
        starty + firstline + secondline + thirdline,
        signaturespace,
        fourthline
      );
      doc.rect(
        midpoint + startx,
        starty + firstline + secondline + thirdline,
        datacolumns * 2 + signaturespace,
        fourthline
      );
      doc.rect(
        midpoint + startx,
        starty + firstline + secondline + thirdline + headercell,
        datacolumns * 2 + signaturespace,
        fourthline - headercell
      );

      doc.setFontSize(10);
      doc.text(
        "Colli",
        midpoint + textcol,
        firstline + secondline + thirdline + headercell
      );
      doc.text(
        (item.additional_data.volume_data || "") + "",
        midpoint + textcol,
        firstline + secondline + thirdline + fourthline * (2 / 3)
      );
      doc.text(
        "Peso",
        midpoint + signaturespace + textcol,
        firstline + secondline + thirdline + headercell
      );
      doc.text(
        (item.weight || "") + "",
        midpoint + signaturespace + textcol,
        firstline + secondline + thirdline + fourthline * (2 / 3)
      );
      doc.text(
        "Timbro e firma",
        midpoint + datacolumns * 2 + signaturespace + notecol,
        firstline + secondline + thirdline + headercell
      );
      //item.latest_plan_delivery.driver_notes || "" it doesn't work
      if (!item.latest_plan_delivery.driver_notes)
        item.latest_plan_delivery["driver_notes"] = "";

      doc.text(
        "Note:  " + this.truncateString(item.latest_plan_delivery.driver_notes, 35),
        textcol,
        firstline + secondline + thirdline + headercell,
        { maxWidth: 80 }
      );
      
      var i;
      for (i = 0; i < n_notes.length && i < 3; i++) {
        doc.text(
          this.truncateString(n_notes[i], 35),
          notecol + textcol,
          firstline + secondline + thirdline + headercell + 5 * i,
          { maxWidth: 80 }
        );
      }
      if (this.$store.state.auth.session.user.departments.length > 0){
        doc.text(
          "Dipartimento:  " + (item.additional_data.department || ""),
          textcol,
          firstline + secondline + thirdline + fourthline + fifthline * (1 / 2),
          { maxWidth: 80 }
        );
      }

      doc.text(
        "Destinatario:",
        textcol,
        firstline + secondline + thirdline * (1 / 6)
      );
      doc.text(
        this.truncateString(this.getReceiverName(item), 100) +
          "\n" +
          item.street +
          "\n" +
          item.zipcode +
          ", " +
          item.province +
          "\n" +
          item.town,
        textcol,
        firstline + secondline + thirdline * (3 / 10),
        { maxWidth: 95 }
      );
      if (this.company.name == "Antylia Logistics")
        doc.text(
          "Mittente: " + item.additional_data.sender_company,
          midpoint + textcol,
          firstline + secondline + thirdline * (1 / 6),
          { maxWidth: 95 }
        );
      else {
        doc.text(
          "Mittente: ",
          midpoint + textcol,
          firstline + secondline + thirdline * (1 / 6)
        );
        doc.text(
          this.truncateString(this.user.tax_entity.denomination, 100) +
            "\n" +
            this.user.full_address.street +
            "\n" +
            this.user.full_address.zipcode +
            ", " +
            this.user.full_address.province +
            "\n" +
            this.user.full_address.town,
           midpoint + textcol,
          firstline + secondline + thirdline * (3 / 10),
          { maxWidth: 95 }
        );
      }
      doc.text(
        "Data Spedizione: " + plan_date,
        textcol,
        firstline + secondline * (2 / 3)
      );
      if (this.company.name == "Fenix")
        doc.text(
          this.company.name,
          midpoint + textcol,
          firstline + secondline * (2 / 3)
        );
      else
        doc.text(
          this.company.name,
          midpoint + textcol,
          firstline + secondline * (2 / 3)
        );
      if (this.company.name != "Fenix"){
        doc.text(
          "Firma conducente:",
          textcol,
          firstline + secondline + thirdline + fourthline + fifthline * (1 / 3)
        );
      }
      var riceventename = "";
      if (item.latest_plan_delivery.signature_person != null) {
        riceventename = item.latest_plan_delivery.signature_person;
      }
      doc.text(
        "Nome leggibile del ricevente: \n" + riceventename,
        midpoint + textcol,
        firstline + secondline + thirdline + fourthline + fifthline * (1 / 2)
      );
      var datetowrite;
      if (item.latest_plan_delivery.state_update_time != null) {
        datetowrite = new Date(
          item.latest_plan_delivery.state_update_time
        ).toLocaleString("it-IT");
      } else {
        datetowrite = "";
      }

      doc.text(
        "Data e ora consegna: \n" + datetowrite,
        midpoint + textcol,
        firstline +
          secondline +
          thirdline +
          fourthline +
          fifthline +
          sixthline * (1 / 2)
      );
      doc.text(
        "LDV: " + item.identifier,
        midpoint / 2 + notecol,
        firstline * (4 / 10)
      );
      if (payment) {
        doc.text(
          "Contrassegno: " + payment,
          midpoint / 2 + notecol,
          firstline * (7 / 10)
        );
      }
      if (signatureUrl)
        doc.addImage(
          signatureUrl,
          midpoint + datacolumns * 2 + signaturespace * 2 + notecol,
          firstline + secondline + thirdline + headercell - 20,
          20,
          40,
          "",
          null,
          90
        );
      if (this.company.name == "DeliverMI") {
        var delivermiSignature = new Image();
        var delivermiLogo = new Image();
        delivermiSignature.onload = function () {
          doc.addImage(this, 
            textcol,
            firstline + secondline + thirdline + fourthline + fifthline * (1 / 3),
            30,
            28
          );
          delivermiLogo.onload = function () {
            doc.addImage(this, 15, 3, 35, 35);
            doc.save(item.identifier + ".pdf");
          };
        };
        delivermiSignature.crossOrigin = "";
        delivermiSignature.src = this.delivermiSignatureUrl;
        delivermiLogo.crossOrigin = "";
        delivermiLogo.src = this.delivermiLogoUrl;
      } else doc.save(item.identifier + ".pdf");
    },
    chunk(str, n) {
      var ret = [];
      var i;
      var len = str.length;

      for (i = 0; i < len; i += n) {
        ret.push(str.substr(i, n + i));
      }

      return ret;
    },
    translate(s) {
      return s ? this.translations[s] : "";
    },
    get_color(s) {
      return s ? this.colors[s] : "white";
    },
    truncateString(string, n){
      return (string.length > n) ? string.slice(0, n) : string;
    },
    getReceiverName(item) {
      if (this.company.name == "Fenix")
        return item.additional_data.denomination || "";
      if (item.receiver) return item.receiver.to_s;
      if (item.additional_data)
        if (item.additional_data.recipient)
          return item.additional_data.recipient;
      if (item.additional_data.recipient_person)
        return item.additional_data.recipient_person;
      if (item.additional_data.recipient_company)
        return item.additional_data.recipient_company;
      return "";
    },
    get_plan_name(plan_delivery) {
      if (plan_delivery) {
        if (plan_delivery.plan) {
          if (plan_delivery.plan.name) {
            return plan_delivery.plan.name;
          }
        }
      } else {
        return "";
      }
    },
    get_latest_plan_name(item) {
      if (item.latest_plan_delivery && item.latest_plan_delivery.plan) {
        return item.latest_plan_delivery.plan.name;
      } else {
        return "";
      }
    },
    get_plan_date(plan_delivery) {
      if (plan_delivery) {
        if (plan_delivery.plan) {
          if (plan_delivery.plan.formatted_date) {
            return plan_delivery.plan.formatted_date;
          }
        }
      } else {
        return "";
      }
    },
    get_missed_delivery_reason_description(plan_delivery) {
      if (
        plan_delivery &&
        plan_delivery.missed_delivery_reason &&
        plan_delivery.missed_delivery_reason.description
      ) {
        return plan_delivery.missed_delivery_reason.description;
      }
      return "";
    },
    get_latest_plan_date(item) {
      if (item.latest_plan_delivery && item.latest_plan_delivery.plan) {
        return item.latest_plan_delivery.plan.formatted_date;
      } else {
        return "";
      }
    },
    get_volume_data(item) {
      if (item.additional_data.volume_data) {
        return item.additional_data.volume_data;
      } else {
        return "";
      }
    },
    getShipment(item) {
      if (item.shipments) {
        if (item.shipments[0]) return item.shipments[0].identifier;
      } else {
        return "";
      }
    },
    getIdentifier(item) {
      return DeliveryService.fields_to_identify(item);
    },
    genTrackingUrl(item) {
      return process.env.VUE_APP_URL + "d?id=" + item.identifier;
    },
    genMailingUrl(item) {
      return (
        "mailto:servizio.clienti@antylia-logistics.it?subject=Consegna-" +
        item.identifier
      );
    },
    run_search() {
      if (this.options.page == 1) {
        this.fetch_deliveries();
      } else {
        // ugly hack, but this will trigger the options watcher and run a
        // search where the page is the first one. Otherwise, the page was
        // not resetted.
        this.options.page = 1;
        this.fetch_deliveries();
      }
    },
    fetch_deliveries() {
      var that = this;
      this.loading = true;
      var search = this.search;
      var options = this.options;
      return RequestService.request_post(
        { deliveries: { search, options } },
        "senders/" + this.user.id + "/deliveries"
      ).then(
        (response) => {
          var data = response.data;
          that.company = data.company_name;
          that.schema = data.schema;
          that.headers = data.headers;
          that.headers.push({ text: "", value: "data-table-expand" });
          that.items = data.deliveries;
          that.pageCount = data.page_count;
          that.loading = false;
          that.items.forEach((i) => {
            i.plan_deliveries.forEach((pd) =>
              this.$set(pd, "showImages", false)
            );
            i.delivery_notes.reverse();
            i.created_at = that.getFormatDate(
              i.created_at.substring(0, "AAAA-MM-GG".length)
            );
          });
        },
        (error) => {
          this.$emit("errorServer", error);
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.connection_error_with_server"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
          console.log(error);
          that.loading = false;
        }
      );
    },
    fetchEnums() {
      var that = this;
      this.translations;
      RequestService.request_get({}, "plan_deliveries/enums").then(
        (response) => {
          that.translations = Object.values(response.data).reduce(
            (acc, cur) => Object.assign(acc, ...cur),
            {}
          );
          that.setEnum("assistanceStatus", response.data.assistance_enum, {
            text: "",
            value: null,
          });
          that.setEnum("states", response.data.state_enum, {
            value: "none",
            text: that.$t("enum.plan_delivery.state.not_planned"),
          });
          that.setEnum("documentEnum", response.data.payment_document_kind, {
            text: "",
            value: null,
          });
          that.setEnum("paymentEnum", response.data.payment_payment_kind, {
            text: "",
            value: null,
          });
          that.setEnum("statusEnum", response.data.payment_status_kind, {
            text: "",
            value: null,
          });
        }
      );
    },
    setEnum(attribute, enumTranslated, nullValue = { text: "", value: null }) {
      this[attribute] = [nullValue].concat(
        ...enumTranslated.map((e) => {
          return { value: Object.keys(e)[0], text: Object.values(e)[0] };
        })
      );
    },
    updateAssistance(item, status) {
      var that = this;
      var params = {
        assistance: status,
        plan_delivery_id: item.latest_plan_delivery.id,
      };
      RequestService.request_post(
        params,
        "plan_deliveries/updated_assistance"
      ).then(
        (response) => {
          if (response.data.success) {
            that.items.find(
              (i) => i.latest_plan_delivery.id == item.latest_plan_delivery.id
            )["latest_plan_delivery"]["assistance"] = status;
            that.updateMessages(item.id);
          }
        },
        (error) => {
          console.log(error);
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.connection_error_with_server"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
    vehicleSkills(item) {
      if (item.skills && item.skills.length > 0) {
        const reduceskills = (acc, curr) => acc + ", " + curr;
        return item.skills.map((skill) => skill.name).reduce(reduceskills);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date
        .substring(0, "AAAA-MM-GG".length)
        .split("-");
      return `${day}/${month}/${year} ${date.substring(11, 19)}`;
    },
    getFormatDate(date) {
      return (
        date.substring("AAAA-MM-".length, "AAAA-MM-GG".length) +
        "/" +
        date.substring("AAAA-".length, "AAAA-MM".length) +
        "/" +
        date.substring("".length, "AAAA".length)
      );
    },
    saveMessage(message) {
      var that = this;
      var params = {
        description: message,
        sender_id: this.user.id,
        delivery_id: this.deliveryChatNote["delivery_id"],
      };
      RequestService.request_post(params, "delivery_notes").then(
        (response) => {
          that.deliveryChatNote["delivery_notes"].push(response.data);
        },
        (error) => {
          console.log(error);
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.connection_error_with_server"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
    updateMessages(delivery_id) {
      var that = this;
      RequestService.request_get(
        { delivery_id: delivery_id },
        "delivery_notes/list"
      ).then(
        (response) => {
          if (that.deliveryChatNote["delivery_notes"])
            that.deliveryChatNote["delivery_notes"] = response.data;

          that.items.find((i) => i.id == delivery_id)["delivery_notes"] =
            response.data;
        },
        (error) => {
          console.log(error);
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.connection_error_with_server"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
    openMail(body) {
      var deliveryId = this.deliveryChatNote.delivery_id;
      var delivery = this.items.find((e) => e.id == deliveryId);
      var mailObject =
        body.controller == "deliveries"
          ? `OPEN: ${this.getIdentifier(delivery)}`
          : `OPEN: ${body.identifier}`;
      var mailBody = `Comunicazione da ${this.user.username}: \n
                       \n""" 
                       \n ${body.message} 
                       \n 
                       \n""" 
                       \n
                       \n è possibile accedere alla nota attraverso il seguente link: 
                       \n ${APP_URL}${body.controller}/${body.id}
                       \n 
                       \n Buon lavoro`;
      var mailTo = this.company.email;

      var params = {
        mail_object: mailObject,
        sender_id: this.user.id,
        mail_body: mailBody,
        mail_to: mailTo,
      };
      var that = this;

      RequestService.request_post(
        params,
        `senders/${this.user.id}/send_email_to_logistic`
      ).then(
        () => {
          that.snackbar = {
            text: that.$t("enum.order.sms_status.success"),
            color: "success",
            timeout: 5000,
            value: true,
          };
        },
        () => {
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.connection_error_with_server"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
    videoOrPhotoPresent(plan_delivery) {
      return (
        plan_delivery.medias != null &&
        plan_delivery.medias.some((m) => m["video?"] || m["photo?"])
      );
    },
    newPayment(payment, deliveryId) {
      var that = this;
      // eslint-disable-next-line prettier/prettier
      var params = Object.assign(payment, { delivery_id: deliveryId });
      RequestService.request_post(params, "payments").then(
        (response) => {
          var item = that.items.find((i) => i.id == deliveryId);
          if (!item) return;
          item.payments.splice(item.payments, 0, response.data);
        },
        (error) => {
          console.log(error);
          that.snackbar = {
            text: "Errore di connessione. Riprovare.",
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
    updatePayment(payment) {
      var that = this;
      // eslint-disable-next-line prettier/prettier
      var deliveryId = payment.delivery_id;
      RequestService.request_patch(payment, "payments/" + payment.id).then(
        (response) => {
          var item = that.items.find((i) => i.id == deliveryId);
          if (!item) return;
          var paymentIdx = item.payments.findIndex(
            (p) => p.id == response.data.id
          );
          if (!paymentIdx) return;
          that.$set(item.payments, paymentIdx, response.data);
        },
        (error) => {
          console.log(error);
          that.snackbar = {
            text: that.$t(
              "activerecord.errors.messages.error_during_request_retry"
            ),
            color: "error",
            timeout: 5000,
            value: true,
          };
        }
      );
    },
  },
};
</script>
