import axios from "axios";
import authHeader from "@/services/auth-header.js";


const API_URL = process.env.VUE_APP_API_URL;

class RequestService {
  request_get(params, address, responseType = null) {
    var url = API_URL + address;
    var config = { headers: authHeader(), params: params };
    if (responseType != null) {
      config.responseType = responseType;
    }
    return axios.get(url, config).then(response => {
      return response || [];
    });
  }
  request_post(params, address) {
    var url = API_URL + address;
    var config = { headers: authHeader() };
    return axios.post(url, params, config).then(response => {
      return response || [];
    });
  }
  request_patch(params, address) {
    var url = API_URL + address;
    var config = { headers: authHeader() };
    return axios.patch(url, params, config).then(response => {
      return response || [];
    });
  }
  request_delete(address) {
    var url = API_URL + address;
    var config = { headers: authHeader() };
    return axios.delete(url, config).then(response => {
      return response || [];
    });
  }
}

export default new RequestService();
