<template>
  <v-card class="pa-2 ma-0">
    <v-row align-content="center" no-gutters>
      <v-col cols="4" class="pa-2 ma-0">
        <v-text-field
          label="Codice"
          type="string"
          v-model="expense_attributes.code"
          required
          outlined
          hide-details="auto"
          dense
          :error-messages="errors.code"
        ></v-text-field>
      </v-col>
      <v-col cols="5" class="pa-2 ma-0">
        <v-text-field
          label="Descrizione"
          type="string"
          v-model="expense_attributes.description"
          required
          outlined
          hide-details="auto"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="pa-2 ma-0" align-self="center">
        <v-checkbox
          label="Extra"
          v-model="expense_attributes.extra"
          hide-details="auto"
          class="mt-0"
          :disabled="expense_attributes.from_fip"
        ></v-checkbox>
      </v-col>
      <v-col cols="2" class="pa-2 ma-0">
        <v-btn color="red" align-center small @click="removeExpense()">
          <v-icon color="white"> mdi-delete </v-icon>
        </v-btn>
        <v-btn
          color="orange"
          align-center
          @click.stop="archiveExpense()"
          small
          v-show="!isArchivedExpense && expense_attributes.id"
        >
          <v-icon color="white"> mdi-folder </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align-content="center" no-gutters>
      <v-col cols="2" class="pa-2 ma-0">
        <v-text-field
          label="Num prodotti"
          type="number"
          v-model="expense_attributes.quantity"
          required
          outlined
          hide-details="auto"
          dense
          :error-messages="errors.quantity"
        ></v-text-field>
      </v-col>
      <v-col cols="2" align="justify" class="pa-2 ma-0">
        <v-text-field
          label="Prezzo unitario"
          type="number"
          v-model="expense_attributes.unitary_price"
          required
          outlined
          hide-details="auto"
          dense
          :error-messages="errors.unitary_price"
        ></v-text-field>
      </v-col>
      <v-col cols="3" align="justify" class="pa-2 ma-0">
        <v-select
          :items="ivaCodes"
          :item-text="'Iva'"
          v-model="expense_attributes.cod_iva"
          :error-messages="errors.cod_iva"
          label="IVA (%)"
          outlined
          dense
          @change="ivaChanged(expense_attributes.cod_iva)"
        >
        </v-select>
      </v-col>
      <v-col cols="3" align="justify" class="pa-2 ma-0">
        <v-select
          v-if="expense_attributes.cod_iva == 'I0'"
          :items="invoiceNatures"
          v-model="expense_attributes.invoice_nature"
          :error-messages="errors.invoice_nature"
          label="Natura"
          outlined
          dense
        >
        </v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/* eslint-disable prettier/prettier */
import RequestService from "@/services/request.service.js";

export default {
  name: "expense-card",
  props: {
    value: {
      type: Object,
    },
    invoiceNatures: {
      type: Array,
    },
    ivaCodes: {
      type: Array,
    },
    isArchivedExpense: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      expense_attributes: JSON.parse(JSON.stringify(this.value)),
    };
  },

  watch: {
    expense_attributes: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  methods: {
    archiveExpense() {
      var that = this;
      RequestService.request_post(
        {},
        `expenses/${this.expense_attributes.id}/archive`
      ).then(
        () => {
          that.$emit("archiveExpense", that.expense_attributes.uuid);
        },
        (error) => {
          that.$emit("errors", error.response.data.errors);
          console.log(error);
        }
      );
    },
    ivaChanged(newVal) {
      if (newVal == "I0" && this.expense_attributes.invoice_nature == undefined)
        this.expense_attributes.invoice_nature = "N4: esenti (art.10)";
    },
    removeExpense: function () {
      this.expense_attributes._destroy = true;
    },
  },
};
</script>
