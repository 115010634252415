<style scoped></style>
<template>
  <v-container fluid class="mb-16">
    <v-card class="mb-4">
      <v-card-title>
        {{ $t("activerecord.models.order.other") }}

        <v-spacer></v-spacer>

        <v-card v-if="providerName == 'fip'">
          <v-card-title>
            <v-col>
              <v-text-field
                v-model="identifier.number"
                :label="$t('activerecord.attributes.order.number')"
                single-line
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="identifier.type"
                :label="$t('activerecord.attributes.order.type')"
                single-line
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="identifier.year"
                :label="$t('activerecord.attributes.order.year')"
                single-line
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-btn
              color="warning"
              class="ma-2"
              @click="downloadOrderFromFip"
              dense
            >
              {{ $t("helpers.submit.download", null, { model: "" }) }}
            </v-btn>
          </v-card-title>
        </v-card>

        <v-spacer></v-spacer>

        <new-order
          @created="runSearch"
          :senders="senders"
          :withholding_tax_codes="withholding_tax_codes"
        ></new-order>
      </v-card-title>

      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("common.filter.one") }}
              <v-spacer></v-spacer>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search.identifier"
                    :label="$t('common.identifier.one')"
                    single-line
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="search.name"
                    :label="$t('activerecord.models.receiver.one')"
                    single-line
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="providerName == 'fip'">
                <v-col>
                  <v-select
                    :items="senders"
                    item-text="username"
                    item-value="id"
                    v-model="search.sender_id"
                    :label="$t('activerecord.models.sender.one')"
                    outlined
                    dense
                    multiple
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="dateFromMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="search.date_from"
                        :label="$t('common.from.m.one')"
                        single-line
                        hide-details
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="search.date_from"
                      locale="it"
                      scrollable
                      @change="dateFromMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="dateToMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="search.date_to"
                        :label="$t('common.to.m.one')"
                        single-line
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        :error-messages="errors.dateTo"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="search.date_to"
                      locale="it"
                      scrollable
                      @change="dateToMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col :cols="isMaster ? 4 : 6" align="center">
                  <v-btn color="success" @click="runSearch">
                    {{ $t("search", null, { model: "" }) }}
                  </v-btn>
                </v-col>
                <v-col :cols="isMaster ? 4 : 6" align="center">
                  <v-btn color="primary" @click="search = {}">
                    {{ $t("common.clean", null, { model: "" }) }}
                  </v-btn>
                </v-col>
                <v-col v-if="isMaster" :cols="4" align="center">
                  <v-btn color="error" @click="downloadInvoices">
                    {{
                      $t("helpers.submit.download", null, {
                        model: `${$t("activerecord.models.invoice.other")} XML`,
                      })
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :sort-by.sync="defaultSort"
        :sort-desc.sync="defaultDesc"
        :server-items-length="(options.itemsPerPage || 0) * pageCount"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.denomination`]="{ item }">
          <v-chip label class="ma-1" color="white">
            {{ tableName(item) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row align="center">
            <v-col align="center">
              <new-order
                :order="item"
                @created="runSearch"
                :withholding_tax_codes="withholding_tax_codes"
                :warehouses="warehouses"
                @errors="addErrorsOnSnackbar($event)"
              ></new-order>
            </v-col>
            <v-col v-if="providerName == 'fip'" align="center">
              <v-icon @click="updateOrder(item.id)">mdi-reload</v-icon>
            </v-col>
            <v-col v-if="providerName == 'fip'" align="center">
              <v-icon @click="updateOrderItems(item.id)">mdi-sofa</v-icon>
            </v-col>
            <v-col v-if="canUsePayments" align="center">
              <order-payments :order="item"></order-payments>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar.opened" :color="snackbar.color">
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import RequestService from "@/services/request.service.js";
import OrderPayments from "./OrderPayments.vue";
import NewOrder from "./NewOrder.vue";
import Pusher from "pusher-js";
import moment from "moment";

export default {
  name: 'Orders-vue',
  data() {
    var appKey = process.env.VUE_APP_APP_KEY;
    var appCluster = process.env.VUE_APP_APP_CLUSTER;
    return {
      company: null,
      user: this.$store.state.auth.session.user,
      pageCount: 0,
      headers: [],
      items: [],
      defaultSort: "created_at",
      defaultDesc: true,
      schema: {},
      loading: false,
      options: {},
      lastSearchedIdentifier: null,
      search: {},
      dateFromMenu: false,
      dateToMenu: false,
      identifier: {},
      snackbar: {},
      senders: [],
      pusher: null,
      appKey: appKey,
      appCluster: appCluster,
      withholding_tax_codes: [],
      errors: {},
      warehouses: [],
      providerName: this.$store.getters["auth/providerName"],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetchOrders();
      },
      deep: true,
    },
  },

  components: { OrderPayments, NewOrder },

  mounted() {
    var that = this;
    this.pusher = new Pusher(this.appKey, { cluster: this.appCluster });
    this.pusher.subscribe(
      "user-" + this.$store.state.auth.session.user.id + "-invoices"
    );
    this.pusher.bind("completed", (data) => {
      window.open(data.file_url, "_blank");
    });
    this.pusher.bind("failed", (data) => {
      that.snackbar = {
        opened: true,
        color: "error",
        text: data.message,
      };
    });
    this.fetchSenders();
    this.fetchWarehouses();
  },

  beforeDestroy() {
    this.pusher.unsubscribe(
      "user-" + this.$store.state.auth.session.user.id + "-invoices"
    );
  },

  computed: {
    canUsePayments() {
      return (
        this.providerName == "fip" &&
        (this.$store.state.auth.session.roles || []).includes(
          "open_order_payments"
        )
      );
    },
    identifierInserted() {
      return (
        this.identifier.number != null &&
        this.identifier.number.length > 0 &&
        this.identifier.type != null &&
        this.identifier.type.length > 0 &&
        this.identifier.year != null &&
        this.identifier.year.length > 0
      );
    },
    isMaster() {
      return this.$store.state.auth.session.user.master;
    },
  },

  methods: {
    tableName(item) {
      var denomination = item.receiver_data.denomination;
      if (!item.receiver_data.denomination) {
        denomination = [
          item.receiver_data.name,
          item.receiver_data.surname,
        ].join(" ");
      }
      return denomination;
    },
    runSearch() {
      this.errors = {};
      var dateFrom = moment(this.search.date_from);
      var dateTo = moment(this.search.date_to);
      if (dateTo.isBefore(dateFrom)) {
        this.errors.dateTo = this.$t(
          "errors.messages.date_to_before_date_from"
        );
        return;
      }
      if (this.options.page == 1) {
        this.fetchOrders();
      } else {
        this.options.page = 1;
        this.fetchOrders();
      }
    },
    fetchOrders() {
      var that = this;
      this.loading = true;
      var search = this.search;
      var options = this.options;
      return RequestService.request_post(
        { orders: { search, options } },
        "orders"
      ).then(
        (response) => {
          var data = response.data;
          that.company = data.company_name;
          that.headers = data.headers;
          that.items = data.orders;
          that.withholding_tax_codes = data.withholding_tax_codes;
          that.pageCount = data.page_count;
          that.loading = false;
        },
        (error) => {
          this.$emit("errorServer", error);
          alert(
            this.$t("activerecord.errors.messages.connection_error_with_server")
          );
          console.log(error);
          that.loading = false;
        }
      );
    },
    fetchSenders() {
      var that = this;
      if (!this.$store.state.auth.session.user.master) return;
      else {
        var address = "orders/fetch_senders";
        var params = { company_id: this.$store.state.auth.session.company.id };
        RequestService.request_post(params, address).then(
          (response) => {
            that.senders = response.data;
          },
          (error) => {
            console.log(error);
            this.errors = error.response.data;
          }
        );
      }
    },
    downloadOrderFromFip() {
      if (!this.identifierInserted) return;
      var address = "orders/get_fip_orders";
      var that = this;
      RequestService.request_get(this.identifier, address).then(
        (response) => {
          if (response.data.success) {
            that.runSearch();
            this.snackbar = {
              opened: true,
              color: "success",
              text: this.$t("helpers.effects.downloaded.m.one", null, {
                model: this.$t("activerecord.models.order.one"),
              }),
            };
          } else {
            this.snackbar = {
              opened: true,
              color: "error",
              text: this.$t("errors.messages.not_found_on", null, {
                model: this.$t("activerecord.models.order.one"),
                attribute: "fip",
              }),
            };
          }
        },
        (error) => {
          //this.$emit("errorServer", error);
          this.snackbar = {
            opened: true,
            color: "error",
            text: error.response.data.error,
          };
          //alert("Errore di comunicazione con il server.");
          console.log(error);
        }
      );
    },
    downloadInvoices() {
      var address = "orders/download_invoices";
      var that = this;
      var search = this.search;
      var options = this.options;
      return RequestService.request_post(
        { orders: { search, options } },
        address
      ).then(
        (response) => {
          that.snackbar = {
            opened: true,
            color: "success",
            text: this.$t("helpers.submit.started", null, {
              model: "XML",
              action: this.$t("helpers.actions.generation"),
            }),
          };
          console.log(response);
        },
        (error) => {
          that.$emit("errorServer", error);
          alert(
            that.$t("activerecord.errors.messages.connection_error_with_server")
          );
          console.log(error);
        }
      );
    },
    updateOrder(orderId) {
      var address = `orders/${orderId}/update_order`;
      var that = this;
      var params = { id: orderId };
      return RequestService.request_post(params, address).then(
        () => {
          that.runSearch();
          this.snackbar = {
            opened: true,
            color: "success",
            text: this.$t("helpers.effects.updated.m.one", null, {
              model: this.$t("activerecord.models.order.one"),
            }),
          };
        },
        (error) => {
          if (error.response.data && error.response.data.errors) {
            this.snackbar = {
              opened: true,
              color: "error",
              text: error.response.data.errors[0],
            };
          } else {
            this.$emit("errorServer", error);
          }
          console.log(error);
        }
      );
    },
    updateOrderItems(orderId){
      var address = `orders/${orderId}/update_order_items`;
      var that = this;
      var params = { id: orderId };
      return RequestService.request_post(params, address).then(
        () => {
          that.runSearch();
          this.snackbar = {
            opened: true,
            color: "success",
            text: this.$t("helpers.effects.updated.m.other", null, {
              model: this.$t("activerecord.models.item.other"),
            }),
          };
        },
        (error) => {
          if (error.response.data && error.response.data.errors) {
            this.snackbar = {
              opened: true,
              color: "error",
              text: error.response.data.errors[0],
            };
          } else {
            this.$emit("errorServer", error);
          }
          console.log(error);
        }
      );
    },
    fetchWarehouses() {
      var that = this;
      var address = "orders/fetch_warehouses";
      var params = { company_id: this.$store.state.auth.session.company.id };
      RequestService.request_post(params, address).then(
        (response) => {
          that.warehouses = response.data;
        },
        (error) => {
          console.log(error);
          this.errors = error.response.data;
        }
      );
    },
    addErrorsOnSnackbar(messages) {
      this.snackbar = {
        opened: true,
        color: "error",
        text: messages[0],
      };
    },
  },
};
</script>
