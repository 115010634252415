<style type="text/css">
</style>
<template>
  <v-dialog v-model="dialog" max-width="80vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        <v-icon color="white"> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary">
        <h2><font color="#FFFFFF">Modifica descrizione</font></h2>
        <v-spacer />
        <v-btn
          @click="dialog = false"
          color="white"
          outlined
          dense
          hide-details
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-form ref="form">
          <br />
          <v-text-field
            v-model="params.paymentDescription"
            outlined
            dense
            :error-messages="messageErrors.paymentDescription"
            label="Descrizione pagamento"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="primary">
        <v-spacer />
        <v-btn color="white" outlined dense hide-details @click="confirm()">
          Rigenera fattura
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable radix */

export default {
  name: "edit-description-modal",
  props: {
    paymentDescription: { type: String },
  },
  data() {
    return {
      dialog: false,
      rules: {
        present: (value) => !!value || "Campo obbligatorio",
      },
      params: {
        paymentDescription: this.paymentDescription,
      },
      messageErrors: {
        paymentDescription: [],
      },
    };
  },
  methods: {
    confirm() {
      if (!this.$refs.form.validate()) return;

      this.$emit("confirm", this.params);
      this.dialog = false;
    },
  },
};
</script>
