class DeliveryService {
  fields_to_identify(delivery) {
    var id = "" 
    if (delivery.identifier)
      id = delivery.identifier
    var add_data = delivery.additional_data
    if (add_data) {
      var name = ""
      if (add_data.number && add_data.type && add_data.year)
        id = [add_data.number, add_data.type, add_data.year].join("-")
      if (add_data.recipient)
        name = add_data.recipient
      if (add_data.recipient_person)
        name = add_data.recipient_person
      if (name.length > 0)
        id += " : " + name
    }
    return id;
  }
}

export default new DeliveryService();
