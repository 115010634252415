<style type="text/css">
.col-no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.row-margin {
  margin-top: 1vh;
  margin-bottom: 1vh;
}
</style>

<template>
  <v-row justify="start" style="margin-left: 0px">
    <v-container fluid style="max-width: 80vw">
      <v-row>
        <v-col md="12">
          <h2>
            {{ $t("activerecord.models.common.import") }}
            {{ $t("activerecord.models.delivery.other") }}
          </h2>
          <br />
          <v-row>
            <v-col :cols="12" :md="10">
              <v-file-input
                v-model="file"
                label="Cerca il file da importare"
              ></v-file-input>
            </v-col>
            <v-col :cols="12" :md="2" align="center">
              <v-btn
                style="margin-top: 0.8vh"
                dark
                large
                color="primary"
                @click="importFile()"
              >
                Importa
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="7000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import XLSX from "xlsx";
import AntyliaLogisticsXLSXImporter from "@/services/imports/antylia_logistics_xlsx_importer.js";

export default {
  name: "delivery-import",

  data() {
    return {
      sender: this.$store.state.auth.session.user,
      skills: this.$store.state.auth.session.skills.filter((s) => s.internal),
      file: null,
      snackbar: false,
      snackbarText: "Qualcosa è andato storto, contattare il supporto tecnico",
      snackbarColor: "red",
    };
  },

  computed: {
    errorAlerts() {
      return this.errorsArray.length > 0;
    },
  },

  methods: {
    importFile() {
      var that = this;
      if (this.file == null) {
        that.snackbarText =
          "È necessario selezionare un file per l'importazione";
        that.snackbarColor = "red";
        that.snackbar = true;
        return;
      }

      that.snackbarText = "Import avviato";
      that.snackbarColor = "green";
      that.snackbar = true;

      if (this.$store.state.auth.session.company.name == "Antylia Logistics") {
        var fileName = this.file.name;
        var reader = new FileReader();
        reader.readAsArrayBuffer(this.file);
        reader.onload = function () {
          var data = new Uint8Array(reader.result);
          var wb = XLSX.read(data, { type: "array", cellDates: true });
          if (wb.SheetNames.length < 1) {
            alert("Non è presente alcun foglio nel file indicato.");
            return;
          }

          var importer = new AntyliaLogisticsXLSXImporter(
            XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]),
            fileName,
            this.skills
          );
          importer.import();
        };
      }
    },
  },
};
</script>
