<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row align-content="center" no-gutters>
        <v-col cols="4" class="pa-2 ma-0">
          <v-text-field
            label="Nome"
            type="string"
            v-model="item_attributes.name"
            :error-messages="errors.name"
            required
            outlined
            dense
            hide-details="auto"
            @click.stop=""
          ></v-text-field>
          <v-row align-content="center" no-gutters>
            <v-col
              cols="5"
              class="pa-2 ma-0"
              style="padding-left: 0 !important"
            >
              <v-text-field
                label="Num prodotti"
                type="number"
                v-model="item_attributes.expected_stock"
                :error-messages="errors.expected_stock"
                required
                outlined
                dense
                hide-details="auto"
                @click.stop=""
              ></v-text-field>
            </v-col>
            <v-col
              cols="7"
              class="pa-2 ma-0"
              style="padding-right: 0 !important"
            >
              <v-text-field
                label="Prezzo unitario"
                type="number"
                v-model="item_attributes.unitary_price"
                :error-messages="errors.unitary_price"
                required
                outlined
                dense
                hide-details="auto"
                @click.stop=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="pa-2 ma-0">
          <v-text-field
            label="Identificatore"
            type="string"
            v-model="item_attributes.identifier"
            :error-messages="errors.identifier"
            required
            outlined
            dense
            hide-details="auto"
            @click.stop=""
          ></v-text-field>
          <v-row align-content="center" no-gutters>
            <v-col
              class="pa-2 ma-0"
              style="padding-left: 0 !important; padding-right: 0 !important"
            >
              <v-select
                :items="ivaCodes"
                :item-text="'Iva'"
                v-model="item_attributes.cod_iva"
                :error-messages="errors.cod_iva"
                label="IVA (%)"
                outlined
                dense
                @change="ivaChanged(item_attributes.cod_iva)"
                @click.stop=""
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="pa-2 ma-0">
          <v-text-field
            label="Codice articolo"
            type="string"
            v-model="item_attributes.article_code"
            :error-messages="errors.article_code"
            required
            outlined
            dense
            hide-details="auto"
            @click.stop=""
          ></v-text-field>
          <v-row align-content="center" no-gutters>
            <v-col
              class="pa-2 ma-0"
              style="padding-left: 0 !important; padding-right: 0 !important"
            >
              <v-select
                v-if="item_attributes.cod_iva == 'I0'"
                :items="invoiceNatures"
                v-model="item_attributes.invoice_nature"
                :error-messages="errors.invoice_nature"
                label="Natura"
                outlined
                dense
                @click.stop=""
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="1" class="pa-2 ma-0">
          <v-btn color="red" align-center small @click="removeItem()">
            <v-icon color="white"> mdi-delete </v-icon>
          </v-btn>
          <v-btn
            color="orange"
            align-center
            @click.stop="archiveItem()"
            small
            v-show="!isArchivedItem && item_attributes.id"
          >
            <v-icon color="white"> mdi-folder </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row v-if="itemExists && itemInSenderWarehouseArea">
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-text-field
            label="Quantità"
            readonly
            dense
            outlined
            hide-details="auto"
            v-model="item_attributes.stock"
            prepend-icon="mdi-minus"
            append-outer-icon="mdi-plus"
            @click:append-outer="registerMovement('in', 1)"
            @click:prepend="registerMovement('in', -1)"
          />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Configurazione"
            type="string"
            v-model="item_attributes.config"
            required
            outlined
            dense
            rows="2"
            auto-grow
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Descrizione"
            type="string"
            v-model="item_attributes.description"
            required
            outlined
            dense
            rows="2"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Linea ordine"
            type="number"
            v-model="item_attributes.order_line"
            :disabled="true"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Num pezzi"
            type="number"
            v-model="item_attributes.unitary_pieces"
            :error-messages="errors.unitary_pieces"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Volume unitario"
            type="number"
            v-model="item_attributes.unitary_volume"
            :error-messages="errors.unitary_volume"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Peso unitario"
            type="number"
            v-model="item_attributes.unitary_weight"
            :error-messages="errors.unitary_weight"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Altezza"
            type="string"
            v-model="item_attributes.height"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Larghezza"
            type="number"
            v-model="item_attributes.width"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Lunghezza"
            type="number"
            v-model="item_attributes.length"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
/* eslint-disable prettier/prettier */
import RequestService from "@/services/request.service.js";

export default {
  name: "item-panel",
  props: {
    value: {
      type: Object,
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
    invoiceNatures: {
      type: Array,
    },
    ivaCodes: {
      type: Array,
    },
    isArchivedItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item_attributes: JSON.parse(JSON.stringify(this.value)),
    };
  },

  watch: {
    item_attributes: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (JSON.stringify(val) != JSON.stringify(this.item_attributes))
          this.item_attributes = JSON.parse(JSON.stringify(val));
      },
      deep: true,
    },
  },

  methods: {
    ivaChanged(newVal) {
      if (newVal == "I0" && this.item_attributes.invoice_nature == undefined)
        this.item_attributes.invoice_nature = "N4: esenti (art.10)";
    },
    removeItem() {
      this.item_attributes._destroy = true;
    },
    archiveItem() {
      var that = this
      RequestService.request_post(
        {},
        `/items/${this.item_attributes.id}/archive`
      ).then(
        () => {
          that.$emit('archived', that.item_attributes.uuid);
        },
        (error) => {
          that.$emit("errors", error.response.data.errors);
          console.log(error);
        }
      );
    },
    registerMovement(kind, quantity = 1) {
      RequestService.request_post(
        {
          kind: kind,
          quantity: quantity,
        },
        `items/${this.item_attributes.id}/register_movements`
      ).then(
        (response) => {
          this.item_attributes.stock = response.data.stock;
        },
        (error) => {
          this.$emit("error", error);
          alert("Errore di comunicazione con il server.");
          console.log(error);
        }
      );
    },
  },
  computed: {
    itemExists: function () {
      return !isNaN(this.item_attributes.id);
    },
    itemInSenderWarehouseArea: function () {
      return (
        this.item_attributes.warehouse_area_id ==
        this.$store.state.auth.session.user.warehouse_area_id
      );
    },
  },
};
</script>
