import axios from "axios";
import authHeader from "@/services/auth-header.js";
import AWS from "aws-sdk";

const API_URL = process.env.VUE_APP_API_URL;

class S3MediaService {
  constructor() {
    this.region = "eu-central-1";
  }

  login(successCallback, errorCallback) {
    var that = this;
    var url = API_URL + "auth/aws_credentials";
    var config = { headers: authHeader() };
    var params = {}

    axios.post(url, params, config)
      .then(response => {
        var res = response.data;
        AWS.config.region = that.region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: res.credentials.pool_id,
          IdentityId: res.credentials.identity_id,
          Logins: {
            'cognito-identity.amazonaws.com': res.credentials.token
          }
        });
        successCallback();
      })
      .catch(err => {
        errorCallback(err);
      });
  }

  getMedia(bucket, key, callback) {
    var s3 = new AWS.S3();
    var params = { Bucket: bucket, Key: key };
    return s3.getObject(params, callback);
  }

  putMedia(body, bucket, key, callback) {
    var s3 = new AWS.S3();
    var params = {
      Body: body, 
      Bucket: bucket, 
      Key: key
    };
    return s3.putObject(params, callback);
  }
}

export default new S3MediaService();
