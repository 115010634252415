<style scoped></style>
<template>
  <v-container fluid class="mb-16">
    <v-card class="mb-4">
      <v-card-title>
        {{ $t("activerecord.models.inventory.other") }}

        <new-inventory @failed="addErrorsOnSnackbar($event)"></new-inventory>
      </v-card-title>

      <v-spacer></v-spacer>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :sort-by.sync="defaultSort"
        :sort-desc.sync="defaultDesc"
        :server-items-length="(options.itemsPerPage || 0) * pageCount"
        :loading="loading"
        class="elevation-1"
        style="height: 100%"
        dense
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-row align="center">
            <v-col align="center">
              <new-inventory
                :inventory="item"
                @created="runSearch"
              ></new-inventory>
            </v-col>
            <v-col align="center">
              <router-link
                :to="{
                  path: '/inventory-show',
                  query: { id: item.id },
                }"
              >
                <v-icon>mdi-eye</v-icon>
              </router-link>
            </v-col>
            <v-col align="center">
              <v-icon v-if="!item.completed" @click="destroyInventory(item)"
                >mdi-delete</v-icon
              >
            </v-col>
            <v-col align="center">
              <v-icon @click="updateOrder(item.id)">mdi-excel</v-icon>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar.opened" :color="snackbar.color">
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import NewInventory from "./newInventory.vue";
import RequestService from "@/services/request.service.js";

export default {
  name: 'Inventories-vue',
  data() {
    var appKey = process.env.VUE_APP_APP_KEY;
    return {
      company: null,
      user: this.$store.state.auth.session.user,
      pageCount: 0,
      headers: [],
      items: [],
      defaultSort: "created_at",
      defaultDesc: true,
      loading: false,
      options: {},
      search: {},
      identifier: {},
      snackbar: {},
      appKey: appKey,
      channelName: "",
    };
  },

  components: { NewInventory },

  watch: {
    channelName(val, oldVal) {
      var that = this;
      if (val != "" && val != oldVal) {
        var channel = this.$pusher.subscribe(val);
        channel.bind("inventory-messages", function (data) {
          if (data.message) {
            alert(data.message);
          }
          if (data.complete) {
            that.fetchInventories();
          }
        });
      }
    },
    options: {
      handler() {
        this.fetchInventories();
      },
      deep: true,
    },
  },

  beforeDestroy() {
    this.$pusher.unsubscribe(this.channelName);
  },
  computed: {},

  methods: {
    runSearch() {
      if (this.options.page == 1) {
        this.fetchInventories();
      } else {
        this.options.page = 1;
        this.fetchInventories();
      }
    },
    destroyInventory(item) {
      let text = "Sicuro di voler cancellare l'inventario " + item.identifier;
      if (confirm(text) == true) {
        RequestService.request_delete("inventories/" + item.id)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fetchInventories() {
      var that = this;
      this.loading = true;
      var search = this.search;
      var options = this.options;
      return RequestService.request_post(
        { inventories: { search, options } },
        "inventories"
      ).then(
        (response) => {
          var data = response.data;
          that.company = data.company_name;
          that.channelName = "user-" + data.user_id;
          that.headers = data.headers;
          that.items = data.inventories;
          that.pageCount = data.page_count;
          that.loading = false;
        },
        (error) => {
          this.$emit("errorServer", error);
          alert(
            this.$t("activerecord.errors.messages.connection_error_with_server")
          );
          console.log(error);
          that.loading = false;
        }
      );
    },
    addErrorsOnSnackbar(messages) {
      this.snackbar = {
        opened: true,
        color: "error",
        text: messages[0],
      };
    },
  },
};
</script>
